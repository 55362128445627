<template>
    <div>
        <h2 class="text-center mb-6">
            {{ $t(`auth.teacher`) }}
        </h2>
        <register-user-form role="teacher" />

        <CustomButton
            class="mt-6"
            version="text"
            @click.prevent="goBack">
            {{ $t("global.goBack") }}
        </CustomButton>
    </div>
</template>

<script>
import RegisterUserForm from "views/auth/register/RegisterUserForm"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "RegisterInstructionsTeacher",
    components: { CustomButton, RegisterUserForm }
}
</script>
