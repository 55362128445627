<template>
    <UserHeader align="start">
        <GoBackButton />
        <h1 v-if="$store.state.auth.user.isTeacher">
            {{ $t("student.title") }}
        </h1>
        <h1 v-if="$store.state.auth.user.isParent">
            {{ $t("student.parentTitle") }}
        </h1>
    </UserHeader>

    <div
        class="w-full py-6 h-full flex-1 h-0"
        v-if="student && statsWords">
        <Card class="w-full h-full">
            <StudentProgression
                :stats-games="statsGames"
                :student="student"
                :stats-words="statsWords" />
        </Card>
    </div>
</template>

<script>
import * as types from "store/types"
import UserHeader from "components/globals/UserHeader"

import ajax from "config/ajax"
import StudentProgression from "components/layouts/StudentProgression"
import GoBackButton from "components/globals/GoBackButton"
import Card from "components/cards/Card"

export default {
    name: "UserStudentStats",
    components: { Card, GoBackButton, StudentProgression, UserHeader },
    data() {
        return {
            statsWords: null,
            statsGames: null
        }
    },
    async created() {
        this.$store.dispatch(types.USER_DATA.GET_STUDENT_BY_UUID, this.$route.params.uuid)

        ajax.get(`/user/students/${this.$route.params.uuid}/stats/words`).then((res) => {
            this.statsWords = res.statsWords
        })

        ajax.get(`/user/students/${this.$route.params.uuid}/stats/games`).then((res) => {
            this.statsGames = res.statsGames
        })

        //todo show student game, etc
    },
    computed: {
        student() {
            return this.$store.state.userData.students.find((s) => s.uuid === this.$route.params.uuid)
        }
    },
    methods: {}
}
</script>
