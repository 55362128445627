<template>
    <Card>
        <div class="sm:flex flex-row justify-between items-stretch p-3 md:space-x-3">
            <div class="flex flex-row justify-start sm:justify-between items-stretch space-x-3">
                <Image
                    class="h-28 w-32 min-w-32 md:w-40 md:min-w-40"
                    :image-url="wordGroup.imageUrl"
                    :alt="wordGroup.text" />
                <div class="flex flex-col justify-start items-start py-2">
                    <div class="mb-3 flex flex-row justify-start items-center">
                        <h5 class="opacity-60 text-purple uppercase">
                            {{ wordGroup.wordIds.length }} {{ $t(`multi.words.${learningLang}`) }}
                        </h5>
                    </div>

                    <h2 v-if="learningTraduction">
                        {{ capitalize(learningTraduction.text) }}
                    </h2>
                    <h2 v-else-if="traduction">
                        {{ capitalize(traduction.text) }}
                    </h2>
                    <h2 v-else>
                        {{ capitalize(wordGroup.text) }}
                    </h2>
                </div>
            </div>
            <div
                class="flex flex-row items-stretch space-x-3 pt-6 sm:pt-0"
                :class="{
                    'justify-between': gameTypes.length >= 3,
                    'justify-start': gameTypes.length < 3
                }">
                <Card
                    class="cursor-pointer min-h-16"
                    color="yellow"
                    v-for="gameType in gameTypes"
                    :style="`width:${(1 / gameTypes.length) * 100}%`"
                    :key="gameType.id">
                    <Popper
                        :offset-y="15"
                        class="w-full h-full block"
                        :content="$t(`global.gameTypes.${gameType.slug}`)">
                        <button
                            type="button"
                            @click.prevent.stop="startGame(gameType.slug)"
                            class="appearance-none flex flex-row justify-center items-center h-full w-full px-4">
                            <Icons
                                :icon="`game-type-${gameType.slug}`"
                                class="w-6 h-6 xs:w-8 xs:h-8 md:w-10 md:h-10 text-white block" />
                        </button>
                    </Popper>
                </Card>
            </div>
        </div>
    </Card>
</template>
<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"
import { LANGS } from "@globals/constants"
import Image from "components/globals/Image"
import Popper from "components/globals/Popper"

const VERSIONS = {
    DEFAULT: "default",
    LIVE: "live"
}

export default {
    name: "WordGroupCard",
    components: { Popper, Image, Icons, Card },
    emits: ["create"],
    props: {
        wordGroup: {
            required: true,
            type: Object
        },
        version: {
            default: () => VERSIONS.DEFAULT,
            type: String
        }
    },
    data() {
        return {
            VERSIONS
        }
    },
    created() {
        this.lang = LANGS.FR

        if (this.$store.getters.isStudentLogin) {
            this.lang = this.$store.state.auth.student.lang
        }
        if (this.$store.getters.isUserLogin) {
            this.lang = this.$store.state.auth.user.lang
        }
        if (this.$store.getters.isAnonymousLogin) {
            this.lang = this.$store.state.auth.anonymous.lang
        }
    },
    computed: {
        gameTypes() {
            let arr = []
            if (this.$store.getters.isStudentLogin) {
                arr = this.$store.state.studentData.global.gameTypes
            }
            if (this.$store.getters.isUserLogin) {
                arr = this.$store.state.userData.global.gameTypes
            }
            if (this.$store.getters.isAnonymousLogin) {
                arr = this.$store.state.anonymousData.global.gameTypes
            }

            if (this.version === VERSIONS.LIVE) {
                return arr.filter((g) => g.live && this.isGameTypePlayable(g.slug))
            }
            return arr.filter((g) => g.solo)//show only solo game types
        },
        traduction() {
            if (!this.wordGroup) return null
            return this.wordGroup.traductions.find((t) => t.lang === this.lang)
        },
        learningLang() {
            if (this.$store.getters.isUserLogin) {
                if (this.$store.state.auth.user.teachingLangs.length > 0) {
                    return this.$store.state.auth.user.teachingLangs[0]
                }
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.state.auth.student.learningLang
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.state.auth.anonymous.learningLang
            }

            return LANGS.EN //default
        },
        learningTraduction() {
            if (!this.wordGroup) return null

            if (this.$store.getters.isUserLogin) {
                return this.$store.getters.getUserTeachingTraduction(this.wordGroup)
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentLearningTraduction(this.wordGroup)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousLearningTraduction(this.wordGroup)
            }
            return null
        }
    },
    methods: {
        isGameTypePlayable(gameTypeSlug) {
            let obj = {}
            if (this.$store.getters.isStudentLogin) {
                obj = this.$store.state.studentData.playableWordGroupIds
            }
            if (this.$store.getters.isUserLogin) {
                obj = this.$store.state.userData.playableWordGroupIds
            }
            if (typeof obj[gameTypeSlug] === "undefined") {
                return true //default to true
            }

            return obj[gameTypeSlug].includes(this.wordGroup.id)
        },
        startGame(gameTypeSlug) {
            let gameType = this.gameTypes.find((g) => g.slug === gameTypeSlug)
            if (!gameType) return

            if (this.$store.getters.isStudentLogin) {
                if (this.version === VERSIONS.LIVE) {
                    this.$emit("create", {
                        wordGroupUuid: this.wordGroup.uuid,
                        gameTypeSlug: gameTypeSlug
                    })
                } else {
                    this.$router.push(`/student/list/${this.wordGroup.uuid}/${gameTypeSlug}`)
                }
            }
            if (this.$store.getters.isUserLogin) {
                this.$emit("create", {
                    wordGroupUuid: this.wordGroup.uuid,
                    gameTypeSlug: gameTypeSlug
                })
            }
            if (this.$store.getters.isAnonymousLogin) {
                this.$router.push(`/anonymous/list/${this.wordGroup.uuid}/${gameTypeSlug}`)
            }
        }
    }
}
</script>
