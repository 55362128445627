<template>
    <h1 class="text-center mb-6">
        {{ $t("auth.registerIntro") }}
    </h1>

    <router-view />

    <div class="md:flex flex-row justify-center items-center md:space-x-3 mt-6">
        <CustomButton
            version="text"
            to="/auth/login">
            {{ $t("auth.btnLogin") }}
        </CustomButton>

        <CustomButton
            to="/auth/forgot-pwd"
            version="text"
            class="mt-6 md:mt-0">
            {{ $t("auth.btnForgotPwd") }}
        </CustomButton>
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton"

export default {
    name: "Register",
    components: { CustomButton }
}
</script>
