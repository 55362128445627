<template>
    <div class="flex flex-row flex-wrap md:flex-nowrap justify-center items-stretch h-full">
        <div class="w-full md:w-1/2 md:pr-3">
            <div
                class="bg-white border-6 border-purple text-purple rounded p-2 flex flex-col justify-center items-center text-center h-full"
                v-if="traduction">
                <div
                    class="bg-gray-100 rounded-full h-12 min-w-12 w-12 md:h-16 md:min-w-16 md:w-16 p-2 flex flex-row justify-center items-center">
                    <img
                        :src="FLAGS[traduction.lang]"
                        class="block w-full h-full object-contain object-center" />
                </div>
                <h1 class="text-center pt-3 md:pt-6">
                    {{ capitalize(traduction.text) }}
                </h1>
            </div>
        </div>
        <div class="w-full md:w-1/2 md:pl-3 pt-6 md:pt-0">
            <div
                class="grid grid-cols-1 grid-rows-4 gap-6 h-full"
                v-if="currentSection">
                <button
                    v-for="answer in currentSection.answers"
                    :key="answer.id"
                    type="button"
                    class="appearance-none cursor-pointer rounded p-2 flex flex-col justify-center items-center text-center h2"
                    :class="{
                        'bg-red text-white': answer.wasWrongAnswer,
                        'bg-green text-white': answer.wasGoodAnswer,
                        'bg-white text-purple': !answer.wasGoodAnswer && !answer.wasWrongAnswer
                    }"
                    @click.prevent="sendAnswer(answer.uuid)">
                    <span v-if="getGameTraduction(answer)">
                        {{ capitalize(getGameTraduction(answer).text) }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { FLAGS } from "services/countryFlags"

export default {
    name: "GameTranslate",
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            FLAGS
        }
    },
    computed: {
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        traduction() {
            if (!this.currentSection) return null
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentTraduction(this.word)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousTraduction(this.word)
            }
            return null
        }
    },
    methods: {
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        },
        sendAnswer(answerUuid) {
            if (!this.currentSection.isReal) return
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: answerUuid
            })
        }
    }
}
</script>
