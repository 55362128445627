<template>
    <component
        :is="tag"
        class="whitespace-nowrap">
        {{ content }}
    </component>
</template>

<script>
import padStart from "lodash-es/padStart"

export default {
    name: "GameTimer",
    props: {
        timer: {
            type: Object,
            required: true
        },
        tag: {
            type: String,
            default: "h1"
        }
    },
    computed: {
        content() {
            let times = []
            if (typeof this.timer.minutes !== "undefined") {
                times.push(padStart(this.timer.minutes, 2, "0"))
            }
            if (typeof this.timer.seconds !== "undefined") {
                times.push(padStart(this.timer.seconds, 2, "0"))
            }
            if (typeof this.timer.hundreth !== "undefined") {
                times.push(padStart(parseInt(this.timer.hundreth / 10), 2, "0"))
            }

            return times.join(":")
        }
    }
}
</script>
