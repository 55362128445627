<template>
    <div>
        <h2 class="text-center mb-6">
            {{ $t(`auth.student`) }}
        </h2>
        <register-student-form />

        <CustomButton
            class="mt-6"
            version="text"
            @click.prevent="goBack">
            {{ $t("global.goBack") }}
        </CustomButton>
    </div>
</template>

<script>
import RegisterUserForm from "views/auth/register/RegisterUserForm"
import CustomButton from "components/globals/CustomButton"
import RegisterStudentForm from "views/auth/register/RegisterStudentForm"

export default {
    name: "RegisterStudentWithClassroom",
    components: { RegisterStudentForm, CustomButton, RegisterUserForm }
}
</script>
