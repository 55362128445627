import * as types from "store/types"
import ajax from "config/ajax"
import { getExperienceByXp } from "@globals/functions"
import { selectLang } from "config/i18n"
import { createSocket } from "services/socket"
import { USER_TYPES } from "@globals/constants"
// Static
let existingStudentToken = window.localStorage.getItem(`kwordz:student-jwt`)
let existingUserToken = window.localStorage.getItem(`kwordz:user-jwt`)
let existingAnonymousToken = window.localStorage.getItem(`kwordz:anonymous-jwt`)

// initial state, with craft backend or empty
const state = {
    //student
    studentShortcode: window.localStorage.getItem("kwordz:student-shortcode") || "",
    studentJwt: existingStudentToken || null,
    student: null,
    studentSocket: null,

    //user
    user: null,
    userEmail: window.localStorage.getItem("kwordz:user-email") || "",
    userJwt: existingUserToken || null,
    userSocket: null,

    finishedSession: false,

    anonymousSocket: null,
    anonymousJwt: existingAnonymousToken || null,
    anonymous: null
}

// getters, make function easy to access by vue
const getters = {
    isStudentLogin: (state) => state.student !== null,
    isUserLogin: (state) => state.user !== null,
    isAnonymousLogin: (state) => state.anonymous !== null,
    getStudentExperience: (state) => (student) => {
        if (!student) return null
        return getExperienceByXp(student.pointsXP)
    }
}

// actions
const actions = {
    [types.FORGOT_PWD](store, username) {
        return ajax.post(`/global/auth/forgot-pwd`, {
            username
        })
    },
    [types.USER_RESET_PWD](store, payload) {
        return ajax.post(`/global/auth/reset-pwd`, payload)
    },
    [types.STUDENT_RESET_PWD](store, payload) {
        return ajax.post(`/global/auth/reset-pwd`, payload)
    },
    [types.LOGIN](store, payload) {
        return ajax.post(`/global/auth/login`, payload).then((res) => {
            switch (res.type) {
                case USER_TYPES.STUDENT:
                    store.commit(types.STUDENT_JWT, res.jwt)
                    return store.dispatch(types.GET_STUDENT)
                case USER_TYPES.USER:
                    store.commit(types.USER_JWT, res.jwt)
                    return store.dispatch(types.GET_USER)
            }
        })
    },
    [types.STUDENT_REGISTER_WITH_CLASSROOM](store, payload) {
        return ajax.post(`/student/auth/register/classroom/${encodeURI(payload.classroomCode)}`, payload)
    },
    [types.USER_REGISTER](store, payload) {
        return ajax.post(`/user/auth/register`, payload)
            .then((res) => {
                switch (res.type) {
                    case USER_TYPES.USER:
                        store.commit(types.USER_JWT, res.jwt)
                        store.commit(types.GET_USER, res.user)
                }
                return res
            })
    },
    [types.UPDATE_USER](store, payload) {
        return ajax.put("/user/me", payload).then((res) => {
            store.commit(types.UPDATE_USER, res.user)
            return res.user
        })
    },
    [types.UPDATE_STUDENT](store, payload) {
        return ajax.put("/student/me", payload).then((res) => {
            store.commit(types.UPDATE_STUDENT, res.student)
            return res.student
        })
    },
    [types.GET_USER](store, payload) {
        return ajax.get("/user/me").then((res) => {
            store.commit(types.GET_USER, res.user)
        })
    },
    [types.GET_STUDENT](store, payload) {
        return ajax.get("/student/me").then((res) => {
            store.commit(types.GET_STUDENT, res.student)
        })
    },
    [types.LOGIN_STUDENT_WITH_SHORTCODE](store, payload) {
        return ajax.post(`/student/auth/login`, payload).then((res) => {
            store.commit(types.STUDENT_JWT, res.jwt)
            return store.dispatch(types.GET_STUDENT)
        })
    },
    [types.GET_ANONYMOUS](store, payload) {
        return ajax.get("/anonymous/me").then((res) => {
            store.commit(types.GET_ANONYMOUS, res.anonymous)
        })
    },
    [types.ANONYMOUS_LOGIN](store, payload) {
        return ajax.post(`/anonymous/auth/login`, payload).then((res) => {
            store.commit(types.ANONYMOUS_JWT, res.jwt)
            return store.dispatch(types.GET_ANONYMOUS)
        })
    }
}

// mutations
const mutations = {
    [types.LOGIN_STUDENT_WITH_SHORTCODE](state, shortcode) {
        state.studentShortcode = shortcode
        state.finishedSession = false
        window.localStorage.setItem(`kwordz:student-shortcode`, shortcode)
    },
    [types.UPDATE_STUDENT](state, newValues) {
        Object.keys(newValues).forEach((key) => {
            if (typeof state.student[key] !== "undefined") {
                state.student[key] = newValues[key] //update
            }
        })
        selectLang(state.student.lang)
    },
    [types.GET_STUDENT](state, student) {
        state.student = student
        selectLang(student.lang)
    },
    [types.STUDENT_JWT](state, jwt) {
        let withBearer = `Bearer ${jwt}`
        state.studentJwt = jwt
        window.localStorage.setItem(`kwordz:student-jwt`, jwt)
        ajax.defaults.headers.common["Authorization"] = withBearer
        state.studentSocket = createSocket(withBearer, USER_TYPES.STUDENT)
    },
    [types.UPDATE_USER](state, newValues) {
        Object.keys(newValues).forEach((key) => {
            if (typeof state.user[key] !== "undefined") {
                state.user[key] = newValues[key] //update
            }
        })
        selectLang(state.user.lang)
    },
    [types.GET_USER](state, user) {
        state.user = user
        selectLang(user.lang)
    },
    [types.USER_JWT](state, jwt) {
        let withBearer = `Bearer ${jwt}`
        state.userJwt = jwt
        window.localStorage.setItem(`kwordz:user-jwt`, jwt)
        ajax.defaults.headers.common["Authorization"] = withBearer
        state.userSocket = createSocket(withBearer, USER_TYPES.USER)
    },
    [types.ANONYMOUS_JWT](state, jwt) {
        let withBearer = `Bearer ${jwt}`
        state.anonymousJwt = jwt
        window.localStorage.setItem(`kwordz:anonymous-jwt`, jwt)
        ajax.defaults.headers.common["Authorization"] = withBearer
        state.anonymousSocket = createSocket(withBearer, USER_TYPES.ANONYMOUS)
    },
    [types.GET_ANONYMOUS](state, anonymous) {
        state.anonymous = anonymous
        selectLang(anonymous.lang)
    },
    [types.UPDATE_ANONYMOUS](state, newValues) {
        Object.keys(newValues).forEach((key) => {
            if (typeof state.anonymous[key] !== "undefined") {
                state.anonymous[key] = newValues[key] //update
            }
        })
        selectLang(state.anonymous.lang)
    },
    [types.SET_FINISHED_SESSION](state, value) {
        state.finishedSession = value
    },
    [types.LOGOUT](state) {
        state.student = null
        state.user = null
        state.studentShortcode = ""
        state.studentJwt = ""
        state.userEmail = ""
        state.userJwt = ""
        state.anonymous = null
        state.anonymousJwt = ""
        window.localStorage.removeItem(`kwordz:student-jwt`)
        window.localStorage.removeItem(`kwordz:anonymous-jwt`)
        window.localStorage.removeItem(`kwordz:student-shortcode`)
        window.localStorage.removeItem(`kwordz:user-jwt`)
        window.localStorage.removeItem(`kwordz:user-email`)
        ajax.defaults.headers.common["Authorization"] = ""
        ajax.defaults.params = {} //reset
        if (state.studentSocket) {
            state.studentSocket.disconnect()
            state.studentSocket = null
        }
        if (state.userSocket) {
            state.userSocket.disconnect()
            state.userSocket = null
        }
        if (state.anonymousSocket) {
            state.anonymousSocket.disconnect()
            state.anonymousSocket = null
        }
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
