<template>
    <div
        class="flex flex-col justify-start items-start max-h-full h-full w-full overflow-y-auto md:overflow-y-visible md:flex-1 md:h-0">
        <div class="grid grid-cols-2 gap-6 w-full">
            <div class="c__input">
                <label>
                    {{ $t("form.wordGroupTitle") }}
                </label>
                <input
                    type="text"
                    name="text"
                    min="4"
                    max="191"
                    v-model="wordGroup.text" />
                <p
                    class="error"
                    v-if="errors.text">
                    {{ errors.text }}
                </p>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("form.listGroup") }}
                </label>
                <div class="c__select">
                    <select
                        v-model.number="wordGroup.listGroupId"
                        name="listGroupId"
                        class="form-select">
                        <option
                            v-for="listGroup in $store.state.userData.listGroups"
                            :key="listGroup.id"
                            :value="listGroup.id">
                            {{ listGroup.title }}
                        </option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.listGroupId">
                    {{ errors.listGroupId }}
                </p>
            </div>
        </div>

        <div class="md:grid grid-cols-3 gap-6 mt-3 pt-3 border-t border-gray-200 w-full">
            <div>
                <div class="c__input">
                    <label>
                        {{ $t("form.difficulty") }}
                    </label>

                    <select
                        v-model.number="wordGroup.difficulty"
                        name="difficulty"
                        class="form-select">
                        <option
                            value=""
                            selected>
                            {{ $t("global.all") }}
                        </option>
                        <option
                            v-for="wordDifficulty in Object.values(DIFFICULTY)"
                            :value="wordDifficulty">
                            {{ $t(`global.difficulty.${wordDifficulty}`) }}
                        </option>
                    </select>

                    <p
                        class="error"
                        v-if="errors.difficulty">
                        {{ errors.difficulty }}
                    </p>
                </div>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("modal.wordGroup.filterFromExisting") }}
                </label>
                <select
                    name="filterFromWordGroupId"
                    v-model="filterFromWordGroupId"
                    class="form-select">
                    <option
                        value=""
                        selected>
                        {{ $t("global.all") }}
                    </option>
                    <option
                        v-for="wordGroup in listWordGroups"
                        :key="wordGroup.id"
                        :value="wordGroup.id">
                        {{ capitalize(getTeachingTraductionText(wordGroup)) }}
                    </option>
                </select>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("global.search") }}
                </label>

                <div class="relative">
                    <input
                        :placeholder="$t('list.searchWordsPlaceholder')"
                        type="text"
                        v-model="searchValue"
                        name="search"
                        class="pl-12"
                        @keyup.enter.prevent.stop="searchWords" />
                    <Icons
                        icon="search"
                        @click.prevent.stop="searchWords"
                        class="w-7 h-7 block cursor-pointer absolute top-1/2 -translate-y-1/2 transform left-2" />
                </div>
            </div>
        </div>

        <div class="flex flex-row flex-wrap md:flex-nowrap justify-start items-stretch w-full mt-6 flex-1 h-0">
            <div class="w-full md:w-1/2 flex flex-col justify-start items-start md:pr-3">
                <label>
                    {{ $t("modal.wordGroup.addedWords") }}
                    <span class="text-purple-40">{{ wordGroup.wordIds.length }}</span>
                </label>

                <div class="rounded bg-gray-100 p-6 mt-2 flex-1 w-full max-h-full overflow-y-auto">
                    <div class="flex flex-row flex-wrap justify-start items-center max-h-full">
                        <WordStatCard
                            size="small"
                            v-for="(word, idx) in selectedWords"
                            :key="word.id"
                            class="mb-3 mr-3"
                            :label="capitalize(getTeachingTraductionText(word))">
                            <template v-slot:icon>
                                <Icons
                                    @click.prevent="removeWord(word.id)"
                                    icon="delete"
                                    class="cursor-pointer w-full h-full block text-purple" />
                            </template>
                        </WordStatCard>
                        <div class="h-6 w-full"></div>
                    </div>
                </div>
                <p
                    class="error"
                    v-if="errors.wordIds">
                    {{ errors.wordIds }}
                </p>
            </div>

            <div class="w-full md:w-1/2 flex flex-col justify-start items-start md:pl-3 pt-3 md:pt-0">
                <label class="mb-2">
                    {{ $t("modal.wordGroup.availableWords") }}
                    <span class="text-purple-40">{{ allAvailableWords.length }}</span>
                </label>
                <!-- <div v-for="(filter, idx) in filters" :key="idx"
              class="flex flex-row justify-between items-center my-1 px-2 py-1 rounded bg-gray-100 w-full">
           <p class="-small">
             {{ filter.label }}
           </p>
           <div class="c__input py-0" v-if="filter.type === 'difficulty'">
             <select v-model.number="filterDifficulty" name="difficulty" class="form-select -small">
               <option v-for="wordDifficulty in Object.values(DIFFICULTY)" :value="wordDifficulty">
                 {{ $t(`global.difficulty.${wordDifficulty}`) }}
               </option>
             </select>
           </div>
           <Icons icon="trash" @click.prevent="removeFilter(filter.type)"
                  class="cursor-pointer text-purple w-4 h-4 block"/>
         </div> -->

                <div
                    class="rounded bg-gray-100 p-6 mt-2 flex-1 w-full max-h-full overflow-y-auto"
                    ref="scrollZone">
                    <div class="flex flex-row flex-wrap justify-start items-center max-h-full">
                        <div ref="beforeScrollDetector" />
                        <Loader v-if="isLoading" />
                        <template v-else>
                            <WordStatCard
                                size="small"
                                v-for="word in availableWords"
                                :key="word.id"
                                class="mb-3 mr-3"
                                :label="capitalize(getTeachingTraductionText(word))">
                                <template v-slot:icon>
                                    <Icons
                                        @click.prevent="addWord(word.id)"
                                        icon="plus"
                                        class="cursor-pointer w-full h-full block text-purple" />
                                </template>
                            </WordStatCard>
                        </template>
                        <div
                            class="h-6"
                            ref="afterScrollDetector" />
                        <p
                            v-if="availableWords.length > WORDS_LIMIT"
                            class="py-2 text-purple -small text-center w-full">
                            {{ $t("modal.wordGroup.pleaseFilterWords") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createArray } from "@globals/functions"
import { WORD_GROUP_TYPES, DIFFICULTY } from "@globals/constants"
import Icons from "components/globals/Icons"
import WordStatCard from "components/cards/WordStatCard"
import ajax from "config/ajax"
import Loader from "components/Loader"

const WORDS_LIMIT = 360

export default {
    name: "WordGroupForm",
    components: { Loader, WordStatCard, Icons },
    props: {
        wordGroup: Object,
        errors: Object
    },
    data() {
        return {
            WORDS_LIMIT,
            DIFFICULTY,

            searchValue: "",
            filterFromWordGroupId: "",
            page: 1,
            perPage: 60,
            isLoading: true,
            words: []

            //filterDifficulty: null
        }
    },
    watch: {
        searchValue(nextValue) {
            if (!nextValue) {
                this.$nextTick(() => {
                    this.searchWords()
                })
            }
        },
        "wordGroup.difficulty"(nextValue, prevValue) {
            if (nextValue !== prevValue) {
                this.$nextTick(() => {
                    this.searchWords()
                })
            }
        },
        filterFromWordGroupId(nextValue, prevValue) {
            if (!nextValue || nextValue !== prevValue) {
                this.$nextTick(() => {
                    this.searchWords()
                })
            }
        }
    },
    computed: {
        filters() {
            let filters = []

            if (this.filterDifficulty) {
                filters.push({
                    type: "difficulty",
                    label: this.$t("form.difficulty"),
                    value: this.filterDifficulty
                })
            }

            return filters
        },
        listWordGroups() {
            return this.$store.state.userData.wordGroups.filter((wG) => wG.type === WORD_GROUP_TYPES.LIST)
        },
        selectedWords() {
            if (!this.wordGroup) return []

            //grab from store
            return this.wordGroup.wordIds.reduce((acc, wId) => {
                let word = this.$store.state.userData.words.find((w) => w.id === wId)
                if (word) {
                    acc.push(word)
                }
                return acc
            }, [])
        },
        allAvailableWords() {
            return this.words.filter((w) => !this.wordGroup.wordIds.includes(w.id))
        },
        availableWords() {
            return this.allAvailableWords.slice(0, this.perPage * this.page)
        }
    },
    created() {
        this.weekNbSelector = createArray(52)
        this.searchWords()
    },
    mounted() {
        this.observer = new IntersectionObserver(this.onScrollDetectorInView, {
            rootMargin: "0px",
            threshold: 1.0,
            root: this.$refs.scrollZone
        })
        this.observer.observe(this.$refs.afterScrollDetector)
    },
    beforeUnmount() {
        this.observer.unobserve(this.$refs.afterScrollDetector)
        this.observer.disconnect()
    },
    methods: {
        validate() {
            let errors = {}

            if (this.wordGroup.text.length < 4) {
                errors.text = this.$t("validationErrors.titleLength")
            }

            if (!this.wordGroup.listGroupId) {
                errors.listGroupId = this.$t("validationErrors.listGroup")
            }

            if (this.wordGroup.wordIds.length < 10) {
                errors.wordIds = this.$t("validationErrors.wordIdsLength")
            }

            return errors
        },
        onScrollDetectorInView(e) {
            if (this.availableWords.length > WORDS_LIMIT) return
            this.page++
        },
        async searchWords() {
            this.isLoading = true
            let res = await ajax.get(`/user/words/search`, {
                params: {
                    search: this.searchValue,
                    difficulty: this.wordGroup.difficulty ? this.wordGroup.difficulty : undefined,
                    wordGroupId: this.filterFromWordGroupId ? this.filterFromWordGroupId : undefined
                }
            })
            this.page = 1
            this.words = res.words
            this.isLoading = false
        },
        getTeachingTraductionText(item) {
            if (!item) return ""
            let traduction = this.$store.getters.getUserTeachingTraduction(item)
            if (!traduction) return item.text
            return traduction.text
        },
        addWord(wordId) {
            this.wordGroup.wordIds.unshift(wordId) //add to first pos
        },
        removeWord(wordId) {
            let idx = this.wordGroup.wordIds.findIndex((id) => id === wordId)
            this.wordGroup.wordIds.splice(idx, 1)
        },
        onSelectStartFromWordGroup(e) {
            let id = parseInt(e.target.value)
            let wordGroup = this.listWordGroups.find((w) => w.id === id)
            if (!wordGroup) return
            this.wordGroup.wordIds = [...wordGroup.wordIds] //just change the entire array
        }
    }
}
</script>
