<template>
    <StudentHeader>
        <GoBackButton route="/student/list" />
        <h1>
            {{ $t("list.title") }}
        </h1>
    </StudentHeader>
    <WordGroupCard
        class="w-full mt-12"
        v-if="wordGroup"
        :word-group="wordGroup" />

    <WordsByDfficulty :word-group="wordGroup" />
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import WordGroupCard from "components/cards/WordGroupCard"
import GoBackButton from "components/globals/GoBackButton"
import WordsByDfficulty from "components/layouts/WordsByDifficulty"

export default {
    name: "SingleList",
    components: { WordsByDfficulty, GoBackButton, WordGroupCard, StudentHeader },
    data() {
        return {}
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_WORD_GROUP_BY_UUID, this.$route.params.uuid)
        this.$store.dispatch(types.STUDENT_DATA.GET_WORDS_BY_WORD_GROUP_UUID, this.$route.params.uuid)
    },
    computed: {
        wordGroup() {
            return this.$store.state.studentData.wordGroups.find((w) => w.uuid === this.$route.params.uuid) || null
        }
    },
    methods: {}
}
</script>
