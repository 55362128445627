<template>
    <div ref="map" class="w-full h-full mx-auto relative map-game"
         :style="`background-image: url(${currentPlanet.bgImg})`">

        <div ref="player" class="player">
            <Character :direction="direction" :is-hitting="currentAction === GAME_MAP_ACTIONS.HIT" />
        </div>
        <div class="joystick-container">
            <Joystick @move="onJoystickMove" />
        </div>

        <img svg-inline class="icon" :src="gem" />

        {{ currentMapZone }}


    </div>
</template>

<script>
import Character from "components/game/map/Character.vue"
import Joystick from "components/game/map/Joystick.vue"
import gsap from "gsap"
import { DIRECTIONS, GAME_MAP_ACTIONS } from "services/constants"
import clamp from "lodash-es/clamp"
import { PLANETS } from "config/data/3dGame"

import gem1 from "assets/svgs/game/gem-1.svg"
import gem2 from "assets/svgs/game/gem-2.svg"
import gem3 from "assets/svgs/game/gem-3.svg"
import gem4 from "assets/svgs/game/gem-4.svg"
import gem5 from "assets/svgs/game/gem-5.svg"
import gem6 from "assets/svgs/game/gem-6.svg"
import gem7 from "assets/svgs/game/gem-7.svg"
import gem8 from "assets/svgs/game/gem-8.svg"
import gem9 from "assets/svgs/game/gem-9.svg"
import gem10 from "assets/svgs/game/gem-10.svg"
import gem11 from "assets/svgs/game/gem-11.svg"

import pioche1 from "assets/svgs/game/pioche-1.svg"
import pioche2 from "assets/svgs/game/pioche-2.svg"
import pioche3 from "assets/svgs/game/pioche-3.svg"
import pioche4 from "assets/svgs/game/pioche-4.svg"

const GEMS = [
    gem1,
    gem2,
    gem3,
    gem4,
    gem5,
    gem6,
    gem7,
    gem8,
    gem9,
    gem10,
    gem11
]

const PIOCHES = [
    pioche1,
    pioche2,
    pioche3,
    pioche4
]


export default {
    name: "MapGame",
    components: { Joystick, Character },
    data() {
        return {
            GAME_MAP_ACTIONS,

            gem: GEMS[0],
            direction: null,
            currentPlayerPosition: {
                x: 0,
                y: 0,
                xPercent: 0,
                yPercent: 0
            },
            currentAction: null
        }
    },
    created() {
        this.movementIntervalId = null

        window.addEventListener("keydown", this.onKeyHold)
        window.addEventListener("keypress", this.onKeyHold)
        window.addEventListener("keyup", this.onKeyUp)
    },
    mounted() {
        this.setMapZone()
    },
    beforeUnmount() {
        clearInterval(this.movementIntervalId)

        window.removeEventListener("keydown", this.onKeyHold)
        window.removeEventListener("keypress", this.onKeyHold)
        window.removeEventListener("keyup", this.onKeyUp)
    },

    watch: {
        "currentPlayerPosition": {
            handler: function(newVal, oldVal) {
                gsap.to(this.$refs.player, { x: newVal.x, y: newVal.y, duration: 0.08 })
            },
            deep: true
        },
        "direction": function(newVal, oldVal) {
            if (newVal === null) {
                clearInterval(this.movementIntervalId)
                return
            }

            clearInterval(this.movementIntervalId)

            this.movePlayer(newVal)

            this.movementIntervalId = setInterval(() => {
                this.movePlayer(newVal)
            }, 110)
        }
    },
    computed: {
        currentPlanet() {
            return PLANETS["planet2"]//todo
        },
        currentMapZone() {
            const zones = this.currentPlanet.zones

            const currentX = Math.round(this.currentPlayerPosition.xPercent / 100 * 12)
            const currentY = Math.round(this.currentPlayerPosition.yPercent / 100 * 12)

            return zones[currentY][currentX]
        }
    },
    methods: {
        setMapZone() {
            this.$refs.map.style.width = `${this.$refs.map.offsetHeight}px`
        },
        onKeyUp() {
            this.direction = null//reset
            this.currentAction = null//reset
        },
        onKeyHold(e) {
            console.log(e.key)
            let direction = null
            if (e.key === "ArrowUp" || e.key === "w") {
                direction = DIRECTIONS.UP
            } else if (e.key === "ArrowDown" || e.key === "s") {
                direction = DIRECTIONS.DOWN
            } else if (e.key === "ArrowLeft" || e.key === "a") {
                direction = DIRECTIONS.LEFT
            } else if (e.key === "ArrowRight" || e.key === "d") {
                direction = DIRECTIONS.RIGHT
            } else if (e.key === "r") {
                this.currentAction = GAME_MAP_ACTIONS.HIT
            }

            if (this.direction === direction) return
            this.direction = direction
        },
        onJoystickMove({ direction }) {
            this.direction = direction
        },
        movePlayer(direction) {
            const SPEED = 20//todo should depends on screen size to make it uniform

            let newPosition = {
                x: this.currentPlayerPosition.x,
                y: this.currentPlayerPosition.y,
                xPercent: this.currentPlayerPosition.xPercent,
                yPercent: this.currentPlayerPosition.yPercent
            }
            //todo make diagonal move slower by making it / 2
            //up
            if ([DIRECTIONS.UP, DIRECTIONS.RIGHT_UP, DIRECTIONS.LEFT_UP].includes(direction)) {
                newPosition.y -= SPEED
            }
            //down
            if ([DIRECTIONS.DOWN, DIRECTIONS.RIGHT_DOWN, DIRECTIONS.LEFT_DOWN].includes(direction)) {
                newPosition.y += SPEED
            }

            //right
            if ([DIRECTIONS.RIGHT, DIRECTIONS.RIGHT_DOWN, DIRECTIONS.RIGHT_UP].includes(direction)) {
                newPosition.x += SPEED
            }
//left
            if ([DIRECTIONS.LEFT, DIRECTIONS.LEFT_DOWN, DIRECTIONS.LEFT_UP].includes(direction)) {
                newPosition.x -= SPEED
            }

            newPosition.x = clamp(newPosition.x, 0, this.$refs.map.offsetWidth - this.$refs.player.offsetWidth)
            newPosition.y = clamp(newPosition.y, 0, this.$refs.map.offsetHeight - this.$refs.player.offsetHeight)

            newPosition.xPercent = newPosition.x / this.$refs.map.offsetWidth * 100
            newPosition.yPercent = newPosition.y / this.$refs.map.offsetHeight * 100


            //update
            this.currentPlayerPosition = newPosition
        }
    }
}
</script>

<style lang="scss">

.map-game {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.joystick-container {
  position: absolute;
  bottom: 100px;
  right: 100px;
  z-index: 2;
}

.player {
  display: inline-block;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  position: absolute;
  top: 0;
}
</style>
