<template>
    <simplebar
        class="scroll-container"
        v-if="wordsByDifficulty">
        <div
            v-for="difficulty in Object.values(DIFFICULTY)"
            :key="difficulty">
            <div
                class="py-6"
                v-if="wordsByDifficulty[difficulty].length > 0">
                <h2>
                    {{ $t(`global.difficulty.${difficulty}`) }}
                </h2>
                <div class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-4">
                    <WordCard
                        v-for="word in wordsByDifficulty[difficulty]"
                        :key="word.id"
                        :word="word" />
                </div>
            </div>
        </div>
    </simplebar>
</template>

<script>
import WordCard from "components/cards/WordCard"
import Simplebar from "components/globals/Simplebar"
import { DIFFICULTY } from "@globals/constants"

export default {
    name: "WordsByDfficulty",
    components: { Simplebar, WordCard },
    props: {
        wordGroup: Object
    },
    data() {
        return {
            DIFFICULTY
        }
    },
    computed: {
        words() {
            if (this.$store.getters.isUserLogin) {
                return this.$store.state.userData.words
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.state.studentData.words
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.state.anonymousData.words
            }
            return []
        },
        wordsByDifficulty() {
            if (!this.wordGroup) return null

            let result = {
                [DIFFICULTY.EASY]: [],
                [DIFFICULTY.MEDIUM]: [],
                [DIFFICULTY.HARD]: [],
                [DIFFICULTY.VERY_HARD]: []
            }

            for (let word of this.words) {
                if (!this.wordGroup.wordIds.includes(word.id)) {
                    continue
                }
                result[word.difficulty].push(word)
            }
            return result
        }
    },
    methods: {}
}
</script>
