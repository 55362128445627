<template>
    <StudentHeader class="mb-6">
        <h1
            class="hidden xs:inline-block"
            v-if="$store.state.auth.anonymous">
            {{ $t("home.hi") }} {{ $store.state.auth.anonymous.name }}
        </h1>
        <div class="inline-block xs:hidden">
            <CustomButton to="/anonymous/live">
                {{ $t("play.joinLiveGame") }}
            </CustomButton>
        </div>
        <template v-slot:end>
            <div class="hidden xs:inline-block">
                <CustomButton to="/anonymous/live">
                    {{ $t("play.joinLiveGame") }}
                </CustomButton>
            </div>
        </template>
    </StudentHeader>

    <h1
        class="xs:hidden"
        v-if="$store.state.auth.anonymous">
        {{ $t("home.hi") }} {{ $store.state.auth.anonymous.name }}
    </h1>

    <simplebar class="scroll-container">
        <div class="grid grid-cols-1 gap-6 pb-6">
            <WordGroupCard
                class="cursor-pointer"
                v-for="wordGroup in $store.state.anonymousData.wordGroups"
                :key="wordGroup.id"
                :word-group="wordGroup"
                @click.prevent="goToWordGroup(wordGroup.uuid)" />
        </div>
    </simplebar>
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import WordGroupCard from "components/cards/WordGroupCard"
import Simplebar from "components/globals/Simplebar"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "AnonymousPlay",
    components: { CustomButton, Simplebar, WordGroupCard, StudentHeader },
    data() {
        return {}
    },
    created() {
        this.$store.dispatch(types.ANONYMOUS_DATA.GET_GLOBALS)
        this.$store.dispatch(types.ANONYMOUS_DATA.GET_WORD_GROUPS)
    },
    methods: {
        goToWordGroup(uuid) {
            this.$router.push(`/anonymous/list/${uuid}`)
        }
    }
}
</script>
