<template>
    <Popper
        :content="studentUsername"
        :disabled="!withPopper">
        <div
            class="block flex flex-row justify-center items-center transform"
            :class="imageSizeClass">
            <ProductSvgShape
                class="transform scale-125"
                :code="badgeCode"
                :shape-name="`shape-${uuid}`"
                v-slot="slotProps"
                version="avatar">
                <ProductSvgBackground
                    :code="backgroundCode"
                    :background-name="`bg-${uuid}`" />
                <g :clip-path="`url(#shape-${uuid})`">
                    <rect
                        width="200"
                        height="200"
                        x="0"
                        y="0"
                        :fill="`url(#bg-${uuid})`" />
                    <image
                        :height="slotProps.width"
                        :width="slotProps.width"
                        :x="slotProps.x"
                        :y="slotProps.y"
                        :href="avatarUrl" />
                </g>
            </ProductSvgShape>
        </div>
    </Popper>
</template>

<script>
import * as types from "store/types"
import Popper from "components/globals/Popper"
import { v4 } from "uuid"
import ProductSvgShape from "components/globals/ProductSvgShape"
import ProductSvgBackground from "components/globals/ProductSvgBackground"

const SIZES = {
    BIGGER: "bigger",
    BIG: "big",
    NORMAL: "normal",
    SMALL: "small"
}

export default {
    name: "StudentAvatar",

    components: { ProductSvgBackground, ProductSvgShape, Popper },
    props: {
        size: {
            default: "normal",
            type: String
        },
        withPopper: {
            default: false,
            type: Boolean
        },
        student: {
            required: true,
            type: [Object, null]
        }
    },
    data() {
        return {
            SIZES
        }
    },
    watch: {
        student() {
            this.$nextTick(() => {
                this.fetchProducts()
            })
        }
    },
    created() {
        this.uuid = v4()
        this.fetchProducts()
    },
    computed: {
        studentUsername() {
            if (!this.student) return ""

            //if user, show entire name
            if (this.$store.getters.isUserLogin) {
                return this.student.name
            }

            //otherwise show username
            return this.student.username
        },
        productsArr() {
            if (this.$store.getters.isStudentLogin) {
                return this.$store.state.studentData.allProducts
            }
            if (this.$store.getters.isUserLogin) {
                return this.$store.state.userData.products
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.state.anonymousData.allProducts
            }
            return []
        },
        imageSizeClass() {
            switch (this.size) {
                case SIZES.SMALL:
                    return "min-w-10 w-10 h-10 translate-y-1"
                case SIZES.NORMAL:
                    return "min-w-12 w-12 h-12 translate-y-1"
                case SIZES.BIG:
                    return "w-20 h-20 min-w-20 md:min-w-24 md:w-24 md:h-24 translate-y-1  md:translate-y-2"
                case SIZES.BIGGER:
                    return "w-24 h-24 min-w-24 md:min-w-32 md:w-32 md:h-32 translate-y-1  md:translate-y-2"
            }
            return ""
        },
        backgroundCode() {
            if (!this.student || !this.student.backgroundId) return ""

            let product = this.productsArr.find((p) => p.id === this.student.backgroundId)
            if (product) {
                return product.code
            }
            return ""
        },
        badgeCode() {
            if (!this.student || !this.student.badgeId) return ""
            let product = this.productsArr.find((p) => p.id === this.student.badgeId)
            if (product) {
                return product.code
            }
            return ""
        },
        avatarUrl() {
            if (!this.student || !this.student.avatarId) return ""
            let product = this.productsArr.find((p) => p.id === this.student.avatarId)
            if (product) {
                return product.imageUrl
            }
            return ""
        }
    },
    methods: {
        fetchProducts() {
            let values = ["avatarId", "badgeId", "backgroundId"]
            values.forEach((value) => {
                if (this.student && this.student[value]) {
                    //todo optimize backend or frontend to not fetch entire product
                    if (this.$store.getters.isStudentLogin) {
                        this.$store.dispatch(types.STUDENT_DATA.GET_PRODUCT_BY_ID, this.student[value])
                    }
                    if (this.$store.getters.isAnonymousLogin) {
                        this.$store.dispatch(types.ANONYMOUS_DATA.GET_PRODUCT_BY_ID, this.student[value])
                    }
                    if (this.$store.getters.isUserLogin) {
                        this.$store.dispatch(types.USER_DATA.GET_PRODUCT_BY_ID, this.student[value])
                    }
                }
            })
        }
    }
}
</script>
