<template>
    <Card
        :id="`league-${league.id}`"
        :color="`${active ? 'yellow' : 'white'}`">
        <div class="p-2">
            <div class="-m-2 p-2">
                <simplebar
                    v-if="league.students"
                    class="overflow-y-hidden">
                    <div class="flex flex-row justify-start items-center space-x-1 flex-nowrap h-12">
                        <StudentAvatar
                            with-popper
                            v-for="student in league.students"
                            :key="student.id"
                            :student="student"
                            size="small" />
                    </div>
                </simplebar>
            </div>
            <div class="px-2 pb-2 pt-4">
                <h5
                    class="text-gray-200 pb-1"
                    v-if="league.students">
                    {{ league.students.length }} {{ $t("friends.leagueMembers").toLowerCase() }}
                </h5>
                <h3 class="break-words">{{ league.name }}</h3>
                <h4 class="break-words pt-1">@{{ league.slug }}</h4>
                <div class="pt-4">
                    <CustomButton
                        v-if="canEdit && isManager"
                        @click.prevent="$emit('edit', league)">
                        {{ $t("form.btnEdit") }}
                    </CustomButton>
                    <div v-if="canJoin">
                        <CustomButton
                            disabled
                            v-if="invitationAlreadySend">
                            {{ $t("friends.leagueInvitationPending") }}
                        </CustomButton>
                        <CustomButton
                            v-else-if="!studentIsInLeague"
                            @click.prevent="$emit('join', league)">
                            {{ $t("friends.joinLeague") }}
                        </CustomButton>
                        <CustomButton
                            disabled
                            v-else>
                            {{ $t("friends.alreadyInLeague") }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import Simplebar from "components/globals/Simplebar"
import StudentAvatar from "components/globals/StudentAvatar"

export default {
    name: "LeagueCard",
    components: { StudentAvatar, Simplebar, CustomButton, Card },
    emits: ["edit", "join"],
    props: {
        league: {
            required: true,
            type: Object
        },
        invitations: {
            type: Array
        },
        active: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canJoin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        invitationAlreadySend() {
            if (!this.invitations) return false
            return this.invitations.some((lI) => lI.pending)
        },
        isManager() {
            return this.league.studentManagerId === this.$store.state.auth.student.id
        },
        studentIsInLeague() {
            return this.$store.state.studentData.leagues.some((l) => l.id === this.league.id)
        }
    }
}
</script>
