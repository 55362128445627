<template>
    <div class="flex flex-row flex-wrap md:flex-nowrap justify-center items-stretch h-full">
        <div class="w-full h-2/5 md:h-auto md:w-1/2 md:pr-3">
            <div
                v-if="word"
                class="w-full max-h-full h-full relative bg-white bg-center bg-white rounded p-2 flex flex-col justify-center items-center">
                <template v-if="isBonus">
                    <div class="p-2 border-8 rounded border-yellow-200 m-6 absolute inset-0"></div>
                    <div class="absolute top-0 left-0 right-0 flex flex-row justify-center items-center">
                        <div class="bg-white py-2 px-6 mb-12">
                            <h1 class="text-yellow-200 text-center">
                                {{ $t("game.bonusQuestion") }}
                            </h1>
                            <p class="text-yellow-200 text-center">
                                {{ $t("game.2xPoints") }}
                            </p>
                        </div>
                    </div>
                </template>
                <h1 v-if="gameTraduction">
                    {{ capitalize(gameTraduction.text) }}
                </h1>
                <button
                    type="button"
                    class="appearance-none cursor-pointer inline-block mt-3 md:mt-6"
                    @click.prevent.stop="playSound">
                    <Card
                        rounded
                        color="yellow">
                        <div class="p-3">
                            <Icons
                                icon="game-type-hear"
                                class="w-8 h-8 min-w-8 md:w-12 md:min-w-12 md:h-12 block text-white" />
                        </div>
                    </Card>
                </button>
                <audio
                    ref="audioTag"
                    class="hidden"
                    autoplay="false"
                    autostart="0">
                    <source
                        v-if="gameTraduction"
                        :src="gameTraduction.soundUrl"
                        :type="`${gameTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                </audio>
            </div>
        </div>
        <div class="w-full h-3/5 md:h-auto md:w-1/2 md:pl-3 pt-6 md:pt-0">
            <div
                class="grid grid-cols-2 grid-rows-2 gap-6 h-full"
                v-if="currentSection">
                <button
                    v-for="answer in currentSection.answers"
                    :key="answer.id"
                    type="button"
                    class="appearance-none bg-contain bg-no-repeat bg-center cursor-pointer rounded p-2"
                    :class="{
                        'bg-red text-white': answer.wasWrongAnswer,
                        'bg-green text-white': answer.wasGoodAnswer,
                        'bg-white text-purple': !answer.wasGoodAnswer && !answer.wasWrongAnswer
                    }"
                    @click.prevent="sendAnswer(answer.uuid)"
                    :style="`background-image: url(${answer.imageUrl})`"></button>
            </div>
        </div>
    </div>
</template>
<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"

export default {
    name: "GameBattle",
    components: { Icons, Card },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    computed: {
        isBonus() {
            return this.currentSection && this.currentSection.isBonus
        },
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        gameTraduction() {
            if (!this.word || !this.game) return null
            return this.word.traductions.find((t) => t.lang === this.game.lang)
        },
        traduction() {
            if (!this.currentSection) return null
            return this.$store.getters.getStudentTraduction(this.word)
        }
    },
    watch: {
        currentSection(nextValue) {
            //make sure we play sound only on new word value
            if (!nextValue.isReal) return

            this.$nextTick(async () => {
                this.playSound()
            })
        }
    },
    mounted() {
        //play on init
        this.playSound()
    },
    methods: {
        async playSound() {
            if (!this.$refs.audioTag) return
            this.$refs.audioTag.autoplay = false

            try {
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
            } catch (e) {}
        },
        sendAnswer(answerUuid) {
            if (!this.currentSection.isReal) return
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: answerUuid
            })
        }
    }
}
</script>
