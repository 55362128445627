<template>
    <div
        class="flex justify-center items-center"
        :class="{
            'flex-row shadow-xl p-1 md:p-2 bg-white rounded-full': version === 'complete',
            'flex-row-reverse': version === 'simple'
        }">
        <h3
            class="font-medium pt-0.5 pl-1"
            :class="{
                'text-yellow-200 mr-2': version === 'complete',
                'ml-2': version === 'simple',
                'text-gray-200': version === 'simple' && value === 0,
                'text-yellow-200': version === 'simple' && value > 0
            }">
            {{ value }}
        </h3>
        <div class="rounded-full min-w-6 w-6 h-6 block bg-gradient-yellow flex flex-row justify-center items-center">
            <h3 class="text-white">
                {{ type }}
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: "PointTag",
    props: {
        value: [String, Number],
        type: {
            default: "K",
            type: String
        },
        version: {
            type: String,
            default: "complete"
        }
    }
}
</script>
