<template>
    <img svg-inline ref="img" />
</template>


<script>

import front1 from "assets/svgs/game/characters/front-1.svg"
import front2 from "assets/svgs/game/characters/front-2.svg"
import front3 from "assets/svgs/game/characters/front-3.svg"
import front4 from "assets/svgs/game/characters/front-4.svg"
import front5 from "assets/svgs/game/characters/front-5.svg"


import back1 from "assets/svgs/game/characters/back-1.svg"
import back2 from "assets/svgs/game/characters/back-2.svg"
import back3 from "assets/svgs/game/characters/back-3.svg"
import back4 from "assets/svgs/game/characters/back-4.svg"
import back5 from "assets/svgs/game/characters/back-5.svg"
import back6 from "assets/svgs/game/characters/back-6.svg"
import back7 from "assets/svgs/game/characters/back-7.svg"

import right1 from "assets/svgs/game/characters/right-1.svg"
import right2 from "assets/svgs/game/characters/right-2.svg"
import right3 from "assets/svgs/game/characters/right-3.svg"
import right4 from "assets/svgs/game/characters/right-4.svg"
import right5 from "assets/svgs/game/characters/right-5.svg"
import right6 from "assets/svgs/game/characters/right-6.svg"
import right7 from "assets/svgs/game/characters/right-7.svg"
import right8 from "assets/svgs/game/characters/right-8.svg"

import left1 from "assets/svgs/game/characters/left-1.svg"
import left2 from "assets/svgs/game/characters/left-2.svg"
import left3 from "assets/svgs/game/characters/left-3.svg"
import left4 from "assets/svgs/game/characters/left-4.svg"
import left5 from "assets/svgs/game/characters/left-5.svg"
import left6 from "assets/svgs/game/characters/left-6.svg"
import left7 from "assets/svgs/game/characters/left-7.svg"
import left8 from "assets/svgs/game/characters/left-8.svg"


import hit1 from "assets/svgs/game/characters/hit-1.svg"
import hit2 from "assets/svgs/game/characters/hit-2.svg"
import hit3 from "assets/svgs/game/characters/hit-3.svg"
import hit4 from "assets/svgs/game/characters/hit-4.svg"
import hit5 from "assets/svgs/game/characters/hit-5.svg"
import hit6 from "assets/svgs/game/characters/hit-6.svg"

import { DIRECTIONS } from "services/constants"

const rightImages = [right1, right2, right3, right4, right5, right6, right7, right8]
const leftImages = [left1, left2, left3, left4, left5, left6, left7, left8]
const frontImages = [front1, front2, front3, front4, front5]
const backImages = [back1, back2, back3, back4, back5, back6, back7]
const hitImages = [hit1, hit2, hit3, hit4, hit5, hit6]
export default {
    name: "Character",

    props: {
        type: String,
        direction: String,
        isHitting: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            DIRECTIONS
        }
    },
    created() {
        this.imageIdx = 0
        this.lastImage = right1//default
        this.lastDirection = this.direction
    },
    mounted() {
        this.$refs.img.src = this.lastImage
    },
    watch: {
        "isHitting": function(newVal, oldVal) {
            if (newVal === true) {
                this.startAnimation(hitImages)
            } else {
                this.stopAnimation()
                this.imageIdx = 0
            }
        },
        "direction": function(newVal, oldVal) {
            if (newVal === null) {
                this.stopAnimation()
                return
            }


            if (this.lastDirection !== newVal) {
                this.imageIdx = 0
            }

            if (newVal === DIRECTIONS.RIGHT) {
                this.startAnimation(rightImages)
            } else if (newVal === DIRECTIONS.DOWN) {
                this.startAnimation(frontImages)
            } else if (newVal === DIRECTIONS.UP) {
                this.startAnimation(backImages)
            } else if (newVal === DIRECTIONS.LEFT) {
                this.startAnimation(leftImages)
            }

            this.lastDirection = newVal
        }
    },
    beforeUnmount() {
        this.stopAnimation()
    },
    methods: {
        stopAnimation() {
            clearInterval(this.animationId)
        },
        startAnimation(images) {
            clearInterval(this.animationId)

            this.setImage(images)
            this.animationId = setInterval(() => {
                this.setImage(images)
            }, 110)//movement speed
        },
        setImage(images) {
            this.$refs.img.src = images[this.imageIdx]
            this.lastImage = images[this.imageIdx]

            this.imageIdx++
            if (this.imageIdx >= images.length) {
                this.imageIdx = 0//restart animation
            }
        }
    }
}
</script>

<style lang="scss" scoped>
img {
  display: block;
  width: auto;
  height: 70px;
}
</style>
