<template>
    <Card
        class="w-full"
        v-if="version === VERSIONS.DEFAULT">
        <div :class="containerClass">
            <div class="xs:flex flex-row flex-wrap justify-start items-center md:space-x-3 p-3">
                <button
                    v-for="item in options"
                    type="button"
                    :key="item.value"
                    @click.prevent="$emit('click', item.value)"
                    class="py-2 px-3 text-16 rounded font-medium transition duration-75"
                    :class="`${item.value === value ? 'bg-gray-100 text-purple' : 'text-gray-200'}`">
                    {{ item.label }}
                </button>
            </div>
            <slot></slot>
        </div>
    </Card>
    <div
        class="w-full"
        v-if="version === VERSIONS.SIMPLE">
        <div :class="containerClass">
            <div class="xs:flex flex-row flex-wrap justify-start items-center md:space-x-3">
                <button
                    v-for="item in options"
                    type="button"
                    :key="item.value"
                    @click.prevent="$emit('click', item.value)"
                    class="py-2 px-3 text-16 rounded font-medium transition duration-75"
                    :class="`${item.value === value ? 'bg-gray-100 text-purple' : 'text-gray-200'}`">
                    {{ item.label }}
                </button>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Card from "components/cards/Card"

const VERSIONS = {
    DEFAULT: "default",
    SIMPLE: "simple"
}

export default {
    name: "TabbedButtons",
    components: { Card },
    emits: ["click"],
    props: {
        value: [String, Number],
        version: {
            type: String,
            default: VERSIONS.DEFAULT
        },
        containerClass: {
            type: String,
            default: ""
        },
        options: {
            default: () => [],
            type: Array
        }
    },
    data() {
        return {
            VERSIONS
        }
    }
}
</script>
