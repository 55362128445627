import { createRouter, createWebHistory } from "vue-router"
import store from "store/index"
import UserPlay from "views/user/UserPlay"
import Login from "views/auth/login/Login"
import AuthLayout from "views/layouts/AuthLayout"
import UserForgotPassword from "views/auth/forgot-pwd/UserForgotPassword"
import UserChangePassword from "views/auth/change-pwd/UserChangePassword"
import Register from "views/auth/register/Register"
import RegisterInstructionsParent from "views/auth/register/RegisterInstructionsParent"
import RegisterInstructionsTeacher from "views/auth/register/RegisterInstructionsTeacher"
import RegisterInstructionsStudent from "views/auth/register/RegisterInstructionsStudent"
import RegisterInstructionsSchoolManager from "views/auth/register/RegisterInstructionsSchoolManager"
import RegisterWelcome from "views/auth/register/RegisterWelcome"
import * as types from "store/types"
import StudentList from "views/student/List"
import StudentLiveBattle from "views/student/LiveBattle"
import StudentFriends from "views/student/Friends"
import StudentShop from "views/student/Shop"
import StudentInventory from "views/student/Inventory"
import StudentScoreboard from "views/student/Scoreboard"
import StudentSettings from "views/student/Settings"
import StudentHome from "views/student/Home"
import StudentProgression from "views/student/Progression"
import StudentSingleList from "views/student/SingleList"
import StudentMapGame from "views/student/game/MapGame"
import StudentListGame from "views/student/game/ListGame"
import StudentLiveGame from "views/student/game/LiveGame"
import AppLayout from "views/layouts/AppLayout"
import UserSettings from "views/user/UserSettings"
import UserStudents from "views/user/UserStudents"
import UserStudentStats from "views/user/UserStudentStats"
import UserList from "views/user/UserList"
import UserSingleList from "views/user/UserSingleList"
import UserLiveGame from "views/user/game/UserLiveGame"
import StudentNotifications from "views/student/Notifications"
import UserBilling from "views/user/UserBilling"
import AnonymousPlay from "views/anonymous/AnonymousPlay"
import AnonymousSingleList from "views/anonymous/AnonymousSingleList"
import AnonymousListGame from "views/anonymous/AnonymousListGame"
import RegisterStudentWithClassroom from "views/auth/register/RegisterStudentWithClassroom"
import AnonymousLiveGame from "views/anonymous/AnonymousLiveGame"
import AnonymousJoinLiveGame from "views/anonymous/AnonymousJoinLiveGame"
import Privacy from "views/Privacy"
import Terms from "views/Terms"
import UserClassroomStats from "views/user/UserClassroomStats"
import MapGame from "views/student/game/MapGame.vue"

const routes = [
    {
        path: "/student",
        name: "student",
        component: AppLayout,
        meta: {
            needStudentLogin: true
        },
        children: [
            {
                path: "",
                name: "student-index",
                component: StudentHome
            },
            {
                path: "list",
                name: "student-list",
                component: StudentList
            },
            {
                path: "list/:uuid",
                name: "student-single-list",
                component: StudentSingleList
            },
            {
                path: `list/:uuid/map`,
                name: `student-list-game-map`,
                component: StudentMapGame,
                meta: {
                    inGame: true
                }
            },
            {
                path: `list/:uuid/:gameType`,
                name: `student-list-game`,
                component: StudentListGame,
                meta: {
                    inGame: true
                }
            },
            {
                path: `live/:uuid/map`,
                name: `student-live-game-map`,
                component: StudentMapGame,
                meta: {
                    inGame: true
                }
            },
            {
                path: `live/:uuid/:gameType`,
                name: `student-live-game`,
                component: StudentLiveGame,
                meta: {
                    inGame: true
                }
            },
            {
                path: "live-battle",
                name: "student-live-battle",
                component: StudentLiveBattle
            },
            {
                path: "friends",
                name: "student-friends",
                component: StudentFriends
            },
            {
                path: "shop",
                name: "student-shop",
                component: StudentShop
            },
            {
                path: "inventory",
                name: "student-inventory",
                component: StudentInventory
            },
            {
                path: "scoreboard",
                name: "student-scoreboard",
                component: StudentScoreboard
            },
            {
                path: "progress",
                name: "student-progress",
                component: StudentProgression
            },
            {
                path: "settings",
                name: "student-settings",
                component: StudentSettings
            },
            {
                path: "notifications",
                name: "student-notifications",
                component: StudentNotifications
            }
        ]
    },
    {
        path: "/user",
        name: "user",
        component: AppLayout,
        meta: {
            needUserLogin: true
        },
        children: [
            {
                path: "",
                name: "user-index",
                component: UserPlay
            },
            {
                path: "settings",
                name: "user-settings",
                component: UserSettings
            },
            {
                path: "students",
                name: "user-students",
                component: UserStudents
            },
            {
                path: "students/:uuid",
                name: "user-student-stats",
                component: UserStudentStats
            },
            {
                path: "classrooms/:uuid",
                name: "user-classroom-stats",
                component: UserClassroomStats
            },
            {
                path: "list",
                name: "user-list",
                component: UserList
            },
            {
                path: "play/:uuid",
                name: "user-single-list",
                component: UserSingleList
            },
            {
                path: "billing",
                name: "user-billing",
                component: UserBilling
            },
            {
                path: `live/:uuid/:gameType`,
                name: `user-live-game`,
                meta: {
                    inGame: true
                },
                component: UserLiveGame
            }
        ]
    },
    {
        path: "/anonymous",
        name: "anonymous",
        component: AppLayout,
        children: [
            {
                path: "",
                name: "anonymous-index",
                component: AnonymousPlay
            },
            {
                path: "list/:uuid",
                name: "anonymous-single-list",
                component: AnonymousSingleList
            },
            {
                path: `live`,
                name: `anonymous-join-live-game`,
                component: AnonymousJoinLiveGame
            },
            {
                path: `list/:uuid/:gameType`,
                name: `anonymous-list-game`,
                component: AnonymousListGame,
                meta: {
                    inGame: true,
                    needAnonymousLogin: true
                }
            },
            {
                path: `live/:uuid/:gameType`,
                name: `anonymous-live-game`,
                component: AnonymousLiveGame,
                meta: {
                    inGame: true,
                    needAnonymousLogin: true
                }
            }
        ]
    },
    {
        path: "/auth",
        name: "auth",
        component: AuthLayout,
        children: [
            {
                path: "",
                name: "auth-index",
                redirect: "/auth/login"
            },
            {
                path: "login",
                name: "auth-login",
                component: Login
            },
            {
                path: "register",
                name: "auth-register",
                component: Register,
                children: [
                    {
                        path: "",
                        name: "auth-register-index",
                        component: RegisterWelcome
                    },
                    {
                        path: "parent",
                        name: "auth-register-parent",
                        component: RegisterInstructionsParent
                    },
                    {
                        path: "teacher",
                        name: "auth-register-teacher",
                        component: RegisterInstructionsTeacher
                    },
                    {
                        path: "student",
                        name: "auth-register-student",
                        component: RegisterInstructionsStudent
                    },
                    {
                        path: "student/classroom",
                        name: "auth-register-student-classroom",
                        component: RegisterStudentWithClassroom
                    },
                    {
                        path: "school-manager",
                        name: "auth-register-school-manager",
                        component: RegisterInstructionsSchoolManager
                    },
                    {
                        path: "/:catchAll(.*)",
                        redirect: "/"
                    }
                ]
            },
            {
                path: "forgot-pwd",
                name: "forgot-pwd",
                component: UserForgotPassword
            },
            {
                path: "change-pwd",
                name: "change-pwd",
                component: UserChangePassword
            },
            {
                path: "logout",
                name: "auth-logout",
                component: () => {}
            },
            {
                path: "/:catchAll(.*)",
                redirect: "/"
            }
        ]
    },

    {
        path: "/map-game",
        name: "map-game",
        component: MapGame
    },

    {
        path: "/privacy",
        name: "privacy",
        component: Privacy
    },

    {
        path: "/terms",
        name: "terms",
        component: Terms
    },
    {
        path: "/",
        redirect: () => {
            if (store.state.auth.student) return "/student"
            if (store.state.auth.user) return "/user"
            return "/auth/login"
        }
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/"
    }
]

const router = createRouter({
    history: createWebHistory(process.env.WEBSITE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    let needStudentLogin = to.matched.some((route) => route.meta.needStudentLogin)
    let needUserLogin = to.matched.some((route) => route.meta.needUserLogin)
    let needAnonymousLogin = to.matched.some((route) => route.meta.needAnonymousLogin)

    if (to.name === "auth-logout") {
        next("/auth/login")
        //make sure its reset on next tick
        setTimeout(() => store.commit(types.LOGOUT), 1)
        return
    }

    if (to.name === "anonymous-live-game" && store.state.auth.studentJwt) {
        return next({ name: "student-live-game", params: to.params })
    }

    if (needAnonymousLogin && !store.state.auth.anonymousJwt) {
        let url = "/anonymous"
        //save old url so if the user grab a game link, once connected he will be send there
        if (to.name.startsWith("anonymous") && to.name !== "anonymous-index" && to.name !== "anonymous") {
            url += `?next_url=${to.path}`
        }
        return next(url)
    }
    if (needStudentLogin && !store.state.auth.studentJwt) {
        return next("/auth/login")
    }
    if (needUserLogin && !store.state.auth.userJwt) {
        return next("/auth/login")
    }
    next()
})

export default router
