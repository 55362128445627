<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="mb-6">
            {{ $t("form.editLeague") }}
        </h2>
        <form @submit.prevent="submit">
            <LeagueForm
                ref="leagueForm"
                :errors="errors"
                :league="league" />
            <div class="inline-block mt-12">
                <CustomButton type="submit">
                    {{ $t("form.btnEdit") }}
                </CustomButton>
            </div>
        </form>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import LeagueForm from "components/forms/LeagueForm"

export default {
    components: { LeagueForm, CustomButton, Modal },
    emits: ["close"],
    name: "UpdateLeagueModal",
    props: {
        league: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            errors: {}
        }
    },
    methods: {
        submit() {
            this.errors = this.$refs.leagueForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store.dispatch(types.STUDENT_DATA.UPDATE_LEAGUE, this.league)
            this.$emit("close")
        }
    }
}
</script>
