<template>
    <Card
        rounded
        :color="`${disabled ? 'gray' : 'yellow'}`"
        v-if="version === 'default'"
        class="inline-block">
        <router-link
            v-if="to"
            :to="to"
            :class="className">
            <slot></slot>
        </router-link>
        <button
            v-else
            :type="type"
            :class="className">
            <slot></slot>
        </button>
    </Card>
    <router-link
        v-else-if="to"
        :to="to"
        :class="className">
        <slot></slot>
    </router-link>
    <a
        v-else-if="href"
        :href="href"
        :target="target"
        :class="className">
        <slot></slot>
    </a>
    <button
        v-else
        :type="type"
        :class="className">
        <slot></slot>
    </button>
</template>
<script>
import Card from "components/cards/Card"

export const BASE_BUTTON_CLASS =
    " leading-normal custom-btn w-full transition duration-250 block cursor-pointer font-sans appearance-none"
export default {
    name: "CustomButton",
    components: { Card },
    props: {
        version: {
            type: String,
            default: "default"
        },
        active: {
            type: Boolean,
            default: false
        },
        to: String,
        href: String,
        target: {
            type: String,
            default: "_self"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "normal"
        },
        type: {
            type: String,
            default: "button"
        },
        align: {
            type: String,
            default: "center"
        },
        wrap: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        className() {
            let className = BASE_BUTTON_CLASS

            if (!this.wrap) {
                className += ` whitespace-nowrap `
            }

            if (this.version === "text") {
                //tw -> text-left text-center text-right
                className += ` font-medium hover:underline text-${this.align}`

                if (this.size === "big") {
                    className += " text-16 font-bold"
                } else if (this.size === "small") {
                    className += " text-12 p-2"
                } else {
                    className += " text-14"
                }

                if (this.disabled) {
                    className += " text-gray-200 pointer-events-none"
                } else {
                    className += " text-yellow"
                }

                return className
            }

            //default

            className += ` font-medium rounded-full text-${this.align}`

            if (this.size === "big") {
                className += " text-16 font-bold px-4 py-2"
            } else if (this.size === "small") {
                className += " text-12 p-2"
            } else {
                className += " text-14 py-2 px-3"
            }

            if (this.disabled) {
                className += " text-gray-200 pointer-events-none"
            } else {
                className += " text-white"
            }

            return className
        }
    }
}
</script>
