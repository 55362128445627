<template>
    <div class="w-full h-full">
        <simplebar class="scroll-container">
            <div
                class="p-6"
                v-if="statsWords">
                <StudentHeader2 :student="student" />
                <div class="border-t border-purple-10 mt-12 pt-12">
                    <h2>
                        {{ $t("stats.myProgress") }}
                    </h2>
                    <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-6 mt-6">
                        <WordStatCard
                            v-for="(progress, idx) in progressions"
                            :key="idx"
                            :label="progress.label"
                            :value="progress.value" />
                    </div>
                </div>
                <div class="border-t border-purple-10 mt-12 pt-12">
                    <h2>
                        {{ $t("stats.myLog") }}
                    </h2>
                    <div class="grid grid-cols-1 xl:grid-cols-2 gap-12 mt-6">
                        <div
                            v-for="(section, idx) in sections"
                            :key="idx"
                            class="h-full flex flex-col justify-start items-start">
                            <div class="flex flex-row flex-wrap justify-start items-center w-full">
                                <Icons
                                    :icon="section.icon"
                                    class="w-8 h-8 mr-1 block text-purple" />
                                <h3>
                                    {{ section.label }}
                                </h3>
                            </div>

                            <div class="rounded bg-gray-100 p-6 mt-4 flex-1 w-full">
                                <div class="flex flex-row flex-wrap justify-start items-center">
                                    <WordStatCard
                                        v-for="(word, idx) in section.words"
                                        :key="idx"
                                        class="mb-3 mr-3"
                                        :data-id="word.id"
                                        :label="capitalize(getLearningTraductionText(word))"
                                        :value="word.wordAnswer[section.valueKey]">
                                        <template v-slot:icon>
                                            <Icons
                                                :icon="section.icon"
                                                class="min-w-6 h-6 w-6 mr-1 block text-purple" />
                                        </template>
                                    </WordStatCard>
                                </div>
                                <p
                                    class="text-purple -small"
                                    v-if="section.words.length === 0">
                                    <template v-if="$store.getters.isStudentLogin">
                                        {{ $t("stats.noStats") }}
                                    </template>
                                    <template v-if="$store.getters.isUserLogin && $store.state.auth.user.isTeacher">
                                        {{ $t("stats.noStatsFromTeacher") }}
                                    </template>
                                    <template v-if="$store.getters.isUserLogin && $store.state.auth.user.isParent">
                                        {{ $t("stats.noStatsFromParent") }}
                                    </template>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="statsGames"
                    class="border-t border-purple-10 mt-12 pt-12">
                    <h2>
                        {{ $t("stats.myGames") }}
                    </h2>
                    <ul class="mt-6 space-y-3">
                        <li class="text-purple h4">
                            {{ $t("stats.totalTimePlayed") }}:
                            <span v-if="totalTimePlayedTimer.hours > 0">
                                {{ padStart(totalTimePlayedTimer.hours, 2, "0") }}h{{
                                    padStart(totalTimePlayedTimer.minutes, 2, "0")
                                }}
                            </span>
                            <span v-else-if="totalTimePlayedTimer.minutes > 9">
                                {{ padStart(totalTimePlayedTimer.minutes, 2, "0") }} {{ $t("global.minutes") }}
                            </span>
                            <span v-else> {{ totalTimePlayedTimer.minutes }} {{ $t("global.minutes") }} </span>
                        </li>
                        <li class="text-purple h4">
                            {{ $t("stats.totalGamesPlayed") }}: {{ statsGames.totalStudentGamesCount }}
                        </li>
                        <li class="text-purple h4">
                            {{ $t("stats.totalPodiumCount") }}: {{ statsGames.totalPodiumCount }}
                        </li>
                        <li class="text-purple h4">
                            {{ $t("stats.totalPerfectGameCount") }}: {{ statsGames.totalPerfectGameCount }}
                        </li>
                        <li class="text-purple h4">{{ $t("stats.successRate") }}: {{ statsWords.successRate }}%</li>
                        <li class="text-purple h4">
                            {{ $t("stats.totalScore") }}: {{ statsGames.totalScore }} {{ $t("global.points") }}
                        </li>
                    </ul>
                </div>

                <div
                    v-if="statsGames"
                    class="mt-6 pt-6">
                    <StudentGamesTable
                        :student="student"
                        :games="statsGames.games" />
                </div>
            </div>
        </simplebar>
    </div>
</template>

<script>
import WordStatCard from "components/cards/WordStatCard"
import Icons from "components/globals/Icons"
import StudentHeader2 from "components/globals/StudentHeader2"
import Simplebar from "components/globals/Simplebar"
import { msToTime } from "@globals/functions"
import StudentGamesTable from "components/layouts/StudentGamesTable"
import padStart from "lodash-es/padStart"

export default {
    name: "StudentProgression",
    components: { StudentGamesTable, Simplebar, StudentHeader2, Icons, WordStatCard },
    props: {
        student: Object,
        statsWords: Object,
        statsGames: Object
    },
    data() {
        return {
            padStart: padStart
        }
    },
    computed: {
        sections() {
            if (!this.statsWords) return []
            return [
                {
                    label: this.$t("stats.wordsEasy"),
                    icon: "thumb-up",
                    words: this.statsWords.easyWords,
                    valueKey: "goodResult"
                },
                {
                    label: this.$t("stats.wordsDifficult"),
                    icon: "smiley",
                    words: this.statsWords.mostDifficultWords,
                    valueKey: "badResult"
                }
            ]
        },
        progressions() {
            if (!this.statsWords) return []
            return [
                {
                    label: this.$t("stats.wordsMastered"),
                    value: this.statsWords.masteredWordsCount
                },
                {
                    label: this.$t("stats.wordsLearnt"),
                    value: this.statsWords.learntWordsCount
                },
                {
                    label: this.$t("stats.wordsDifficult"),
                    value: this.statsWords.difficultWordsCount
                },
                {
                    label: this.$t("stats.wordsUnseen"),
                    value: this.statsWords.unseenWordsCount
                }
            ]
        },
        totalTimePlayedTimer() {
            if (!this.statsGames) return null
            return msToTime(this.statsGames.totalTimeMsPlayed)
        }
    },
    methods: {
        getLearningTraductionText(word) {
            if (!word) return ""
            let traduction = null
            if (this.$store.getters.isUserLogin) {
                traduction = word.traductions.find((t) => t.lang === this.student.learningLang) || null
            }
            if (this.$store.getters.isStudentLogin) {
                traduction = this.$store.getters.getStudentLearningTraduction(word)
            }

            if (!traduction) return word.text
            return traduction.text
        }
    }
}
</script>
