import planet2Bg from "assets/images/games/background/planet2.png"

const ZONES = {
    SELL:"sell",
    QUESTION:"question",
    GO_BACK:"go_back"
}


export const PLANETS = {
    mainShip: {},
    planet1: {},
    planet2: {
        zones: [
            //1     2     3     4    5     6     7     8     9     10     11    12
            [null, null, null, null, null, null, null, null, null, null, null, null],// 1
            [null, null, null, null, null, null, null, null, null, null, null, null],// 2
            [null, null, null, null, null, null, null, null, null, null, null, null],// 3
            [null, null, ZONES.SELL, null, null, null, null, null, null, null, null, null],// 4
            [null, null, null, null, null, null, null, null, null, null, null, null],// 5
            [null, null, null, null, null, null, null, null, null, null, null, null],// 6
            [null, null, null, null, null, null, null, null, null, null, null, null],// 7
            [null, null, null, null, null, null, null, null, null, null, null, null],// 8
            [null, null, null, null, null, ZONES.QUESTION, null, null, null, null, null, null],// 9
            [null, null, null, null, null, null, null, null, null, null, null, null],// 10
            [null, ZONES.GO_BACK, null, null, null, null, null, null, null, null, null, null],// 11
            [null, null, null, null, null, null, null, null, null, null, null, null]// 12
        ],
        bgImg: planet2Bg
    }
}
