<template>
    <div class="flex flex-row flex-wrap md:flex-nowrap justify-center items-stretch h-full">
        <div class="w-full md:w-1/2 md:pr-3">
            <div
                v-if="word"
                class="w-full max-h-full h-full bg-white bg-contain bg-no-repeat bg-center border-6 rounded border-white"
                :style="`background-image: url(${word.imageUrl})`"></div>
        </div>
        <div class="w-full md:w-1/2 md:pl-3 pt-6 md:pt-0">
            <div
                class="grid grid-cols-2 grid-rows-2 gap-6 h-full"
                v-if="currentSection">
                <button
                    v-for="answer in currentSection.answers"
                    :key="answer.id"
                    type="button"
                    class="appearance-none cursor-pointer rounded p-2 flex flex-col justify-center items-center text-center h2"
                    :class="{
                        'bg-red text-white': answer.wasWrongAnswer,
                        'bg-green text-white': answer.wasGoodAnswer,
                        'bg-white text-purple': !answer.wasGoodAnswer && !answer.wasWrongAnswer
                    }"
                    @click.prevent="sendAnswer(answer.uuid)">
                    <span v-if="getGameTraduction(answer)">
                        {{ capitalize(getGameTraduction(answer).text) }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "GameCards",
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    computed: {
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        traduction() {
            if (!this.currentSection) return null
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentTraduction(this.word)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousTraduction(this.word)
            }
            return null
        }
    },
    methods: {
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        },
        sendAnswer(answerUuid) {
            if (!this.currentSection.isReal) return
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: answerUuid
            })
        }
    }
}
</script>
