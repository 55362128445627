<template>
    <div class="w-full h-full max-h-full">
        <Loader v-if="isLoading || localIsLoading" />
        <div
            class="flex flex-col justify-start items-start w-full h-full max-h-full"
            v-else>
            <h2>
                {{ $t("billing.plansInfo") }}
            </h2>
            <simplebar class="w-full h-0 flex-1">
                <div class="">
                    <div
                        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10 mt-6"
                        v-if="$store.state.userData.paymentPlan.studentPlans.length > 0">
                        <div class="w-1/4">
                            <p class="-smaller font-medium text-purple">
                                {{ $t("billing.price") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smaller font-medium text-purple">
                                {{ $t("billing.childrenName") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smaller font-medium text-purple">
                                {{ $t("billing.period") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smaller font-medium text-purple">
                                {{ $t("billing.status") }}
                            </p>
                        </div>
                    </div>
                    <div v-if="$store.state.userData.paymentPlan">
                        <div
                            class="flex flex-row justify-start items-center space-x-1 w-full py-2"
                            v-for="(studentPlan, idx) in $store.state.userData.paymentPlan.studentPlans"
                            :key="studentPlan.id"
                            :class="{
                                'border-t border-purple-10': idx !== 0
                            }">
                            <div class="w-1/4">
                                <p
                                    class="-small text-purple"
                                    v-if="studentPlan.subscription">
                                    ${{ studentPlan.subscription.price }} {{ $t("billing.priceInfo") }}
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else>
                                    {{ $t("billing.subscriptionCanceled") }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p class="-small text-purple">
                                    {{ studentPlan.student.name }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p
                                    class="-small text-purple"
                                    v-if="studentPlan.subscription">
                                    {{ moment(studentPlan.subscription.billingPeriodStartDate).format("LL") }}<br />
                                    {{ moment(studentPlan.subscription.billingPeriodEndDate).format("LL") }}
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else>
                                    {{ $t("billing.subscriptionCanceled") }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p
                                    class="-small text-purple"
                                    v-if="studentPlan.subscription">
                                    <span>
                                        {{
                                            $t(
                                                `global.braintreeStatus.${studentPlan.subscription.status.toLowerCase()}`
                                            )
                                        }}
                                    </span>
                                    <span
                                        class="inline-block text-yellow underline cursor-pointer ml-2"
                                        @click.prevent="confirmCancelStudentSubscription(studentPlan.student)">
                                        {{ $t("billing.btnCancelSubscription") }}
                                    </span>
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else>
                                    <span>
                                        {{ $t("billing.subscriptionCanceled") }}
                                    </span>
                                    <span
                                        class="inline-block text-yellow underline cursor-pointer ml-2"
                                        v-if="paymentData"
                                        @click.prevent="createStudentSubscription(studentPlan.student)">
                                        {{ $t("billing.btnActiveSubscription") }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <p
                            v-if="$store.state.userData.paymentPlan.studentPlans.length === 0"
                            class="text-purple -small mt-2">
                            {{ $t("billing.noStudents") }}
                        </p>
                        <p
                            v-if="!paymentData"
                            class="mt-2 error -small">
                            {{ $t("billing.noPaymentMethodParent") }}
                        </p>
                    </div>
                </div>
            </simplebar>
        </div>
        <CancelStudentSubscriptionModal
            :student="currentStudentCancelSubscription"
            @cancel="cancelStudentSubscription"
            @close="showCancelStudentSubscriptionModal = false"
            v-if="showCancelStudentSubscriptionModal" />
    </div>
</template>

<script>
import Loader from "components/Loader"
import Simplebar from "components/globals/Simplebar"
import ajax from "config/ajax"
import * as types from "store/types"
import CancelStudentSubscriptionModal from "components/modals/user/CancelStudentSubscriptionModal"

export default {
    name: "StudentSubscriptions",
    components: { CancelStudentSubscriptionModal, Simplebar, Loader },
    emits: ["changed"],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localIsLoading: false,
            currentStudentCancelSubscription: null,
            showCancelStudentSubscriptionModal: false
        }
    },
    computed: {
        paymentData() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.paymentData
        }
    },
    methods: {
        confirmCancelStudentSubscription(student) {
            this.currentStudentCancelSubscription = student
            this.showCancelStudentSubscriptionModal = true
        },
        async cancelStudentSubscription(student) {
            this.localIsLoading = true
            this.currentStudentCancelSubscription = null
            this.showCancelStudentSubscriptionModal = false

            await ajax.post(`/user/students/${student.uuid}/subscription/cancel`)

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.localIsLoading = false

            this.$emit("changed")
        },
        async createStudentSubscription(student) {
            this.localIsLoading = true
            await ajax.post(`/user/students/${student.uuid}/subscription/create`)

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.localIsLoading = false

            this.$emit("changed")
        }
    }
}
</script>
