<template>
    <StudentHeader class="mb-6">
        <h1>
            {{ $t("friends.title") }}
        </h1>
        <AddButton @click.prevent="createLeague" />
    </StudentHeader>

    <simplebar class="scroll-container">
        <div
            class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mb-12"
            v-if="$store.state.studentData.leagues.length > 0">
            <LeagueCard
                v-for="league in $store.state.studentData.leagues"
                :key="league.id"
                @edit="editLeague"
                :can-edit="true"
                :league="league" />
        </div>

        <div class="pb-6">
            <h2 class="pb-2">
                {{ $t("friends.findALeague") }}
            </h2>
            <form @submit.prevent="searchLeagues">
                <div class="c__input">
                    <div class="flex flex-row justify-start items-center space-x-2 w-full">
                        <div class="relative flex-1">
                            <input
                                :placeholder="$t('friends.searchLeaguePlaceholder')"
                                class="pl-5"
                                type="text"
                                name="searchValue"
                                min="4"
                                max="191"
                                v-model="searchValue" />
                            <span class="absolute left-1 top-2">@</span>
                        </div>
                        <CustomButton type="submit">
                            {{ $t("global.search") }}
                        </CustomButton>
                    </div>
                </div>
            </form>

            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-3">
                <LeagueCard
                    v-for="league in leagues"
                    :key="league.id"
                    :can-join="true"
                    :invitations="getLeagueInvitations(league)"
                    @join="joinLeague"
                    :league="league" />
            </div>

            <p
                v-if="leagues.length === 0"
                class="text-purple -small">
                {{ $t("friends.noLeagues") }}
            </p>
        </div>
    </simplebar>

    <CreateLeagueModal
        v-if="showCreateLeagueModal"
        @close="closeModal" />
    <UpdateLeagueModal
        :league="editLeagueData"
        v-if="showUpdateLeagueModal"
        @close="closeModal" />
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import AddButton from "components/globals/AddButton"
import CreateLeagueModal from "components/modals/student/CreateLeagueModal"
import LeagueCard from "components/cards/LeagueCard"
import UpdateLeagueModal from "components/modals/student/UpdateLeagueModal"
import CustomButton from "components/globals/CustomButton"
import ajax from "config/ajax"
import Simplebar from "components/globals/Simplebar"
import EventBus, { EVENTS } from "@/eventbus"

export default {
    name: "Friends",
    components: { Simplebar, CustomButton, UpdateLeagueModal, LeagueCard, CreateLeagueModal, AddButton, StudentHeader },
    data() {
        return {
            showCreateLeagueModal: false,
            showUpdateLeagueModal: false,
            editLeagueData: null,

            searchValue: "",
            leagues: []
        }
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_FRIENDS)
        this.$store.dispatch(types.STUDENT_DATA.GET_LEAGUES)
        this.$store.dispatch(types.STUDENT_DATA.GET_LEAGUE_INVITATIONS)

        this.searchLeagues()
    },
    methods: {
        getLeagueInvitations(league) {
            return this.$store.state.studentData.leagueInvitations.filter((lI) => lI.leagueId === league.id)
        },
        searchLeagues() {
            return ajax
                .get(`/student/leagues/search`, {
                    params: {
                        search: this.searchValue
                    }
                })
                .then((res) => {
                    this.leagues = res.leagues
                })
        },
        createLeague() {
            this.showCreateLeagueModal = true
        },
        editLeague(league) {
            this.showUpdateLeagueModal = true
            this.editLeagueData = {
                ...league
            }
        },
        async joinLeague(league) {
            this.$store.dispatch(types.STUDENT_DATA.JOIN_LEAGUE, league.uuid)
            EventBus.emit(EVENTS.SHOW_FLASH, {
                title: this.$t("flash.success"),
                message: this.$t("flash.leagueInvitationSend"),
                type: "success"
            })
        },
        closeModal() {
            this.showUpdateLeagueModal = false
            this.showCreateLeagueModal = false
            this.editLeagueData = null
        }
    }
}
</script>
