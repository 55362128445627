<template>
    <div
        class="py-2"
        @click.prevent="onClick"
        :class="{ 'cursor-pointer': !showHasSeen }">
        <div class="w-full flex flex-row justify-between items-center space-x-3">
            <div class="w-full flex flex-row justify-start items-center space-x-2">
                <h4 v-if="notification.type === NOTIFICATION_TYPES.GAME_INVITATION">
                    {{ $t("notifications.gameInvitation") }}
                </h4>
                <h4 v-if="notification.type === NOTIFICATION_TYPES.LEAGUE_INVITATION">
                    {{ $t("notifications.leagueInvitation") }}
                </h4>
                <h4 v-if="notification.type === NOTIFICATION_TYPES.LEAGUE_REQUEST">
                    {{ $t("notifications.leagueRequest") }}
                </h4>
                <div class="w-1 h-1 rounded-full bg-purple" />
                <p class="text-purple -small">
                    {{ capitalize(moment.utc(notification.createdAt).fromNow()) }}
                </p>

                <span
                    v-if="!showHasSeen"
                    class="block bg-red w-1.5 h-1.5 rounded-full" />
            </div>
            <Icons
                icon="cross"
                class="w-4 h-4 cursor-pointer text-purple"
                @click.prevent="remove" />
        </div>
        <div class="pt-2 flex flex-row justify-between items-center">
            <p
                class="text-purple -small"
                v-if="notification.type === NOTIFICATION_TYPES.GAME_INVITATION">
                {{ $t("notifications.receivedGameInvitation") }}
            </p>
            <p
                class="text-purple -small"
                v-if="notification.type === NOTIFICATION_TYPES.LEAGUE_INVITATION && league">
                {{ $t("notifications.receivedLeagueInvitation", { name: league.name }) }}
            </p>
            <p
                class="text-purple -small"
                v-if="notification.type === NOTIFICATION_TYPES.LEAGUE_REQUEST && league">
                {{
                    $t("notifications.receivedLeagueRequest", {
                        name: league.name,
                        studentName: notification.data.fromStudentName
                    })
                }}
            </p>

            <div>
                <template v-if="notification.type === NOTIFICATION_TYPES.GAME_INVITATION">
                    <h4
                        v-if="canJoinGame"
                        class="font-bold cursor-pointer"
                        @click.prevent="joinLiveBattle">
                        {{ $t("notifications.btnJoinGame") }}
                    </h4>
                    <h4
                        v-else
                        class="font-normal">
                        {{ $t("notifications.gameIsFinish") }}
                    </h4>
                </template>

                <template v-if="notification.type === NOTIFICATION_TYPES.LEAGUE_REQUEST && leagueInvitation">
                    <div
                        class="flex flex-row justify-end items-center space-x-3"
                        v-if="leagueInvitation.pending">
                        <h4
                            @click.prevent="rejectLeagueRequest"
                            class="cursor-pointer font-bold">
                            {{ $t("notifications.btnRejectRequest") }}
                        </h4>
                        <h4
                            @click.prevent="acceptLeagueRequest"
                            class="cursor-pointer font-bold">
                            {{ $t("notifications.btnAcceptRequest") }}
                        </h4>
                    </div>
                    <div v-else>
                        <p
                            class="text-purple -small"
                            v-if="leagueInvitation.accepted">
                            {{ $t("notifications.statusAccepted") }}
                        </p>
                        <p
                            class="text-purple -small"
                            v-if="leagueInvitation.rejected">
                            {{ $t("notifications.statusRejected") }}
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import ajax from "config/ajax"
import Icons from "components/globals/Icons"
import * as types from "store/types"
import { NOTIFICATION_TYPES } from "@globals/constants"

export default {
    components: { Icons },
    props: {
        notification: Object
    },
    data() {
        return {
            showHasSeen: false,
            canJoinGame: false,
            NOTIFICATION_TYPES
        }
    },
    async created() {
        this.showHasSeen = this.notification.seen
        this.seen()

        if (this.notification.type === NOTIFICATION_TYPES.LEAGUE_REQUEST) {
            this.$store.dispatch(
                types.STUDENT_DATA.GET_LEAGUE_INVITATION_BY_UUID,
                this.notification.data.leagueInvitationUuid
            )
            this.$store.dispatch(types.STUDENT_DATA.GET_LEAGUES)
        }

        if (this.notification.type === NOTIFICATION_TYPES.GAME_INVITATION) {
            let res = await ajax.get(`/student/live-games/${this.notification.data.gameUuid}`)
            if (res.success) {
                this.canJoinGame = true
            }
        }
    },
    computed: {
        leagueInvitation() {
            if (this.notification.type !== NOTIFICATION_TYPES.LEAGUE_REQUEST) return null
            return this.$store.state.studentData.leagueInvitations.find(
                (lI) => lI.uuid === this.notification.data.leagueInvitationUuid
            )
        },
        league() {
            if (this.notification.type !== NOTIFICATION_TYPES.LEAGUE_REQUEST || !this.leagueInvitation) return null
            return this.$store.state.studentData.leagues.find((l) => l.id === this.leagueInvitation.leagueId)
        }
    },
    methods: {
        async acceptLeagueRequest() {
            if (!this.leagueInvitation) return
            let res = ajax.post(`/student/league-invitations/${this.leagueInvitation.uuid}/accept`)

            if (!res.success) return

            //update store
            //todo the received should receive websocket to update his league
            this.$store.commit(types.STUDENT_DATA.GET_LEAGUE_INVITATION_BY_UUID, res.leagueInvitation)
        },
        rejectLeagueRequest() {
            if (!this.leagueInvitation) return
            let res = ajax.post(`/student/league-invitations/${this.leagueInvitation.uuid}/reject`)

            if (!res.success) return
            //update store
            this.$store.commit(types.STUDENT_DATA.GET_LEAGUE_INVITATION_BY_UUID, res.leagueInvitation)
        },
        async joinLiveBattle() {
            //todo check if game is not finished and dont show the join game button
            let res = await ajax.post(`/student/live-games/${this.notification.data.gameUuid}/join`)

            const { game, success } = res

            if (!success) return

            this.$router.push(`/student/live/${game.uuid}/${game.gameType.slug}`)
        },

        seen() {
            //only if not seen
            if (!this.notification.seen) {
                this.showHasSeen = false
                this.$store.dispatch(types.STUDENT_DATA.SEEN_NOTIFICATION_BY_UUID, this.notification.uuid)
            }
        },
        onClick() {
            this.showHasSeen = true
        },
        remove() {
            this.$store.dispatch(types.STUDENT_DATA.REMOVE_NOTIFICATION_BY_UUID, this.notification.uuid)
        }
    }
}
</script>
