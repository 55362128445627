<template>
    <div class="w-full h-full">
        <Card class="h-full">
            <div class="p-3 flex flex-col justify-start items-start h-full">
                <h1 v-if="playerName">
                    {{ $t("game.congrats", { name: playerName }) }}
                </h1>
                <div class="pt-6">
                    <h2>
                        {{ $t("game.myScore") }}
                    </h2>
                    <div class="flex flex-row justify-start items-center pt-3 space-x-10">
                        <h2 class="text-yellow font-medium">
                            <span class="font-bold">{{ studentGameData.totalScore }}</span> {{ $t("global.points") }}
                        </h2>
                        <GameTimer
                            tag="h2"
                            :timer="totalGameTimer"
                            class="block text-yellow font-bold" />
                        <template v-if="studentGameData.studentGame">
                            <h2 class="text-yellow font-medium">
                                <span class="font-bold">{{ studentGameData.studentGame.pointsXP }}</span> xp
                            </h2>
                            <h2 class="text-yellow font-medium">
                                <span class="font-bold">{{ studentGameData.studentGame.pointsK }}</span> K
                            </h2>
                            <h2 class="text-yellow font-medium">
                                <span class="font-bold">{{ studentGameData.studentGame.pointsZ }}</span> Z
                            </h2>
                        </template>
                    </div>
                </div>
                <div class="pt-6 w-full">
                    <h3 v-if="badResults.length === 0">
                        {{ $t("game.perfectGame") }}
                    </h3>
                    <h3 v-else>
                        {{ $t("game.missedWords") }}
                    </h3>
                    <div
                        class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 mt-6"
                        v-if="badResults.length > 0">
                        <WordCard
                            v-for="word in badResults"
                            :key="word.id"
                            :word="word" />
                    </div>
                </div>
                <div class="pt-6 flex-1 h-0 w-full">
                    <CurrentGameScoreboard :game-data="gameData" />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import { msToTime } from "@globals/functions"
import GameTimer from "components/game/GameTimer"
import WordCard from "components/cards/WordCard"
import uniqBy from "lodash-es/uniqBy"
import CurrentGameScoreboard from "components/game/CurentGameScoreboard"

export default {
    name: "StudentGameResult",
    emits: ["restart", "end"],
    components: { CurrentGameScoreboard, WordCard, GameTimer, Card },
    props: {
        studentGameData: Object,
        gameData: Object,
        game: Object
    },
    computed: {
        totalGameTimer() {
            return msToTime(this.studentGameData.totalGameTimeMs)
        },
        badResults() {
            //remove goodResult and duplicate
            return uniqBy(
                this.studentGameData.results.filter((r) => !r.goodResult),
                "id"
            )
        },
        playerName() {
            if (this.$store.getters.isStudentLogin) {
                return this.$store.state.auth.student.firstName
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.state.auth.anonymous.name
            }
            return null
        }
    },
    methods: {
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        }
    }
}
</script>
