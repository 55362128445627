<template>
    <StudentHeader class="mb-6">
        <h1>
            {{ $t("scoreboard.title") }}
        </h1>
    </StudentHeader>

    <simplebar class="scroll-container">
        <div class="pb-6">
            <Card
                class="w-full mt-6"
                v-for="standing in standings"
                :key="standing.type">
                <div class="p-6">
                    <h4>{{ standing.label }}</h4>
                    <div class="pt-6">
                        <div
                            class="flex flex-row justify-between items-center w-full p-3 border-t border-purple-10"
                            v-for="(student, idx) in standing.rows"
                            :class="{
                                'bg-gray-100': student.id === $store.state.auth.student.id,
                                'border-b': idx + 1 === standing.rows.length
                            }"
                            :key="student.id">
                            <div class="flex flex-row justify-start items-center">
                                <span class="block text-14 font-bold text-purple min-w-12">
                                    {{ student.rank }}
                                </span>
                                <p class="text-purple px-4">
                                    {{ student.name }}
                                </p>
                            </div>
                            <span>
                                <span class="text-14 font-bold text-purple">{{
                                    formatThousand(student.totalScore)
                                }}</span
                                >&nbsp;<span class="text-14 text-purple">{{ $t("global.points") }}</span>
                            </span>
                        </div>
                    </div>
                    <div
                        class="flex flex-row justify-center items-center pt-6"
                        v-if="standing.canSeeMore">
                        <div class="inline">
                            <CustomButton
                                version="text"
                                @click.prevent="toggle(standing.type)">
                                {{ $data[standing.type].seeMore ? $t("scoreboard.seeLess") : $t("scoreboard.seeMore") }}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    </simplebar>
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import Simplebar from "components/globals/Simplebar"

const SEE_LESS_QTY = 5
const SEE_MORE_QTY = 10

export default {
    name: "Scoreboard",
    components: { Simplebar, CustomButton, Card, StudentHeader },
    data() {
        return {
            general: {
                quantity: SEE_LESS_QTY,
                seeMore: false
            },
            school: {
                quantity: SEE_LESS_QTY,
                seeMore: false
            },
            leagues: {
                quantity: SEE_LESS_QTY,
                seeMore: false
            }
        }
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_STANDINGS)
    },
    computed: {
        standings() {
            let storeStandings = this.$store.state.studentData.standings
            let studentId = this.$store.state.auth.student.id
            let studentGeneralRanking = storeStandings.general.find((s) => s.id === studentId)
            let studentLeaguesRanking = storeStandings.leagues.find((s) => s.id === studentId)
            let studentSchoolRanking = storeStandings.school.find((s) => s.id === studentId)

            //todo myClassrooms

            return [
                {
                    label: this.$t("scoreboard.general"),
                    type: "general",
                    canSeeMore: storeStandings.general.length > SEE_MORE_QTY,
                    rows: this.createRows(storeStandings.general, studentGeneralRanking, this.general.quantity)
                },
                //only show if we have a teacher
                ...(this.$store.state.auth.student.teacherId
                    ? [
                          {
                              label: this.$t("scoreboard.mySchool"),
                              type: "school",
                              canSeeMore: storeStandings.school.length > SEE_MORE_QTY,
                              rows: this.createRows(storeStandings.school, studentSchoolRanking, this.school.quantity)
                          }
                      ]
                    : []),
                {
                    label: this.$t("scoreboard.myLeagues"),
                    type: "leagues",
                    canSeeMore: storeStandings.leagues.length > SEE_MORE_QTY,
                    rows: this.createRows(storeStandings.leagues, studentLeaguesRanking, this.leagues.quantity)
                }
            ]
        }
    },
    methods: {
        createRows(data, student, quantity) {
            let standings = data.slice(0, quantity)

            //student is not in top 5 or 10
            if (student && student.rank > quantity) {
                standings.push(student)
            }
            return standings
        },
        toggle(type) {
            if (this.$data[type].seeMore) {
                this.$data[type].seeMore = false
                this.$data[type].quantity = SEE_LESS_QTY
                return
            }

            //see more
            this.$data[type].seeMore = true
            this.$data[type].quantity = SEE_MORE_QTY
        }
    }
}
</script>
