<template>
    <div
        class="appearance-none cursor-pointer shadow-xl rounded-full"
        type="button"
        :class="`${active ? 'bg-yellow-200' : 'bg-white'}`">
        <div class="flex flex-row justify-start items-center p-1 md:p-2">
            <Card
                :color="`${active ? 'white' : 'yellow'}`"
                rounded>
                <div class="p-1">
                    <Icons
                        :icon="icon"
                        class="w-4 h-4 md:w-8 md:h-8 block"
                        :class="`${active ? 'text-yellow-200' : 'text-white'}`" />
                </div>
            </Card>
            <h3
                class="px-2 md:px-3 font-medium"
                :class="`${active ? 'text-white' : 'text-purple'}`">
                {{ label }}
            </h3>
        </div>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"

export default {
    name: "BadgeButton",
    components: { Icons, Card },
    props: {
        active: Boolean,
        icon: String,
        label: String
    }
}
</script>
