<template>
    <StudentHeader class="mb-6">
        <h1>
            {{ $t("shop.title") }}
        </h1>
    </StudentHeader>

    <div class="w-full">
        <h2 class="mb-3">
            {{ $t("shop.filterBy") }}
        </h2>
        <TabbedButtons
            @click="currentProductCategoryId = $event"
            :value="currentProductCategoryId"
            :options="$store.getters.productCategoriesOptions" />
    </div>

    <simplebar class="scroll-container">
        <div class="pb-6">
            <!-- <PurchaseCta class="mb-6"/> -->
            <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
                <ProductCard
                    v-for="product in filteredProducts"
                    :key="product.id"
                    :product="product"
                    @buy="buyProduct"
                    @use="useProduct" />
            </div>
        </div>
    </simplebar>
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import TabbedButtons from "components/globals/TabbedButtons"
import ProductCard from "components/cards/ProductCard"
import Simplebar from "components/globals/Simplebar"

export default {
    name: "Shop",
    components: { Simplebar, ProductCard, TabbedButtons, StudentHeader },
    data() {
        return {
            currentProductCategoryId: null
        }
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_MY_PRODUCTS)
        this.$store.dispatch(types.STUDENT_DATA.GET_BOUGHT_PRODUCTS)
    },
    computed: {
        filteredProducts() {
            if (!this.currentProductCategoryId) {
                return this.$store.getters.sortedMyProducts
            }
            return this.$store.getters.sortedMyProducts.filter((p) => {
                return p.productCategoryId === this.currentProductCategoryId
            })
        }
    },
    methods: {
        buyProduct(productUuid) {
            this.$store.dispatch(types.STUDENT_DATA.BUY_PRODUCT_BY_UUID, productUuid)
        },
        useProduct(productUuid) {
            this.$store.dispatch(types.STUDENT_DATA.USE_PRODUCT_BY_UUID, productUuid)
        }
    }
}
</script>
