<template>
    <div
        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10"
        v-if="games.length > 0">
        <div class="w-1/5">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.startedAt") }}
            </p>
        </div>
        <div class="w-1/5">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.gameTime") }}
            </p>
        </div>
        <div class="w-1/5">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.wordGroup") }}
            </p>
        </div>
        <div class="w-1/5">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.gameType") }}
            </p>
        </div>
        <div class="w-1/5">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.result") }}
            </p>
        </div>
    </div>
    <div
        class="flex flex-row justify-start items-center space-x-1 w-full py-2"
        v-for="(game, idx) in completeGames"
        :key="game.id"
        :class="{
            'border-t border-purple-10': idx !== 0
        }">
        <div class="w-1/5">
            <p class="-small text-purple">
                {{ moment(game.createdAt).format("D MMMM YYYY HH:mm") }}
            </p>
        </div>
        <div class="w-1/5">
            <p
                class="-small text-purple"
                v-if="game.finishedAt && game.wasUserFinished">
                {{ moment(game.finishedAt).diff(moment(game.startedAt), "minutes") }} minutes
            </p>
            <p
                class="-small text-purple"
                v-else>
                {{ $t("game.gameNotFinish") }}
            </p>
        </div>
        <div class="w-1/5">
            <div class="inline-block">
                <CustomButton
                    version="text"
                    align="left"
                    wrap
                    :to="`/user/play/${game.wordGroup.uuid}`"
                    v-if="game.wordGroup">
                    {{ game.wordGroup.text }}
                </CustomButton>
            </div>
        </div>
        <div class="w-1/5">
            <p class="-small text-purple">
                {{ $t(`global.gameTypes.${game.gameType.slug}`) }}
            </p>
        </div>
        <div class="w-1/5">
            <div class="inline-block">
                <CustomButton
                    version="text"
                    @click.prevent="seeGameResult(game)"
                    v-if="canSeeGameResult(game)">
                    {{ $t("game.seeResult") }}
                </CustomButton>
                <p
                    class="-small text-purple"
                    v-else>
                    {{ $t("game.cantSeeResult") }}
                </p>
            </div>
        </div>
    </div>
    <p
        class="text-purple -small mt-2"
        v-if="games.length === 0">
        {{ $t("game.noGames") }}
    </p>

    <UserGameStatModal
        :game="currentGameStat"
        v-if="showGameStatModal"
        @close="showGameStatModal = false" />
</template>

<script>
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import UserGameStatModal from "components/modals/user/UserGameStatModal"

export default {
    name: "ClassroomGamesTable",
    components: { UserGameStatModal, CustomButton },
    props: {
        games: Array,
        students: Array
    },
    data() {
        return {
            currentGameStat: null,
            showGameStatModal: false
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)
    },
    computed: {
        completeGames() {
            return this.games.map((g) => {
                let wordGroup = this.$store.state.userData.wordGroups.find((wG) => wG.id === g.wordGroupId) || null

                if (wordGroup) {
                    let learningTraduction = this.$store.getters.getUserTeachingTraduction(wordGroup)
                    if (learningTraduction) {
                        wordGroup.text = learningTraduction.text
                    }
                }

                return {
                    ...g,
                    studentGames: g.studentGames.map((sG) => {
                        sG.student = this.students.find((s) => s.id === sG.studentId) || null
                        return sG
                    }),
                    wordGroup: wordGroup
                }
            })
        }
    },
    methods: {
        canSeeGameResult(game) {
            return game.studentGames.some((sG) => !!sG.student)
        },
        seeGameResult(game) {
            this.currentGameStat = game
            this.showGameStatModal = true
        }
    }
}
</script>
