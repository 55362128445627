<template>
    <modal
        :show="true"
        @close="close"
        v-if="notification">
        <h2 class="mt-6 text-center px-6">
            {{ $t("modal.newGameInvitation.title") }}
        </h2>

        <Card v-if="wordGroup">
            <div class="flex flex-row justify-between items-center p-3">
                <div>
                    <Image
                        class="h-20 w-28 min-w-28 md:w-40 md:min-w-40"
                        :image-url="wordGroup.imageUrl"
                        :alt="wordGroup.text" />
                </div>
                <div>
                    <h5 class="text-purple opacity-60 uppercase">
                        {{ $t(`global.gameTypes.${game.gameType.slug}`) }}
                    </h5>
                    <h2
                        class="pt-2"
                        v-if="traduction">
                        {{ capitalize(traduction.text) }}
                    </h2>
                    <h2
                        class="pt-2"
                        v-else-if="wordGroup">
                        {{ capitalize(wordGroup.text) }}
                    </h2>
                </div>

                <div class="flex flex-col">
                    <h3 class="text-center text-purple-40 font-bold uppercase">{{ $t("game.code") }}:</h3>
                    <h1 class="text-center pt-1 whitespace-nowrap">
                        {{ cleanCode }}
                    </h1>
                </div>
            </div>
        </Card>

        <div class="flex flex-row space-x-6 justify-center items-center mt-6">
            <div>
                <CustomButton
                    version="text"
                    @click.prevent="close">
                    {{ $t("modal.newGameInvitation.btnClose") }}
                </CustomButton>
            </div>
            <div>
                <CustomButton
                    @click.prevent="joinLiveBattle"
                    v-if="notification.data.gameUuid">
                    {{ $t("notifications.btnJoinGame") }}
                </CustomButton>
            </div>
        </div>
        <p
            class="error"
            v-if="error">
            {{ error }}
        </p>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import ajax from "config/ajax"
import * as types from "store/types"
import Card from "components/cards/Card"
import Image from "components/globals/Image"
import { cleanGameCode } from "helpers/helpers"
import { tryAsyncFunc } from "@globals/helpers/async"

export default {
    name: "NewGameInvitation",
    components: { Image, Card, CustomButton, Modal },
    emits: [],
    data() {
        return {
            game: null,
            wordGroup: null,
            error: null
        }
    },
    computed: {
        notification() {
            //dont show popup if we are already in a game
            if (this.$route.meta.inGame) return null

            return this.$store.state.studentData.notifications.find((n) => !n.seen)
        },
        traduction() {
            if (!this.wordGroup) return null
            return this.wordGroup.traductions.find((t) => t.lang === this.game.lang)
        },
        cleanCode() {
            if (!this.game) return null
            return cleanGameCode(this.game.code)
        }
    },
    watch: {
        notification() {
            this.$nextTick(() => {
                if (this.notification) {
                    this.getGame()
                }
            })
        }
    },
    methods: {
        async getGame() {
            if (!this.notification.data.gameUuid) return
            let [res, err] = await tryAsyncFunc(ajax.get)(`/student/live-games/${this.notification.data.gameUuid}`)
            if (err) {
                //todo show error
                return
            }

            const { game, success } = res

            if (!success) return

            this.game = game
            this.wordGroup =
                this.$store.state.studentData.wordGroups.find((wG) => wG.id === this.game.wordGroupId) || null
        },
        seen() {
            if (!this.notification) return
            this.$store.dispatch(types.STUDENT_DATA.SEEN_NOTIFICATION_BY_UUID, this.notification.uuid)
        },
        close() {
            this.seen()
            this.game = null
            this.wordGroup = null
        },
        async joinLiveBattle() {
            if (!this.notification) return
            if (!this.notification.data.gameUuid) return

            //todo check if game is not finished and dont show the join game button
            this.close()

            let [res, err] = await tryAsyncFunc(ajax.post)(
                `/student/live-games/${this.notification.data.gameUuid}/join`
            )

            if (err) {
                //todo show error
                return
            }

            const { game, success } = res

            if (!success) return

            this.$router.push(`/student/live/${game.uuid}/${game.gameType.slug}`)
        }
    }
}
</script>
