<template>
    <div class="py-6 w-full h-full">
        <Card class="h-full w-full">
            <div class="p-3 flex flex-col justify-start items-start h-full">
                <div class="pt-6 w-full flex flex-col justify-start items-start">
                    <h1>{{ $t("scoreboard.podium") }}</h1>
                    <div class="flex flex-row justify-center items-stretch space-x-2 pt-6 flex-1 w-full">
                        <GamePodiumStep
                            v-if="secondPlace"
                            :step="2"
                            :student-game-data="secondPlace" />
                        <GamePodiumStep
                            v-if="firstPlace"
                            :step="1"
                            :student-game-data="firstPlace" />
                        <GamePodiumStep
                            v-if="thirdPlace"
                            :step="3"
                            :student-game-data="thirdPlace" />
                    </div>
                </div>
                <div class="pt-6 flex-1 w-full">
                    <CurrentGameScoreboard :game-data="gameData" />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import { msToTime } from "@globals/functions"
import CurrentGameScoreboard from "components/game/CurentGameScoreboard"
import GamePodiumStep from "components/game/GamePodiumStep"

export default {
    name: "UserLiveGameResult",
    emits: [],
    components: { GamePodiumStep, CurrentGameScoreboard, Card },
    props: {
        gameData: Object,
        game: Object
    },
    computed: {
        totalGameTimer() {
            return msToTime(this.studentGameData.totalGameTimeMs)
        },
        thirdPlace() {
            if (this.gameData.studentGames.length < 3) return null
            return this.gameData.studentGames[2]
        },
        secondPlace() {
            if (this.gameData.studentGames.length < 2) return null
            return this.gameData.studentGames[1]
        },
        firstPlace() {
            if (this.gameData.studentGames.length < 1) return null
            return this.gameData.studentGames[0]
        }
    },
    methods: {}
}
</script>
