<template>
    <UserHeader class="block lg:hidden mb-6">
        <h1>
            {{ $t("licenses.title") }}
        </h1>
    </UserHeader>
    <div class="max-h-full h-full w-full grid grid-cols-1 grid-rows-3 xl:grid-cols-5 xl:grid-rows-5 gap-6 py-6">
        <Card class="w-full col-span-5 xl:col-span-3 row-span-1 xl:row-span-3">
            <StudentSubscriptions
                @changed="getData"
                :is-loading="isLoading"
                class="p-4"
                v-if="$store.state.auth.user.isParent" />
            <TeacherSubscriptions
                v-else-if="$store.state.auth.user.isTeacher"
                @changed="getData"
                :is-loading="isLoading"
                class="p-4" />
            <SchoolManagerSubscriptions
                v-else
                @changed="getData"
                :is-loading="isLoading"
                class="p-4" />
        </Card>
        <div class="col-span-5 xl:col-span-2 row-span-1 xl:row-span-3">
            <Card class="w-full h-full">
                <PaymentMethod
                    :is-loading="isLoading"
                    class="max-h-full h-full p-4" />
            </Card>
        </div>
        <div class="col-span-5 row-span-1 xl:row-span-2">
            <Card class="w-full h-full">
                <Transactions
                    :is-loading="isLoading"
                    class="p-4" />
            </Card>
        </div>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import * as types from "store/types"
import padStart from "lodash-es/padStart"
import UserHeader from "components/globals/UserHeader"
import PaymentMethod from "components/payment/PaymentMethod"
import Transactions from "components/payment/Transactions"
import SchoolManagerSubscriptions from "components/payment/SchoolManagerSubscriptions"
import StudentSubscriptions from "components/payment/StudentSubscriptions"
import TeacherSubscriptions from "components/payment/TeacherSubscriptions"

export default {
    name: "UserBilling",
    components: {
        TeacherSubscriptions,
        StudentSubscriptions,
        SchoolManagerSubscriptions,
        Transactions,
        PaymentMethod,
        UserHeader,
        Card
    },
    props: {},
    data() {
        return {
            padStart,

            isLoading: true
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            this.isLoading = true

            await Promise.all([
                this.$store.dispatch(types.USER_DATA.GET_PAYMENTS),
                this.$store.dispatch(types.USER_DATA.GET_CURRENT_PAYMENT_PLAN),
                this.$store.dispatch(types.GET_USER) //so we refetch licenseId if needed
            ])

            this.isLoading = false
        }
    }
}
</script>
