<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2>
            {{ $t("modal.cancelSubscription.title", { name: student.name }) }}
        </h2>

        <p class="my-4">
            {{ $t("modal.cancelSubscription.description") }}
        </p>

        <CustomButton @click.prevent="$emit('cancel', student)">
            {{ $t("modal.cancelSubscription.btn") }}
        </CustomButton>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "CancelStudentSubscriptionModal",
    components: { CustomButton, Modal },
    emits: ["close", "cancel"],
    props: {
        student: Object
    }
}
</script>
