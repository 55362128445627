<template>
    <Card>
        <div class="flex flex-row justify-start items-stretch p-3 space-x-3">
            <img
                v-if="imageUrl"
                class="h-28 min-w-32 w-32 object-cover object-center rounded overflow-hidden"
                :alt="word.text"
                :src="imageUrl" />
            <div class="py-2 flex flex-col justify-between items-start">
                <h3 v-if="showWordInOriginalLang && traduction">
                    {{ capitalize(traduction.text) }}
                </h3>
                <h3 v-else-if="learningTraduction">
                    {{ capitalize(learningTraduction.text) }}
                </h3>
                <h3 v-else-if="traduction">
                    {{ capitalize(traduction.text) }}
                </h3>
                <div class="mt-2 flex flex-row justify-start items-center space-x-3">
                    <div
                        class="cursor-pointer w-12 h-12 rounded-full border-2 border-gray-200 p-1"
                        @click.prevent="hear"
                        :class="`${soundIsPlaying ? 'bg-gray-200' : 'bg-white'}`"
                        v-if="soundTraduction && soundTraduction.soundPath">
                        <Popper
                            :offset-y="10"
                            class="w-full h-full block"
                            :content="$t(`global.help.sound`)">
                            <Icons
                                icon="game-type-hear"
                                class="w-full h-auto block"
                                :class="`${soundIsPlaying ? 'text-white' : 'text-gray-200'}`" />
                        </Popper>
                    </div>
                    <button
                        class="appearance-none cursor-pointer w-12 h-12 rounded-full border-2 border-gray-200 p-1"
                        v-if="learningTraduction"
                        :class="{
                            'bg-white text-gray-200': !showWordInOriginalLang,
                            'bg-gray-200 text-white': showWordInOriginalLang
                        }"
                        @click.prevent="switchLang">
                        <Popper
                            :offset-y="10"
                            class="w-full h-full block"
                            :content="$t(`global.help.switchLang`)">
                            <Icons
                                icon="game-type-translate"
                                class="w-full h-auto block" />
                        </Popper>
                    </button>
                </div>
            </div>
            <div
                class="hidden"
                v-if="soundTraduction && soundTraduction.soundPath">
                <audio
                    ref="audioTag"
                    class="hidden"
                    preload="none">
                    <source
                        :src="soundTraduction.soundUrl"
                        :type="`${soundTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                </audio>
            </div>
        </div>
    </Card>
</template>
<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"
import Popper from "components/globals/Popper"

export default {
    name: "WordCard",
    components: { Popper, Icons, Card },
    props: {
        word: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            showWordInOriginalLang: false,
            soundIsPlaying: false
        }
    },
    watch: {
        //make sure we set listener when we have sound data
        soundTraduction: {
            immediate: true,
            handler: function () {
                this.$nextTick(() => {
                    if (this.$refs.audioTag) {
                        this.$refs.audioTag.addEventListener("play", this.isPlaying)
                        this.$refs.audioTag.addEventListener("ended", this.isNotPlaying)
                    }
                })
            }
        }
    },
    beforeUnmount() {
        if (this.$refs.audioTag) {
            this.$refs.audioTag.removeEventListener("play", this.isPlaying)
            this.$refs.audioTag.removeEventListener("ended", this.isNotPlaying)
        }
    },
    computed: {
        imageUrl() {
            if (!this.word) return null
            if (this.traduction && this.traduction.imagePath) {
                return this.traduction.imageUrl
            }
            return this.word.imageUrl
        },
        traduction() {
            if (!this.word) return null

            if (this.$store.getters.isUserLogin) {
                return this.$store.getters.getUserTraduction(this.word)
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentTraduction(this.word)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousTraduction(this.word)
            }

            return null
        },
        learningTraduction() {
            if (!this.word) return null

            if (this.$store.getters.isUserLogin) {
                return this.$store.getters.getUserTeachingTraduction(this.word)
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentLearningTraduction(this.word)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousLearningTraduction(this.word)
            }
            return null
        },
        soundTraduction() {
            if (this.learningTraduction) return this.learningTraduction
            if (this.traduction) return this.traduction
            return null
        }
    },
    methods: {
        async hear() {
            //load then play
            if (!this.$refs.audioTag || this.soundIsPlaying) return

            try {
                await this.$refs.audioTag.play()
            } catch (e) {}
        },
        isPlaying() {
            this.soundIsPlaying = true
        },
        isNotPlaying() {
            this.soundIsPlaying = false
        },
        switchLang() {
            this.showWordInOriginalLang = !this.showWordInOriginalLang
        }
    }
}
</script>
