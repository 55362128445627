<template>
    <UserHeader class="mb-6">
        <h1>
            {{ $t("list.customList") }}
        </h1>
        <AddButton @click.prevent="createListGroup" />
    </UserHeader>

    <div class="md:flex flex-row flex-wrap justify-start items-stretch w-full flex-1 pb-6 md:h-0">
        <div class="w-full md:w-1/3 md:pr-3 max-h-full">
            <Card class="h-full">
                <simplebar class="scroll-container h-full max-h-full">
                    <div class="px-6">
                        <div class="flex flex-row justify-between items-center">
                            <h2>
                                {{ $t("list.listGroup") }}
                            </h2>

                            <button
                                type="button"
                                class="appearance-none"
                                @click.prevent="createListGroup">
                                <Popper
                                    class="w-full h-full block"
                                    :content="$t(`form.createListGroup`)">
                                    <Icons
                                        icon="plus"
                                        class="w-7 h-7 block text-purple" />
                                </Popper>
                            </button>
                        </div>

                        <p
                            v-if="$store.state.userData.listGroups.length === 0 && !selectedListGroup"
                            class="py-4 text-purple -small">
                            {{ $t("list.noListGroup") }}
                        </p>

                        <div class="grid grid-cols-1 gap-3 mt-6">
                            <div
                                v-for="listGroup in $store.state.userData.listGroups"
                                :key="listGroup.id"
                                class="cursor-pointer rounded py-4 px-2 bg-white font-medium flex flex-row justify-between items-center"
                                @click.prevent="selectListGroup(listGroup)"
                                :class="{
                                    'bg-gray-100': selectedListGroup && selectedListGroup.uuid === listGroup.uuid
                                }">
                                <h3 class="text-left">
                                    {{ listGroup.title }}
                                </h3>
                                <div
                                    class="flex flex-row justify-end space-x-3 items-center ml-1"
                                    v-if="listGroup.id">
                                    <button
                                        type="button"
                                        @click.prevent="editListGroup(listGroup)">
                                        <Popper
                                            class="w-full h-full block"
                                            :content="$t(`form.btnEdit`)">
                                            <Icons
                                                icon="edit"
                                                class="w-6 h-6 text-purple block" />
                                        </Popper>
                                    </button>
                                    <button
                                        type="button"
                                        class="appearance-none"
                                        @click.prevent="deleteListGroup(listGroup)">
                                        <Popper
                                            class="w-full h-full block"
                                            :content="$t(`form.btnDelete`)">
                                            <Icons
                                                icon="trash"
                                                class="w-6 h-6 text-purple block" />
                                        </Popper>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </simplebar>
            </Card>
        </div>
        <div class="w-full md:w-2/3 md:pl-3 pt-6 md:pt-0 max-h-full">
            <Card class="h-full">
                <simplebar class="scroll-container h-full max-h-full">
                    <div class="px-6">
                        <div
                            class="flex flex-row justify-between items-center mb-6"
                            v-if="selectedListGroup">
                            <h3 class="font-medium">
                                {{ selectedListGroup.title }}
                            </h3>
                            <div class="flex flex-row justify-between items-center space-x-2">
                                <button
                                    type="button"
                                    class="appearance-none"
                                    @click.prevent="duplicateListInListGroup">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`form.duplicateListInListGroup`)">
                                        <Icons
                                            icon="duplicate"
                                            class="w-6 h-6 block text-purple" />
                                    </Popper>
                                </button>
                                <button
                                    type="button"
                                    class="appearance-none"
                                    @click.prevent="createWordGroup">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`form.createWordGroup`)">
                                        <Icons
                                            icon="plus"
                                            class="w-7 h-7 block text-purple" />
                                    </Popper>
                                </button>
                            </div>
                        </div>
                        <div
                            class="my-4"
                            v-if="wordGroups.length === 0 && selectedListGroup">
                            <p class="text-purple -small">
                                {{ $t("list.noWordGroups") }}
                            </p>
                        </div>
                        <div
                            class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10"
                            v-if="wordGroups.length > 0">
                            <div class="w-1/4">
                                <p class="-smaller font-medium text-purple">
                                    {{ $t("list.name") }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p class="-smaller font-medium text-purple">
                                    {{ $t("list.wordsCount") }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p class="-smaller font-medium text-purple">
                                    {{ $t("list.isWeekNb") }}
                                </p>
                            </div>
                            <div class="w-1/4" />
                        </div>
                        <div
                            class="flex flex-row justify-start items-center space-x-1 w-full py-2"
                            v-for="(wordGroup, idx) in wordGroups"
                            :class="{
                                'border-t border-purple-10': idx !== 0
                            }"
                            :key="wordGroup.id">
                            <div class="w-1/4">
                                <p class="-small text-purple">
                                    {{ capitalize(wordGroup.text) }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <h4 class="text-purple">
                                    {{ wordGroup.wordIds.length }}
                                </h4>
                            </div>

                            <div class="w-1/4">
                                <input
                                    class="cursor-pointer mx-0"
                                    type="checkbox"
                                    :checked="wordGroup.weekNb === currentWeekNb"
                                    @change="toggleWordWk($event, wordGroup)" />
                            </div>

                            <div class="w-1/4 flex flex-row justify-end space-x-3 items-center">
                                <button
                                    type="button"
                                    class="appearance-none"
                                    @click.prevent="duplicateWordGroup(wordGroup)">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`form.btnDuplicate`)">
                                        <Icons
                                            icon="duplicate"
                                            class="w-6 h-6 block text-purple" />
                                    </Popper>
                                </button>

                                <button
                                    type="button"
                                    class="appearance-none"
                                    @click.prevent="editWordGroup(wordGroup)">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`form.btnEdit`)">
                                        <Icons
                                            icon="edit"
                                            class="w-6 h-6 text-purple block" />
                                    </Popper>
                                </button>

                                <button
                                    type="button"
                                    class="appearance-none"
                                    @click.prevent="deleteWordGroup(wordGroup)">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`form.btnDelete`)">
                                        <Icons
                                            icon="trash"
                                            class="w-6 h-6 text-purple block" />
                                    </Popper>
                                </button>
                            </div>
                        </div>
                    </div>
                </simplebar>
            </Card>
        </div>
    </div>

    <DeleteItemModal
        :item-title="selectedWordGroup && selectedWordGroup.text"
        :is-loading="isDeletingWordGroup"
        v-if="showDeleteWordGroupModal"
        @delete="confirmDeleteWordGroup"
        @close="showDeleteWordGroupModal = false" />
    <EditWordGroupModal
        @close="showEditWordGroupModal = false"
        v-if="showEditWordGroupModal"
        :word-group="selectedWordGroup" />
    <CreateWordGroupModal
        :list-group-id="selectedListGroup && selectedListGroup.id"
        @close="showCreateWordGroupModal = false"
        v-if="showCreateWordGroupModal" />

    <DeleteItemModal
        :item-title="selectedListGroup && selectedListGroup.title"
        :is-loading="isDeletingListGroup"
        v-if="showDeleteListGroupModal"
        @delete="confirmDeleteListGroup"
        @close="showDeleteListGroupModal = false" />
    <CreateListGroupModal
        @created="onCreatedListGroup"
        @close="showCreateListGroupModal = false"
        v-if="showCreateListGroupModal" />
    <EditListGroupModal
        @close="showEditListGroupModal = false"
        v-if="showEditListGroupModal"
        :list-group="selectedListGroup" />

    <DuplicateListInListGroupModal
        @success="onSuccessDuplicateListInListGroup"
        @close="showDuplicateListInListGroupModal = false"
        v-if="showDuplicateListInListGroupModal"
        :list-group="selectedListGroup" />

    <DuplicateWordGroupModal
        @success="onSuccessDuplicateWordGroup"
        @close="showDuplicateWordGroupModal = false"
        v-if="showDuplicateWordGroupModal"
        :list-group="selectedListGroup"
        :word-group="selectedWordGroup" />
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import UserHeader from "components/globals/UserHeader"
import AddButton from "components/globals/AddButton"
import Icons from "components/globals/Icons"
import CreateWordGroupModal from "components/modals/user/CreateWordGroupModal"
import EditWordGroupModal from "components/modals/user/EditWordGroupModal"
import Simplebar from "components/globals/Simplebar"
import CreateListGroupModal from "components/modals/user/CreateListGroupModal"
import EditListGroupModal from "components/modals/user/EditListGroupModal"
import Popper from "components/globals/Popper"
import DeleteItemModal from "components/modals/user/DeleteItemModal"
import getWeekNb from "@globals/helpers/getWeekNb"
import DuplicateListInListGroupModal from "components/modals/user/DuplicateListInListGroupModal.vue"
import DuplicateWordGroupModal from "components/modals/user/DuplicateWordGroupModal.vue"

export default {
    name: "UserList",
    components: {
        DuplicateWordGroupModal,
        DuplicateListInListGroupModal,
        DeleteItemModal,
        Popper,
        EditListGroupModal,
        CreateListGroupModal,
        Simplebar,
        EditWordGroupModal,
        CreateWordGroupModal,
        Icons,
        AddButton,
        UserHeader,
        Card
    },
    data() {
        return {
            showCreateWordGroupModal: false,
            showEditWordGroupModal: false,
            showDeleteWordGroupModal: false,
            isDeletingWordGroup: false,

            showCreateListGroupModal: false,
            showEditListGroupModal: false,
            showDeleteListGroupModal: false,
            isDeletingListGroup: false,

            showDuplicateListInListGroupModal: false,

            showDuplicateWordGroupModal: false,

            selectedWordGroup: null,
            selectedListGroup: null
        }
    },
    async created() {
        this.currentWeekNb = getWeekNb()
        await this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS)

        if (this.$store.state.userData.listGroups.length > 0) {
            this.selectListGroup(this.$store.state.userData.listGroups[0])
        }
    },
    computed: {
        wordGroups() {
            if (!this.selectedListGroup) return []
            return this.$store.state.userData.wordGroups.filter((wG) => wG.listGroupId === this.selectedListGroup.id)
        }
    },
    methods: {
        duplicateListInListGroup() {
            this.showDuplicateListInListGroupModal = true
        },
        onSuccessDuplicateListInListGroup() {
            this.showDuplicateListInListGroupModal = false
            this.selectListGroup(this.selectedListGroup)
        },
        toggleWordWk(e, wordGroup) {
            let checked = e.target.checked

            //update
            wordGroup.weekNb = checked ? getWeekNb() : 1 //set 1 so it dosent popup in home page in student
            this.$store.dispatch(types.USER_DATA.EDIT_WORD_GROUP, wordGroup)
        },
        selectWordGroup(wordGroup) {
            this.selectedWordGroup = wordGroup
            this.$store.dispatch(types.USER_DATA.GET_WORDS_BY_WORD_GROUP_UUID, wordGroup.uuid)
        },
        editWordGroup(wordGroup) {
            this.selectWordGroup(wordGroup)
            this.showEditWordGroupModal = true
        },
        createWordGroup() {
            this.showCreateWordGroupModal = true
        },
        deleteWordGroup(wordGroup) {
            this.selectWordGroup(wordGroup)
            this.showDeleteWordGroupModal = true
        },
        duplicateWordGroup(wordGroup) {
            this.selectWordGroup(wordGroup)
            this.showDuplicateWordGroupModal = true
        },
        onSuccessDuplicateWordGroup({ listGroup, wordGroup }) {
            this.showDuplicateWordGroupModal = false
            this.selectListGroup(listGroup)
            this.selectWordGroup(wordGroup)
        },
        async confirmDeleteWordGroup() {
            this.isDeletingWordGroup = true
            await this.$store.dispatch(types.USER_DATA.DELETE_WORD_GROUP, this.selectedWordGroup)
            this.showDeleteWordGroupModal = false
            this.isDeletingWordGroup = false
            this.selectedWordGroup = null
        },

        async confirmDeleteListGroup() {
            this.isDeletingListGroup = true
            await this.$store.dispatch(types.USER_DATA.DELETE_LIST_GROUP, this.selectedListGroup)
            this.showDeleteListGroupModal = false
            this.isDeletingListGroup = false
            this.selectedListGroup = null
            this.selectedWordGroup = null //make sure this is null

            //select first list group if possible
            if (this.$store.state.userData.listGroups.length > 0) {
                this.selectListGroup(this.$store.state.userData.listGroups[0])
            }
        },
        deleteListGroup(listGroup) {
            this.selectedListGroup = listGroup
            this.showDeleteListGroupModal = true
        },
        selectListGroup(listGroup) {
            this.selectedListGroup = listGroup
            this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS_BY_LIST_GROUP_UUID, listGroup.uuid)
        },
        onCreatedListGroup() {
            if (!this.selectedListGroup && this.$store.state.userData.listGroups.length > 0) {
                this.selectListGroup(this.$store.state.userData.listGroups[0])
            }
        },
        editListGroup(listGroup) {
            this.selectListGroup(listGroup)
            this.showEditListGroupModal = true
        },
        createListGroup() {
            this.showCreateListGroupModal = true
        }
    }
}
</script>
