<template>
    <div class="w-full relative h-6">
        <div class="w-full h-px bg-yellow top-1/2 -translate-y-1/2 absolute left-0 right-0" />
        <div class="flex flex-row justify-between items-center w-full h-full absolute inset-0">
            <div
                v-for="(section, idx) in gameData.sections.length + 1"
                :key="idx"
                class="h-2 xs:h-4 min-w-2 xs:min-w-4 w-2 xs:w-4 rounded bg-yellow" />
        </div>
        <div
            ref="studentAvatars"
            v-for="studentGame in allStudentGames"
            :key="studentGame.student.id"
            class="absolute top-1/2 -translate-y-1/2 -translate-x-1/2">
            <StudentAvatar
                with-popper
                :student="studentGame.student"
                size="small" />
        </div>
    </div>
</template>

<script>
import StudentAvatar from "components/globals/StudentAvatar"
import debounce from "lodash-es/debounce"
import gsap from "gsap"

export default {
    name: "LiveBattleRace",
    components: { StudentAvatar },
    props: {
        gameData: Object
    },
    watch: {
        gameData: {
            immediate: true,
            handler: function () {
                this.setAvatar()
            }
        }
    },
    mounted() {
        window.addEventListener("resize", debounce(this.setAvatar, 100))
        this.setAvatar()
    },
    beforeUnmount() {
        window.addEventListener("resize", debounce(this.setAvatar, 100))
    },
    computed: {
        allStudentGames() {
            return [].concat(this.gameData.studentGames).concat(this.gameData.hasLeftStudentGames)
        }
    },
    methods: {
        setAvatar() {
            if (!this.$el) return
            if (!this.$refs.studentAvatars) return
            let totalWidth = this.$el.offsetWidth
            this.$refs.studentAvatars.forEach((_node, idx) => {
                let data = this.gameData.studentGames[idx]
                if (!data) return
                let progress = data.sectionsDoneCount / data.sectionsCount
                if (progress === 1) {
                    gsap.to(_node, 0.3, { opacity: 0 })
                }
                let left = totalWidth * progress
                gsap.to(_node, 0.3, { x: left })
            })
        }
    }
}
</script>
