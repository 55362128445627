<template>
    <div class="w-full h-full max-h-full">
        <Loader v-if="isLoading || localIsLoading" />
        <div
            class="flex flex-col justify-start items-start w-full h-full max-h-full"
            v-else>
            <h2>
                {{ $t("billing.planInfo") }}
            </h2>

            <simplebar class="w-full h-0 flex-1">
                <div
                    class="mt-6 space-y-2"
                    v-if="userLicense">
                    <p
                        v-if="userLicense"
                        class="text-purple font-medium">
                        {{ $t("billing.unlimitedStudents") }}
                    </p>

                    <div v-if="userLicense.buyer">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.buyer") }}
                        </p>
                        <p class="-small text-purple">
                            <span v-if="userLicense.buyer.isSchoolManager">{{ $t("auth.schoolManager") }} <br /></span>
                            {{ userLicense.buyer.name }} <br />
                            {{ userLicense.buyer.email }}
                        </p>
                    </div>
                    <div v-else>
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.buyer") }}
                        </p>
                        <p class="-small text-purple">
                            {{ $t("billing.admin") }}
                        </p>
                    </div>
                    <div v-if="currentUserSubscription">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.planName") }}
                        </p>
                        <p class="-small text-purple">
                            {{ $t(`global.plans.${currentUserSubscription.planId}`) }}
                        </p>
                    </div>
                    <div>
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.startDate") }}
                        </p>
                        <p class="-small text-purple">
                            {{ moment(userLicense.startedAt).format("LL") }}
                        </p>
                    </div>
                    <div>
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.endDate") }}
                        </p>
                        <p class="-small text-purple">
                            {{ moment(userLicense.finishedAt).format("LL") }}
                        </p>
                    </div>
                    <div v-if="currentUserSubscription">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.renewDate") }}
                        </p>
                        <p class="-small text-purple">
                            {{ moment(currentUserSubscription.nextBillingDate).format("LL") }}
                        </p>
                    </div>
                    <div v-if="currentUserSubscription">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.renewPrice") }}
                        </p>
                        <p class="-small text-purple">{{ currentUserSubscription.nextBillAmount }} $</p>
                    </div>
                    <div v-else-if="currentUserPlan">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.renewPrice") }}
                        </p>
                        <p class="-small text-purple">{{ currentUserPlan.price }} $</p>
                    </div>

                    <CustomButton
                        @click="showCancelTeacherSubscriptionModal = true"
                        type="button"
                        class="mt-6">
                        {{ $t("billing.btnCancelSubscriptionComplete") }}
                    </CustomButton>
                </div>
                <div
                    class="mt-6"
                    v-else>
                    <p
                        class="text-purple -small"
                        v-html="$t('billing.teacherSubscriptionSelfDescription')" />
                    <CustomButton
                        @click="createTeacherSubscription"
                        type="button"
                        class="mt-6"
                        v-if="paymentData">
                        {{ $t("billing.btnActivateSubscription") }}
                    </CustomButton>

                    <p
                        v-else
                        class="mt-4 error -small">
                        {{ $t("billing.noPaymentMethodTeacher") }}
                    </p>
                </div>
            </simplebar>
        </div>
        <CancelTeacherSubscriptionModal
            :teacher="$store.state.auth.user"
            @cancel="cancelTeacherSubscription"
            @close="showCancelTeacherSubscriptionModal = false"
            v-if="showCancelTeacherSubscriptionModal" />
    </div>
</template>

<script>
import Loader from "components/Loader"
import Simplebar from "components/globals/Simplebar"
import ajax from "config/ajax"
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import CancelTeacherSubscriptionModal from "components/modals/user/CancelTeacherSubscriptionModal"
import { tryAsyncFunc } from "@globals/helpers/async"

export default {
    name: "StudentSubscriptions",
    components: { CancelTeacherSubscriptionModal, CustomButton, Simplebar, Loader },
    emits: ["changed"],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localIsLoading: false,
            showCancelStudentSubscriptionModal: false,
            showCancelTeacherSubscriptionModal: false
        }
    },
    computed: {
        paymentData() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.paymentData
        },
        currentUserSubscription() {
            return (
                this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.currentUserSubscription
            )
        },
        userLicense() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.license
        },
        currentUserPlan() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.plan
        }
    },
    methods: {
        async cancelTeacherSubscription() {
            this.localIsLoading = true
            this.showCancelTeacherSubscriptionModal = false

            const [res, err] = await tryAsyncFunc(ajax.post)(`/user/teacher/subscription/cancel`)

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.localIsLoading = false

            this.$emit("changed")
        },
        async createTeacherSubscription() {
            this.localIsLoading = true
            const [res, err] = await tryAsyncFunc(ajax.post)(`/user/teacher/subscription/create`)

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.localIsLoading = false

            this.$emit("changed")
        }
    }
}
</script>
