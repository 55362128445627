<template>
    <div class="bg-gray-50 flex flex-col justify-center py-12 container">
        <div class="mt-12 md:mt-8 mx-auto px-4 w-full md:px-0 md:w-2/3 lg:w-1/2">
            <img
                class="h-16 mx-auto block w-auto"
                :src="logo"
                alt="Kwordz logo" />
            <div class="bg-white shadow-xl rounded p-6 md:p-12 mt-12 flex flex-col justify-center items-center">
                <router-view />
            </div>
            <div class="flex flex-row justify-center items-center mx-auto">
                <CustomButton
                    to="/anonymous"
                    class="mt-6">
                    {{ $t("auth.playWithoutAccount") }}
                </CustomButton>
            </div>
            <div class="flex flex-row justify-center items-center space-x-6 pb-6 mx-auto mt-12">
                <router-link
                    class="text-purple -small"
                    to="/terms"
                    >{{ $t("auth.terms") }}</router-link
                >
                <router-link
                    class="text-purple -small"
                    to="/privacy"
                    >{{ $t("auth.privacy") }}</router-link
                >
            </div>
        </div>
    </div>
    <div class="absolute flex flex-row justify-center md:justify-end items-center left-6 top-3 md:top-6 right-6">
        <LangDropdown />
    </div>
</template>
<script>
import logo from "@globals/assets/logos/logo.svg"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import { selectLang } from "config/i18n"
import { LANGS } from "@globals/constants"
import LangDropdown from "components/globals/LangDropdown"

export default {
    name: "AuthLayout",
    components: { LangDropdown, CustomButton },
    data() {
        return {
            LANGS,
            logo
        }
    },
    created() {
        this.$store.commit(types.LOGOUT)
    },
    methods: {
        changeLang(lang) {
            selectLang(lang)
        }
    }
}
</script>
