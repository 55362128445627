//OLD CODE....
const getWeekNb = function () {
    //Written by charles darras
    let dayStart = 14
    let monthStart = 10

    let now = new Date()
    let refYear = now.getFullYear()
    if (now.getMonth() + 1 < monthStart && now.getDay() < dayStart) {
        refYear -= 1
    }

    let acStart = new Date("10/14/" + refYear)
    let acEnd = new Date("10/14/" + refYear)
    acEnd.setDate(acStart.getDate() + 33 * 7)

    // Christmas vacation
    let christmasEve = new Date("12/24/" + refYear)
    let diffToSaturday = christmasEve.getDay() - 6
    let diffToBreakFirstDay = diffToSaturday !== 0 ? 5 + diffToSaturday : -2
    let christmasBreakStartDate = christmasEve.getDate() - diffToBreakFirstDay
    let christmasBreakFirstMonday = new Date("12/" + christmasBreakStartDate + "/" + refYear)
    let christmasBreakSecondMonday = new Date("12/" + christmasBreakStartDate + "/" + refYear)
    christmasBreakSecondMonday.setDate(christmasBreakFirstMonday.getDate() + 1 * 7)
    let christmasBreakThirdMonday = new Date("12/" + christmasBreakStartDate + "/" + refYear)
    christmasBreakThirdMonday.setDate(christmasBreakFirstMonday.getDate() + 2 * 7)

    // Spring break
    let acFirstOfMarch = new Date("03/01/" + (refYear + 1))
    let acFirstOfMarchDay = acFirstOfMarch.getDay()
    let diffToMonday = acFirstOfMarchDay - 1
    let diffToNextMonday = 8 - acFirstOfMarchDay
    let nextMondayDate =
        diffToMonday > 0 ? acFirstOfMarch.getDate() + diffToNextMonday : acFirstOfMarch.getDate() - diffToMonday
    let springBreakFirstMonday = new Date("03/" + nextMondayDate + "/" + (refYear + 1))

    // Calulate week_nb
    let correction = 1
    if (now >= christmasBreakFirstMonday) {
        correction -= 1
        if (now >= christmasBreakSecondMonday) {
            correction -= 1
            if (now >= christmasBreakThirdMonday) {
                correction -= 1
                if (now >= springBreakFirstMonday) {
                    correction -= 1
                }
                if (now >= acEnd) {
                    correction -= 29
                }
            }
        }
    }

    let timeDiff = Math.abs(now.getTime() - acStart.getTime())
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
    let diffWeeks
    if (diffDays % 7 === 0) {
        diffWeeks = diffDays / 7 - 1 + correction
    } else {
        diffWeeks = Math.trunc(diffDays / 7 + correction)
    }

    return diffWeeks
}

module.exports = getWeekNb
