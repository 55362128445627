<template>
    <div class="progress-bar">
        <div class="bg-white shadow-xl rounded-full p-1 md:p-1.5 w-full h-full">
            <div
                class="bg-gray-100 shadow-inset-xl overflow-hidden w-full h-full rounded-full relative flex flex-row justify-end items-center">
                <div
                    class="absolute left-0 top-0 bottom-0 bg-gradient-yellow h-full rounded-r-full transition duration-75"
                    :style="{ width: `${progressPercentage}%` }" />
                <!-- <p class="-small text-gray-400 font-normal relative z-1 mr-2">
           {{ value }}/{{ maxValue }}
         </p> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    components: {},
    props: {
        value: Number,
        minValue: {
            default: 0,
            type: Number
        },
        maxValue: Number
    },
    computed: {
        progressPercentage() {
            return (Math.max(this.value, this.minValue) / this.maxValue) * 100
        }
    }
}
</script>

<style scoped lang="scss">
.progress-bar {
    height: 32px;

    @screen md {
        height: 38px;
    }
}
</style>
