<template>
    <div class="h-full max-h-full w-full relative">
        <Card class="max-h-full h-full">
            <div class="grid grid-cols-2 grid-rows-2 gap-3 h-full max-h-full p-2">
                <button
                    v-for="answer in currentSection.answers"
                    :key="answer.id"
                    type="button"
                    class="appearance-none relative cursor-pointer rounded p-2 bg-gray-100 overflow-hidden"
                    @click.prevent="sendAnswer(answer.uuid)">
                    <img
                        v-if="getImageUrl(answer)"
                        :src="getImageUrl(answer)"
                        class="block absolute inset-0 w-auto mx-auto max-h-full object-contain object-center h-full" />
                    <span
                        class="absolute inset-0 opacity-70 pointer-events-none"
                        :class="{
                            'bg-red': answer.wasWrongAnswer,
                            'bg-green': answer.wasGoodAnswer
                        }" />
                </button>
            </div>
        </Card>
        <button
            type="button"
            class="appearance-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-1 cursor-pointer"
            @click.prevent.stop="playSound">
            <Card
                rounded
                color="yellow">
                <div class="p-6">
                    <Icons
                        icon="game-type-hear"
                        class="w-12 h-12 block text-white" />
                </div>
            </Card>
        </button>
        <audio
            ref="audioTag"
            class="hidden"
            autoplay="false"
            autostart="0">
            <source
                v-if="gameTraduction"
                :src="gameTraduction.soundUrl"
                :type="`${gameTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
        </audio>
    </div>
</template>
<script>
import Icons from "components/globals/Icons"
import Card from "components/cards/Card"

export default {
    name: "GameHear",
    components: { Card, Icons },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    computed: {
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        gameTraduction() {
            if (!this.word || !this.game) return null
            return this.word.traductions.find((t) => t.lang === this.game.lang)
        },
        traduction() {
            if (!this.currentSection) return null
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentTraduction(this.word)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousTraduction(this.word)
            }
            return null
        }
    },
    watch: {
        currentSection(nextValue) {
            //make sure we play sound only on new word value
            if (!nextValue.isReal) return

            this.$nextTick(async () => {
                this.playSound()
            })
        }
    },
    mounted() {
        //play on init
        this.playSound()
    },
    methods: {
        async playSound() {
            if (!this.$refs.audioTag) return
            this.$refs.audioTag.autoplay = false
            try {
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
            } catch (e) {}
        },
        getImageUrl(word) {
            for (let traduction in word.traductions) {
                if (traduction.imageUrl) {
                    return traduction.imageUrl
                }
            }
            return word.imageUrl
        },
        sendAnswer(answerUuid) {
            if (!this.currentSection.isReal) return
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: answerUuid
            })
        }
    }
}
</script>
