<template>
    <div class="md:grid grid-cols-2 gap-6">
        <div class="c__input">
            <label>
                {{ $t("form.firstName") }}
            </label>
            <input
                type="text"
                name="firstName"
                min="4"
                max="191"
                v-model="user.firstName" />
            <p
                class="error"
                v-if="errors.firstName">
                {{ errors.firstName }}
            </p>
        </div>
        <div class="c__input">
            <label>
                {{ $t("form.lastName") }}
            </label>
            <input
                type="text"
                name="lastName"
                min="4"
                max="191"
                v-model="user.lastName" />
            <p
                class="error"
                v-if="errors.lastName">
                {{ errors.lastName }}
            </p>
        </div>
    </div>
    <div class="md:grid grid-cols-2 gap-6">
        <div class="c__input">
            <label>
                {{ $t("form.email") }}
            </label>
            <input
                type="email"
                name="email"
                min="3"
                max="191"
                v-model="user.email" />
            <p
                class="error"
                v-if="!user.emailVerified">
                {{ $t("settings.emailNotVerified") }}
            </p>
            <p
                class="error"
                v-if="errors.email">
                {{ errors.email }}
            </p>
        </div>
        <div class="c__input">
            <label>
                {{ $t("form.lang") }}
            </label>
            <div class="c__select">
                <select
                    v-model="user.lang"
                    name="lang"
                    class="form-select">
                    <option
                        v-for="lang in Object.values(AVAILABLE_LANGS)"
                        :key="lang"
                        :value="lang">
                        {{ $t(`global.langs.${lang}`) }}
                    </option>
                </select>
            </div>
            <p
                class="error"
                v-if="errors.lang">
                {{ errors.lang }}
            </p>
        </div>
    </div>
    <div class="mt-4">
        <label
            for="acceptAds"
            class="-small">
            <input
                v-model="user.acceptAds"
                type="checkbox"
                name="acceptAds"
                id="acceptAds" />
            {{ $t("form.acceptAds") }}
        </label>
        <p
            class="error"
            v-if="errors.acceptAds">
            {{ errors.acceptAds }}
        </p>
    </div>
</template>

<script>
import { AVAILABLE_LANGS } from "@globals/constants"

export default {
    name: "UserForm",
    props: {
        user: Object,
        errors: Object
    },
    data() {
        return {
            AVAILABLE_LANGS
        }
    },
    methods: {
        validate() {
            let errors = {}

            if (this.user.firstName === "") {
                errors.firstName = this.$t("validationErrors.firstName")
            }
            if (this.user.lastName === "") {
                errors.lastName = this.$t("validationErrors.lastName")
            }

            if (this.user.email === "") {
                errors.email = this.$t("validationErrors.email")
            }

            return errors
        }
    }
}
</script>
