<template>
    <div class="absolute z-1 left-0 top-0 py-2 px-4 bg-gradient-yellow rounded shadow-2xl | c-student_question_bonus">
        <h3 class="text-white text-center py-3">
            Question bonus !
            <span>2X points</span>
        </h3>
    </div>
</template>

<script>
export default {
    name: "StudentQuestionBonus",
    components: {}
}
</script>

<style lang="scss">
.c-student_question_bonus {
    transform: rotateZ(-10deg) translateX(-10%);
}
</style>
