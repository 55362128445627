<template>
    <div class="w-full h-full flex flex-col justify-start items-start">
        <h2 class="pb-3">
            {{ $t("scoreboard.title") }}
        </h2>
        <div class="w-full h-0 flex-1">
            <simplebar class="">
                <div
                    class="flex flex-row flex-wrap md:flex-nowrap justify-between items-center w-full p-3 border-b border-purple-10"
                    v-for="(studentGame, idx) in scoreboard"
                    :key="studentGame.student.id"
                    :class="{
                        'border-t': idx === 0,
                        'bg-gray-100': isStudent(studentGame.student)
                    }">
                    <div class="w-full md:w-1/2 flex flex-row justify-start items-center">
                        <span class="block font-bold text-purple min-w-6 text-12 md:text-14">
                            {{ idx + 1 }}
                        </span>
                        <span class="block text-12 md:text-14 text-purple">
                            {{ studentGame.student.name }} ({{ studentGame.student.username }})
                            <span v-if="isStudent(studentGame.student)"> ({{ capitalize($t("global.you")) }}) </span>
                        </span>
                    </div>
                    <div
                        class="w-full md:w-1/2 flex flex-row justify-start md:justify-end items-center space-x-4 ml-6 md:ml-0 pt-1 md:pt-0">
                        <p class="text-purple">
                            <span class="font-bold">{{ studentGame.totalScore }}</span> {{ $t("global.points") }}
                        </p>
                        <div
                            class="min-w-20 flex flex-row justify-center items-center"
                            v-if="studentGame.isFinish && showTotalGameTime">
                            <GameTimer
                                tag="p"
                                class="block text-purple font-bold"
                                :timer="msToTime(studentGame.totalGameTimeMs)" />
                        </div>
                        <div
                            class="flex-row flex-nowrap justify-center items-center hidden xl:flex"
                            v-if="studentGame.goodResult !== null">
                            <Icons
                                icon="thumb-up"
                                class="w-5 h-5 mr-1 block text-purple" />
                            <p class="font-bold text-purple">
                                {{ studentGame.goodResult }}
                            </p>
                        </div>
                        <div
                            class="flex-row flex-nowrap justify-center items-center hidden xl:flex"
                            v-if="studentGame.badResult !== null">
                            <Icons
                                icon="smiley"
                                class="w-5 h-5 mr-1 block text-purple" />
                            <p class="font-bold text-purple">
                                {{ studentGame.badResult }}
                            </p>
                        </div>
                    </div>
                </div>
            </simplebar>
        </div>
    </div>
</template>

<script>
import orderBy from "lodash-es/orderBy"
import { GAME_TYPES } from "@globals/constants"
import GameTimer from "components/game/GameTimer"
import { msToTime } from "@globals/functions"
import Simplebar from "components/globals/Simplebar"
import Icons from "components/globals/Icons"

export default {
    name: "CurrentGameScoreboard",
    components: { Icons, Simplebar, GameTimer },
    props: {
        gameData: Object
    },
    created() {},
    computed: {
        allStudentGames() {
            return [].concat(this.gameData.studentGames).concat(this.gameData.hasLeftStudentGames)
        },
        showTotalGameTime() {
            if (!this.gameData) return false
            //dont show total game time on contest since its the same for everybody
            if (this.gameData.game.gameTypeSlug === GAME_TYPES.CONTEST) return false
            return true
        },
        //todo dont update student when isStudentLogin and game context so he dosent know the right answer
        scoreboard() {
            if (this.gameData.isFinish) {
                return this.allStudentGames
            }

            if (this.gameData.game.gameTypeSlug === GAME_TYPES.CONTEST) {
                return orderBy(this.allStudentGames, ["totalScore"], ["desc"])
            }
            //during game, just show who has completed the most
            return orderBy(this.allStudentGames, ["sectionsDoneCount"], ["desc"])
        }
    },
    methods: {
        msToTime(s) {
            return msToTime(s)
        },
        isStudent(student) {
            if (!student) return false
            if (this.$store.getters.isStudentLogin) {
                return student.id === this.$store.state.auth.student.id
            }
            if (this.$store.getters.isAnonymousLogin) {
                return student.id === this.$store.state.auth.anonymous.id
            }
            return false
        }
    }
}
</script>
