<template>
    <Card>
        <div class="flex flex-row justify-between items-center p-3">
            <div class="w-1/3">
                <GameTimer
                    class="block font-medium"
                    :timer="timer" />
            </div>
            <div class="flex flex-col justify-center items-center w-1/3">
                <h1 class="text-yellow-200 text-center">
                    {{ studentGameData.totalScore }}
                </h1>
                <p class="text-yellow-200 font-bold text-center text-13">{{ $t("global.points") }}</p>
                <p class="text-gray-200 text-center">
                    <span v-if="studentGameData.sectionsDoneCount >= studentGameData.sectionsCount">
                        {{ studentGameData.sectionsDoneCount }} {{ $t("global.of") }}
                        {{ studentGameData.sectionsCount }}
                    </span>
                    <span v-else>
                        {{ studentGameData.sectionsDoneCount + 1 }} {{ $t("global.of") }}
                        {{ studentGameData.sectionsCount }}
                    </span>
                </p>
            </div>
            <div class="flex flex-row justify-end items-center space-x-3 md:space-x-6 w-1/3">
                <div class="flex flex-row justify-center items-center">
                    <Icons
                        icon="thumb-up"
                        class="w-6 h-6 md:w-10 md:h-10 mr-1 block text-purple" />
                    <h2>
                        {{ studentGameData.goodResult }}
                    </h2>
                </div>
                <div class="flex flex-row justify-center items-center">
                    <Icons
                        icon="smiley"
                        class="w-6 h-6 md:w-10 md:h-10 mr-1 block text-purple" />
                    <h2>
                        {{ studentGameData.badResult }}
                    </h2>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "components/cards/Card"
import GameTimer from "components/game/GameTimer"
import Icons from "components/globals/Icons"

export default {
    name: "GameFooter",
    components: { Icons, GameTimer, Card },
    props: {
        timer: {
            required: true,
            type: Object
        },
        studentGameData: {
            required: true,
            type: Object
        }
    }
}
</script>
