<template>
    <modal
        size="big"
        :show="true"
        @close="$emit('close')"
        :scrollable="false">
        <div class="flex flex-col justify-start items-start max-h-full h-full">
            <h2 v-if="listGroup">
                {{ $t("modal.duplicateListInListGroup.title", { title: listGroup.title }) }}
            </h2>

            <p class="pt-4 text-purple -small">
                {{ $t("modal.duplicateListInListGroup.description") }}
            </p>

            <form
                @submit.prevent="onSubmit"
                class="mt-4 w-full h-0 flex-1 flex flex-col justify-center items-center">
                <div
                    class="w-full px-2 flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10">
                    <div class="w-1/3">
                        <input
                            class="cursor-pointer mx-0"
                            type="checkbox"
                            :checked="wordGroupIds.length === listWordGroups.length"
                            @change="toggleAll" />
                    </div>
                    <div class="w-1/3">
                        <p class="-smaller font-medium text-purple">
                            {{ $t("list.name") }}
                        </p>
                    </div>
                    <div class="w-1/3">
                        <p class="-smaller font-medium text-purple">
                            {{ $t("list.wordsCount") }}
                        </p>
                    </div>
                </div>

                <div class="h-0 flex-1 w-full">
                    <simplebar class="h-full">
                        <div
                            class="flex flex-row justify-start items-center space-x-1 w-full p-2"
                            v-for="(wordGroup, idx) in listWordGroups"
                            :class="{
                                'border-t border-purple-10': idx !== 0
                            }"
                            :key="wordGroup.id">
                            <div class="w-1/3">
                                <input
                                    class="cursor-pointer mx-0"
                                    type="checkbox"
                                    :checked="wordGroupIds.includes(wordGroup.id)"
                                    @change="toggleWordGroup(wordGroup.id)" />
                            </div>
                            <div class="w-1/3">
                                <p
                                    class="-small text-purple"
                                    v-if="teachingTraduction(wordGroup)">
                                    {{ capitalize(teachingTraduction(wordGroup).text) }}
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else-if="traduction(wordGroup)">
                                    {{ capitalize(traduction(wordGroup).text) }}
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else>
                                    {{ capitalize(wordGroup.text) }}
                                </p>
                            </div>
                            <div class="w-1/3">
                                <h4 class="text-purple">
                                    {{ wordGroup.wordIds.length }}
                                </h4>
                            </div>
                        </div>
                    </simplebar>
                </div>

                <div class="w-full flex flex-row justify-center items-center my-4 w-full">
                    <CustomButton
                        type="submit"
                        :disabled="wordGroupIds.length === 0">
                        {{ $t("form.btnDuplicate") }}
                    </CustomButton>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal.vue"
import * as types from "store/types"
import { WORD_GROUP_TYPES } from "@globals/constants"
import CustomButton from "components/globals/CustomButton.vue"
import Simplebar from "components/globals/Simplebar.vue"
import EventBus, { EVENTS } from "@/eventbus"

export default {
    name: "DuplicateListInListGroupModal",
    components: { Simplebar, CustomButton, Modal },
    emits: ["close", "success"],
    props: {
        listGroup: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            wordGroupIds: []
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)
    },

    computed: {
        listWordGroups() {
            //needs to have words and be list
            return this.$store.state.userData.wordGroups.filter(
                (wG) => wG.type === WORD_GROUP_TYPES.LIST && wG.wordIds.length > 0
            )
        }
    },

    methods: {
        teachingTraduction(wordGroup) {
            if (!wordGroup) return null
            return this.$store.getters.getUserTeachingTraduction(wordGroup)
        },
        traduction(wordGroup) {
            if (!wordGroup) return null
            return this.$store.getters.getUserTraduction(wordGroup)
        },
        toggleWordGroup(id) {
            let idx = this.wordGroupIds.indexOf(id)

            if (idx === -1) {
                this.wordGroupIds.push(id)
            } else {
                this.wordGroupIds.splice(idx, 1)
            }
        },
        toggleAll($event) {
            if ($event.target.checked) {
                //add all
                this.listWordGroups.forEach((wG) => {
                    this.wordGroupIds.push(wG.id)
                })
            } else {
                //remove all
                this.wordGroupIds = []
            }
        },
        onSubmit() {
            this.$store
                .dispatch(types.USER_DATA.DUPLICATE_LIST_IN_LIST_GROUP, {
                    listGroupUuid: this.listGroup.uuid,
                    wordGroupIds: this.wordGroupIds
                })
                .then((res) => {
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: this.$t("flash.duplicateListInListGroup", { title: this.listGroup.title }),
                        type: "success"
                    })

                    //make sure we update selected list group
                    this.$emit("success", res.listGroup)
                    this.$emit("close")
                })
        }
    }
}
</script>
