<template>
    <div
        class="p-4 max-h-full w-full h-full overflow-hidden -my-2 grid grid-cols-1 md:grid-cols-2 gap-6"
        v-if="currentSection">
        <div class="h-full w-full flex flex-col justify-center items-stretch space-y-3">
            <div
                ref="dropzone"
                class="w-full h-full relative">
                <div
                    v-show="showDropzoneHover"
                    class="absolute inset-0 rounded flex flex-col justify-center items-center"
                    style="background: rgba(0, 0, 0, 0.35)">
                    <h3 class="text-white">
                        {{ $t("global.dndHere") }}
                    </h3>
                </div>
                <div
                    v-if="currentWord"
                    class="w-full h-full p-2 rounded border-2 cursor-pointer bg-contain bg-no-repeat bg-center border-6"
                    :class="{
                        'border-green': goodAnswer && goodAnswer.uuid === currentTextWordUuidSelected,
                        'border-white': !hasWrongAnswer,
                        'border-red': hasWrongAnswer
                    }"
                    :style="`background-image: url(${currentWord.imageUrl})`"></div>
            </div>
        </div>
        <div
            class="max-h-full h-full relative"
            ref="container">
            <div
                v-for="answer in currentSection.answers"
                :key="answer.id"
                ref="answers"
                :data-uuid="answer.uuid"
                class="absolute shadow bg-white w-auto h-auto p-2 cursor-move h-auto border-6 rounded border-white"
                :class="{
                    'border-green':
                        goodAnswer && goodAnswer.id === answer.id && goodAnswer.uuid === currentTextWordUuidSelected,
                    'border-white': currentTextWordUuidSelected !== answer.uuid,
                    'border-red': currentTextWordUuidSelected === answer.uuid && hasWrongAnswer
                }">
                <span
                    class="block font-sans text-24 font-medium text-purple text-center pointer-events-none select-none"
                    v-if="getGameTraduction(answer)">
                    {{ capitalize(getGameTraduction(answer).text) }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import debounce from "lodash-es/debounce"
import { createArray } from "@globals/functions"
import random from "lodash-es/random"
import Draggable from "gsap/Draggable"
import gsap from "gsap"

gsap.registerPlugin(Draggable)

export default {
    name: "GameMatchDnD",
    components: {},
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            currentTextWordUuidSelected: null,
            showDropzoneHover: false
        }
    },
    watch: {
        currentSection(nextValue, prevValue) {
            //if was null and not it exist
            if (!prevValue && nextValue) {
                this.onResize()
                this.initDraggable()
            }
            //if entire section changed
            if (prevValue && nextValue) {
                if (prevValue.sectionIdx !== nextValue.sectionIdx) {
                    this.$nextTick(() => {
                        this.onResize()
                        this.initDraggable()
                    })
                }
            }

            this.showDropzoneHover = false

            //make sure we reset data when we change word
            if (nextValue.words.length === prevValue.words.length && !nextValue.isReal) return

            this.$nextTick(() => {
                this.currentTextWordUuidSelected = null
                this.resetDraggablePosition()
            })
        }
    },
    mounted() {
        window.addEventListener("resize", debounce(this.onResize, 100))
        this.onResize()
        this.initDraggable()
    },
    beforeUnmount() {
        window.addEventListener("resize", debounce(this.onResize, 100))
    },
    computed: {
        currentWord() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        hasWrongAnswer() {
            if (!this.currentSection) return false
            return this.currentSection.answers.some((a) => a.wasWrongAnswer)
        },
        goodAnswer() {
            if (!this.currentSection) return null
            return this.currentSection.answers.find((a) => a.wasGoodAnswer) || null
        }
    },
    methods: {
        initDraggable() {
            let self = this

            Draggable.create(this.$refs.answers, {
                type: "left,top",
                edgeResistance: 1,
                bounds: this.$el,
                onDragStart: () => {
                    self.showDropzoneHover = true
                },
                onDragEnd: function () {
                    self.showDropzoneHover = false

                    let result = false
                    try {
                        result = this.hitTest(self.$refs.dropzone, "1%")
                    } catch (e) {}
                    if (result) {
                        //if has pass 30%, send drop end event
                        self.onDropEnd(this.target.dataset.uuid)
                        return
                    }

                    self.resetDraggablePosition()
                }
            })
        },
        resetDraggablePosition() {
            //reset original position
            this.$refs.answers.forEach((_answer) => {
                _answer.style.top = _answer.dataset.top
                _answer.style.left = _answer.dataset.left
            })
        },
        onResize() {
            if (!this.$refs.container) return //just skip

            //make sure we got enough spot for all words and answers
            let ROWS = 4
            let COLS = 3

            if (this.$refs.answers.length > ROWS * COLS) {
                console.error("NOT ENOUGH SPACE ! Need: ", this.$refs.answers.length)
                return
            }

            let rows = createArray(ROWS).map((_, i) => this.$refs.container.offsetWidth * (i / ROWS))
            let cols = createArray(COLS).map((_, i) => this.$refs.container.offsetHeight * (i / COLS))
            let grid = rows.map((row, rowIdx) => {
                return cols.map((col, colIdx) => ({
                    x: row,
                    y: col,
                    xIdx: rowIdx,
                    yIdx: colIdx,
                    width: rows[1],
                    height: cols[1]
                }))
            })
            /*
            // To debug grid uncomment
            grid.forEach(_row => {
              _row.forEach((col) => {
                let el = document.createElement('div')
                el.style.pointerEvents = 'none'
                el.style.position = 'absolute'
                el.style.width = `${rows[1]}px`
                el.style.height = `${cols[1]}px`
                el.style.border = '1px solid red'
                el.style.top = `${col.y}px`;
                el.style.left = `${col.x}px`;
                this.$refs.container.appendChild(el)
              })
            }) */

            this.$refs.answers.forEach((_answer) => {
                //grab spot
                let xPos = random(0, grid.length - 1)
                let yPos = random(0, grid[xPos].length - 1)

                let randomSpotInGrid = grid[xPos][yPos]

                //remove col position so other element dont grab it
                grid[xPos].splice(yPos, 1)

                //remove row if empty
                if (grid[xPos].length === 0) {
                    grid.splice(xPos, 1)
                }

                //center in grid spot
                let centerWidthOffset = randomSpotInGrid.width / 2 - _answer.offsetWidth / 2
                let centerHeightOffset = randomSpotInGrid.height / 2 - _answer.offsetHeight / 2

                //with some randomess into it , more on y than x, make sure its responsive
                let randomX = this.$refs.container.offsetWidth > 960 ? random(-4, 4) : random(-2, 2)
                let randomY = this.$refs.container.offsetWidth > 960 ? random(-15, 15) : random(-2, 2)

                //place element
                let finalTop = `${parseInt(randomSpotInGrid.y + centerHeightOffset + randomX)}px`
                let finalLeft = `${parseInt(randomSpotInGrid.x + centerWidthOffset + randomY)}px`
                _answer.style.top = finalTop
                _answer.style.left = finalLeft
                _answer.setAttribute("data-top", finalTop)
                _answer.setAttribute("data-left", finalLeft)
            })
        },
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        },
        onDropEnd(wordUuid) {
            if (!wordUuid) return
            //select
            this.currentTextWordUuidSelected = wordUuid

            this.$emit("answer", {
                wordUuid: this.currentWord.uuid,
                answeredWordUuid: this.currentTextWordUuidSelected
            })
        }
    }
}
</script>
