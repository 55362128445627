<template>
    <Card v-if="wordGroup && gameData">
        <div class="flex flex-row justify-between items-stretch p-3">
            <div class="flex flex-row justify-start items-center space-x-3">
                <GoBackButton @click.prevent="$emit('goBack')" />
                <div class="hidden xs:block">
                    <Image
                        class="h-20 w-28 min-w-28 md:w-40 md:min-w-40"
                        :image-url="wordGroup.imageUrl"
                        :alt="wordGroup.text" />
                </div>
                <div>
                    <h5
                        class="text-purple opacity-60 uppercase"
                        v-if="gameType">
                        {{ $t(`global.gameTypes.${gameType.slug}`) }}
                    </h5>
                    <h2
                        class="pt-2"
                        v-if="traduction">
                        {{ capitalize(traduction.text) }}
                    </h2>
                    <h2
                        class="pt-2"
                        v-else-if="wordGroup">
                        {{ capitalize(wordGroup.text) }}
                    </h2>
                </div>
            </div>

            <div v-if="gameData && gameData.isFinish">
                <div class="flex flex-row justify-center items-center space-x-3 h-full">
                    <CustomButton @click.prevent="$emit('end')">
                        {{ $t("game.btnFinish") }}
                    </CustomButton>
                    <CustomButton @click.prevent="$emit('restart')">
                        {{ $t("game.btnReplay") }}
                    </CustomButton>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "components/cards/Card"
import Image from "components/globals/Image"
import GoBackButton from "components/globals/GoBackButton"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "ListGameHeader",
    components: { CustomButton, GoBackButton, Image, Card },
    emits: ["goBack", "restart", "end"],
    props: {
        wordGroup: Object,
        gameData: Object,
        gameType: Object
    },
    computed: {
        traduction() {
            if (!this.wordGroup) return null
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentTraduction(this.wordGroup)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousTraduction(this.wordGroup)
            }
            return null
        }
    }
}
</script>
