<template>
    <div
        v-if="isLoading"
        class="py-6">
        <Loader />
    </div>

    <div class="mt-6 w-full">
        <LiveGameHeader
            @terminateGame="terminateGame"
            @goBack="onGoBack"
            @restart="duplicateLiveGame"
            :game-data="gameData"
            :game="game"
            :word-group="wordGroup" />
    </div>
    <LiveGameWaiting
        v-if="!gameData.isStarted"
        @exit="onGoBack"
        @start="wantToStartGame"
        :game-data="gameData"
        :word-group="wordGroup"
        :game="game" />
    <UserLiveGameDuring
        v-else-if="!gameData.isFinish"
        :game-data="gameData"
        :game="game"
        :timer="timer" />
    <UserLiveGameResult
        v-else
        :game-data="gameData"
        :game="game"
        :word-group="wordGroup" />
</template>

<script>
import * as types from "store/types"
import Loader from "components/Loader"
import ajax from "config/ajax"
import LiveGameWaiting from "components/game/student/live/LiveGameWaiting"
import { SOCKET_EVENTS } from "@globals/constants"
import { userGameMixin } from "mixins/game"
import UserLiveGameResult from "components/game/user/live/UserLiveGameResult"
import UserLiveGameDuring from "components/game/user/live/UserLiveGameDuring"
import LiveGameHeader from "components/game/student/live/LiveGameHeader"

export default {
    name: "UserLiveGame",
    components: {
        LiveGameHeader,
        UserLiveGameDuring,
        UserLiveGameResult,
        LiveGameWaiting,
        Loader
    },
    mixins: [userGameMixin],
    async created() {
        //make sure we got all word groups for selector
        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)

        await this.init() //from mixin
        await this.initGame() //init game
    },
    methods: {
        async initGame() {
            let res
            try {
                res = await ajax.get(`/user/live-games/${this.$route.params.uuid}`)
            } catch (e) {
                this.$router.replace("/user")
                return
            }

            const { gameData, game } = res

            let wordGroup = await this.fetchWordGroup(game.wordGroupId)
            if (!wordGroup) {
                return
            }

            this.gameData = gameData
            this.game = game

            //start game if started and not finished yet
            if (this.gameData.isStarted && !this.gameData.isFinish) {
                this.startGame() //init local game
            }

            this.initSocketEvents()

            this.isLoading = false
        },
        initSocketEvents() {
            this.$store.state.auth.userSocket.on(SOCKET_EVENTS.UPDATE_LIVE_GAME, (gameData) => {
                this.gameData = gameData
            })
            this.$store.state.auth.userSocket.on(SOCKET_EVENTS.USER_UPDATED_GAME, (game) => {
                if (this.game.wordGroupId !== game.wordGroupId) {
                    this.fetchWordGroup(game.wordGroupId)
                }
                this.game = game
            })
            this.$store.state.auth.userSocket.on(SOCKET_EVENTS.START_LIVE_GAME, (gameData) => {
                this.gameData = gameData
                this.startGame()
            })
        },
        async startGame() {
            if (!this.gameData) {
                return
            }
            let currentSection = this.gameData.sections[0]

            //init
            this.currentSection = {
                ...currentSection,
                isReal: true,
                wasGoodAnswer: false,
                wasWrongAnswer: false
            }

            //start timer only if we are not finish
            this.startTimer()
        },
        async wantToStartGame() {
            ajax.post(`/user/live-games/${this.game.uuid}/start`)
            //don't handle response, websocket will do that for us
        }
    }
}
</script>
