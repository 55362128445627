<template>
    <div class="w-full py-2">
        <div class="flex flex-row justify-start items-center space-x-1 w-full py-2">
            <div class="min-w-12">
                <p class="-small text-purple">{{ idx + 1 }}.</p>
            </div>
            <div class="w-5/12">
                <div class="inline-block">
                    <CustomButton
                        version="text"
                        align="left"
                        wrap
                        :to="`/user/play/${item.wordGroup.uuid}`"
                        v-if="item.wordGroup">
                        {{ capitalize(item.wordGroup.text) }}
                    </CustomButton>
                </div>
            </div>
            <div class="w-3/12">
                <p class="-small text-purple">
                    {{ item.stats.gameCount }}
                </p>
            </div>
            <div class="w-3/12">
                <p class="-small text-purple">{{ item.stats.successRate }} %</p>
            </div>
            <div class="w-1/12">
                <CustomButton
                    version="text"
                    align="left"
                    @click.prevent="toggleWordStats"
                    v-if="item.stats.wordStats.length > 0">
                    <template v-if="!seeWordStats">
                        {{ $t("stats.seeWordStats") }}
                    </template>
                    <template v-else>
                        {{ $t("stats.hideWordStats") }}
                    </template>
                </CustomButton>
            </div>
        </div>
        <div
            v-if="seeWordStats"
            class="w-full py-2 px-12">
            <div class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10">
                <div class="w-1/3">
                    <p class="-smaller font-medium text-purple-80">
                        {{ $t("stats.word") }}
                    </p>
                </div>
                <div class="w-1/3">
                    <p class="-smaller font-medium text-purple-80">
                        {{ $t("stats.successRate") }}
                    </p>
                </div>
                <div class="w-1/3">
                    <p class="-smaller font-medium text-purple-80">
                        {{ $t("stats.tries") }}
                    </p>
                </div>
            </div>
            <div
                v-for="wordStat in wordStats"
                :key="wordStat.wordId"
                class="flex flex-row justify-start items-center space-x-1 w-full py-2">
                <div class="w-1/3">
                    <p
                        class="-small text-purple-80"
                        v-if="wordStat.word">
                        {{ capitalize(wordStat.word.text) }}
                    </p>
                </div>
                <div class="w-1/3">
                    <p class="-small text-purple-80">{{ wordStat.successRate }}%</p>
                </div>
                <div class="w-1/3">
                    <p class="-small text-purple-80">
                        {{ wordStat.tries }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"

export default {
    name: "ClassroomWordGroupTableItem",
    components: { CustomButton },
    props: {
        item: Object,
        idx: Number
    },
    data() {
        return {
            seeWordStats: false
        }
    },
    computed: {
        wordStats() {
            return this.item.stats.wordStats.map((wS) => {
                return {
                    ...wS,
                    word: this.$store.state.userData.words.find((w) => w.id === wS.wordId) || null
                }
            })
        }
    },
    methods: {
        toggleWordStats() {
            if (this.seeWordStats) {
                this.seeWordStats = false
                return
            }

            this.openWordStats()
        },
        openWordStats() {
            this.seeWordStats = true
            this.$store.dispatch(types.USER_DATA.GET_WORDS_BY_WORD_GROUP_UUID, this.item.wordGroup.uuid)
        }
    }
}
</script>
