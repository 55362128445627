<template>
    <modal
        size="big"
        :show="true"
        @close="$emit('close')"
        :scrollable="false">
        <div class="flex flex-col justify-start items-start max-h-full h-full">
            <template v-if="wordGroup">
                <h2 v-if="traduction">
                    {{ $t("modal.duplicateWordGroup.title", { title: traduction.text }) }}
                </h2>
                <h2 v-else>
                    {{ $t("modal.duplicateWordGroup.title", { title: wordGroup.text }) }}
                </h2>
            </template>

            <p class="pt-4 text-purple -small">
                {{ $t("modal.duplicateWordGroup.description") }}
            </p>

            <form
                @submit.prevent="onSubmit"
                class="mt-4 w-full h-0 flex-1 flex flex-col justify-center items-center">
                <div
                    class="w-full px-2 flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10">
                    <div class="w-1/3"></div>
                    <div class="w-2/3">
                        <p class="-smaller font-medium text-purple">
                            {{ $t("list.name") }}
                        </p>
                    </div>
                </div>

                <div class="h-0 flex-1 w-full">
                    <simplebar class="h-full">
                        <div
                            class="flex flex-row justify-start items-center space-x-1 w-full p-2"
                            v-for="(listGroup, idx) in $store.state.userData.listGroups"
                            :class="{
                                'border-t border-purple-10': idx !== 0
                            }"
                            :key="listGroup.id">
                            <div class="w-1/3">
                                <input
                                    class="cursor-pointer mx-0"
                                    type="checkbox"
                                    :checked="listGroupId === listGroup.id"
                                    @change="listGroupId = listGroup.id" />
                            </div>
                            <div class="w-2/3">
                                <p class="-small text-purple">
                                    {{ capitalize(listGroup.title) }}
                                </p>
                            </div>
                        </div>
                    </simplebar>
                </div>

                <div class="w-full flex flex-row justify-center items-center my-4 w-full">
                    <CustomButton
                        type="submit"
                        :disabled="!listGroupId">
                        {{ $t("form.btnDuplicate") }}
                    </CustomButton>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal.vue"
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton.vue"
import Simplebar from "components/globals/Simplebar.vue"
import EventBus, { EVENTS } from "@/eventbus"

export default {
    name: "DuplicateWordGroupModal",
    components: { Simplebar, CustomButton, Modal },
    emits: ["close", "success"],
    props: {
        wordGroup: {
            type: Object,
            required: true
        },
        listGroup: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            listGroupId: this.listGroup.id
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS)
    },

    computed: {
        traduction() {
            if (!this.wordGroup) return null
            return this.$store.getters.getUserTraduction(this.wordGroup)
        }
    },

    methods: {
        onSubmit() {
            let listGroup = this.$store.state.userData.listGroups.find((lG) => lG.id === this.listGroupId)
            if (!listGroup) return

            this.$store
                .dispatch(types.USER_DATA.DUPLICATE_WORD_GROUP_BY_UUID, {
                    listGroupId: this.listGroupId,
                    wordGroupUuid: this.wordGroup.uuid
                })
                .then((res) => {
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: this.$t("flash.duplicateWordGroup", { title: listGroup.title }),
                        type: "success"
                    })

                    //make sure we update selected list group
                    this.$emit("success", {
                        wordGroup: res.wordGroup,
                        listGroup: listGroup
                    })
                    this.$emit("close")
                })
        }
    }
}
</script>
