<template>
    <div class="bg-gray-50 flex flex-col justify-center py-12 container">
        <div class="mt-12 md:mt-8 mx-auto px-4 w-full md:px-0 md:w-1/2">
            <img
                class="h-16 mx-auto block w-auto"
                :src="logo"
                alt="Kwordz logo" />
            <div class="bg-white shadow-xl rounded p-6 md:p-12 mt-12 space-y-6">
                <slot />
            </div>
            <div class="flex flex-row justify-center items-center mx-auto">
                <CustomButton
                    to="/auth/login"
                    class="mt-6">
                    {{ $t("auth.btnLogin") }}
                </CustomButton>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "@globals/assets/logos/logo.svg"
import CustomButton from "components/globals/CustomButton"
export default {
    name: "InfoLayout",
    components: { CustomButton },
    data() {
        return {
            logo
        }
    }
}
</script>
