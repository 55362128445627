<template>
    <div
        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10"
        v-if="games.length > 0">
        <div class="w-2/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.startedAt") }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.gameTime") }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.wordGroup") }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.gameType") }}
            </p>
        </div>
        <div class="w-1/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.score") }}
            </p>
        </div>
        <div class="w-1/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("stats.successRate") }}
            </p>
        </div>
        <div class="w-1/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.isPerfectGame") }}
            </p>
        </div>
        <div class="w-1/12">
            <p class="-smaller font-medium text-purple">
                {{ $t("game.isClassroomGame") }}
            </p>
        </div>
    </div>
    <StudentGameTableItem
        v-for="(game, idx) in completeGamesForPage"
        :key="game.id"
        :game="game"
        :class="{
            'border-t border-purple-10': idx !== 0
        }" />
    <div
        class="mt-6 flex flex-row justify-center items-center"
        v-if="completeGamesForPage.length > 0">
        <CustomButton
            version="text"
            v-if="completeGamesForPage.length !== completeGames.length"
            @click.prevent="page++">
            {{ $t("scoreboard.seeMore") }}
        </CustomButton>
    </div>

    <p
        class="text-purple -small mt-2"
        v-if="games.length === 0">
        {{ $t("game.noGames") }}
    </p>
</template>

<script>
import * as types from "store/types"
import StudentGameTableItem from "components/layouts/items/StudentGameTableItem.vue"
import CustomButton from "components/globals/CustomButton.vue"

export default {
    name: "StudentGamesTable",
    components: { CustomButton, StudentGameTableItem },
    props: {
        games: Array,
        student: Object
    },
    data() {
        return {
            page: 1,
            perPage: 10
        }
    },
    created() {
        if (this.$store.getters.isStudentLogin) {
            this.$store.dispatch(types.STUDENT_DATA.GET_WORD_GROUPS)
        } else if (this.$store.getters.isUserLogin) {
            this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)
        }
    },
    computed: {
        completeGames() {
            return this.games.map((g) => {
                let wordGroups = []
                if (this.$store.getters.isStudentLogin) {
                    wordGroups = this.$store.state.studentData.wordGroups
                } else if (this.$store.getters.isUserLogin) {
                    wordGroups = this.$store.state.userData.wordGroups
                }
                let wordGroup = wordGroups.find((wG) => wG.id === g.wordGroupId) || null

                if (wordGroup) {
                    let learningTraduction = null
                    if (this.$store.getters.isStudentLogin) {
                        learningTraduction = this.$store.getters.getStudentLearningTraduction(wordGroup)
                    } else if (this.$store.getters.isUserLogin) {
                        learningTraduction = this.$store.getters.getUserTeachingTraduction(wordGroup)
                    }
                    if (learningTraduction) {
                        wordGroup.text = learningTraduction.text
                    }
                }

                return {
                    ...g,
                    wordGroup: wordGroup
                }
            })
        },
        completeGamesForPage() {
            return this.completeGames.slice(0, this.page * this.perPage)
        }
    }
}
</script>
