<template>
    <button
        type="button"
        class="flex flex-row justify-center items-center w-8 h-8 bg-white shadow-xl rounded-full cursor-pointer focus:outline-none">
        <Icons
            :icon="icon"
            :class="iconClass"
            class="block w-4 h-4 pointer-events-none"></Icons>
    </button>
</template>

<script>
import Icons from "components/globals/Icons"

export default {
    name: "SmallCircleButton",
    components: { Icons },
    props: {
        icon: String,
        iconClass: {
            type: String,
            default: "w-4 h-4"
        }
    }
}
</script>
