<template>
    <modal
        :show="true"
        @close="$emit('close')"
        size="small">
        <h2
            class="mb-4"
            v-if="$store.state.auth.user.isTeacher">
            {{ $t("form.createStudent") }}
        </h2>
        <h2
            class="mb-4"
            v-if="$store.state.auth.user.isParent">
            {{ $t("form.createChildren") }}
        </h2>
        <TabbedButtons
            version="simple"
            @click="currentOptionValue = $event"
            :value="currentOptionValue"
            :options="options" />
        <Loader v-if="isLoading" />
        <form
            v-else-if="currentOptionValue === 'create'"
            @submit.prevent="createTeacher"
            class="mt-6">
            <div>
                <div class="md:grid grid-cols-2 gap-6">
                    <div class="c__input">
                        <label>
                            {{ $t("form.firstName") }}
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            min="4"
                            max="191"
                            v-model="user.firstName" />
                        <p
                            class="error"
                            v-if="errors.firstName">
                            {{ errors.firstName }}
                        </p>
                    </div>
                    <div class="c__input">
                        <label>
                            {{ $t("form.lastName") }}
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            min="4"
                            max="191"
                            v-model="user.lastName" />
                        <p
                            class="error"
                            v-if="errors.lastName">
                            {{ errors.lastName }}
                        </p>
                    </div>
                </div>
                <div class="md:grid grid-cols-2 gap-6">
                    <div class="c__input">
                        <label>
                            {{ $t("form.email") }}
                        </label>
                        <input
                            type="email"
                            name="email"
                            min="3"
                            max="191"
                            v-model="user.email" />
                        <p
                            class="error"
                            v-if="errors.email">
                            {{ errors.email }}
                        </p>
                    </div>
                    <div class="c__input">
                        <label>
                            {{ $t("form.lang") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model="user.lang"
                                name="lang"
                                class="form-select">
                                <option
                                    v-for="lang in Object.values(AVAILABLE_LANGS)"
                                    :key="lang"
                                    :value="lang">
                                    {{ $t(`global.langs.${lang}`) }}
                                </option>
                            </select>
                        </div>
                        <p
                            class="error"
                            v-if="errors.lang">
                            {{ errors.lang }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-center items-center my-4 w-full">
                <CustomButton
                    type="submit"
                    :disabled="!user.email">
                    {{ $t("form.btnCreateSubscription") }}
                </CustomButton>
            </div>
        </form>

        <form
            v-else-if="currentOptionValue === 'assignByEmail'"
            @submit.prevent="assignByEmail"
            class="mt-6">
            <div class="c__input">
                <label>
                    {{ $t("global.search") }}
                </label>

                <div class="relative">
                    <input
                        :placeholder="$t('list.searchTeachersPlaceholder')"
                        type="text"
                        v-model="searchValue"
                        name="search"
                        class="pr-12"
                        @keyup.enter.prevent.stop="searchTeachers" />
                    <Icons
                        icon="search"
                        @click.prevent.stop="searchTeachers"
                        class="w-7 h-7 block cursor-pointer absolute top-1/2 -translate-y-1/2 transform right-2" />
                </div>
            </div>

            <div>
                <div v-if="searchedTeachers.length === 0">
                    <p class="-small error">
                        {{ $t("list.noTeachers") }}
                    </p>
                </div>

                <div
                    v-for="teacher in searchedTeachers"
                    :key="teacher.id"
                    class="shadow-xl mb-2 rounded p-4 flex flex-row justify-between items-center border border-yellow-200"
                    :class="[
                        selectedTeacher && selectedTeacher.id === teacher.id
                            ? 'bg-yellow-200 text-white'
                            : 'bg-white text-gray-200',
                        teacher.licenseId ? 'cursor-not-allowed' : 'cursor-pointer'
                    ]"
                    @click.prevent="onClickTeacher(teacher)">
                    <p>
                        {{ teacher.name }}
                    </p>
                    <p>
                        {{ teacher.email }}
                    </p>
                    <div
                        class="px-2 py-1 rounded-sm"
                        :class="{
                            'bg-purple text-white': teacher.licenseId,
                            'bg-gray-200': !teacher.licenseId
                        }">
                        <p
                            class="-smaller"
                            :class="{
                                'text-white': teacher.licenseId,
                                'text-black': !teacher.licenseId
                            }">
                            {{ teacher.licenseId ? $t("billing.hasSubscription") : $t("billing.noSubscription") }}
                        </p>
                    </div>
                </div>
                <p
                    class="error"
                    v-if="errors.selectTeacher">
                    {{ errors.selectTeacher }}
                </p>
            </div>
            <div class="xl:w-2/3 mx-auto flex flex-col justify-center items-center my-4 w-full">
                <p class="text-center text-purple -small">
                    {{ $t("billing.teacherSubscriptionDescription") }}
                </p>
                <p class="text-center text-purple -small">
                    {{ $t("billing.teacherSubscriptionDescription2") }}
                </p>
                <CustomButton
                    type="submit"
                    class="my-4"
                    :disabled="!selectedTeacher">
                    {{ $t("form.btnAddSubscription") }}
                </CustomButton>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import cloneDeep from "lodash-es/cloneDeep"
import Loader from "components/Loader"
import TabbedButtons from "components/globals/TabbedButtons"
import Icons from "components/globals/Icons.vue"
import ajax from "config/ajax"
import { tryAsyncFunc } from "@globals/helpers/async"
import { AVAILABLE_LANGS } from "@globals/constants"

const USER_INIT_DATA = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    lang: ""
}

export default {
    name: "CreateTeacherModal",
    emits: ["close", "success"],
    components: { Icons, TabbedButtons, Loader, CustomButton, Modal },
    data() {
        return {
            AVAILABLE_LANGS,
            currentOptionValue: "create",
            options: [
                {
                    label: this.$t("modal.createTeacher.btnCreate"),
                    value: "create"
                },
                {
                    label: this.$t("modal.createTeacher.btnAssignByEmail"),
                    value: "assignByEmail"
                }
            ],

            searchValue: "",
            searchedTeachers: [],
            selectedTeacher: null,

            user: cloneDeep(USER_INIT_DATA),
            // usernameAvailable: false,
            errors: {},

            isLoading: false
        }
    },
    async created() {
        this.user.lang = this.$store.state.auth.user.lang //from parent
    },
    methods: {
        onClickTeacher(teacher) {
            if (teacher.licenseId) return

            if (this.selectedTeacher) {
                if (this.selectedTeacher.id === teacher.id) {
                    this.selectedTeacher = null
                    return
                }
            }

            this.selectedTeacher = teacher
        },
        async searchTeachers() {
            const [res, err] = await tryAsyncFunc(ajax.get)("/user/teachers/search", {
                params: { search: this.searchValue }
            })

            if (err) {
                return
            }

            this.searchedTeachers = res.teachers
        },
        validateErrors() {
            let errors = {}

            if (this.user.firstName === "") {
                errors.firstName = this.$t("validationErrors.firstName")
            }
            if (this.user.lastName === "") {
                errors.lastName = this.$t("validationErrors.lastName")
            }

            if (this.user.email === "") {
                errors.email = this.$t("validationErrors.email")
            }
            return errors
        },
        async deleteTeacher() {
            //ajax.delete(`/user/teachers/${payload.uuid}`)//todo
        },
        async createTeacher() {
            let errors = {}

            if (this.user.firstName === "") {
                errors.firstName = this.$t("validationErrors.firstName")
            }
            if (this.user.lastName === "") {
                errors.lastName = this.$t("validationErrors.lastName")
            }

            if (this.user.email === "") {
                errors.email = this.$t("validationErrors.email")
            }
            this.errors = errors
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.isLoading = true

            const [res, err] = await tryAsyncFunc(ajax.post)("/user/teachers", this.user)

            this.isLoading = false
            if (err) {
                this.errors = err.errors || {}
                return
            }
            this.$emit("success")
        },
        async assignByEmail() {
            let errors = {}

            if (!this.selectedTeacher) {
                errors.selectedTeacher = this.$t("validationErrors.selectTeacher")
            }
            this.errors = errors
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.isLoading = true

            const [res, err] = await tryAsyncFunc(ajax.post)(
                `/user/teachers/${this.selectedTeacher.uuid}/subscription/create`
            )
            this.isLoading = false
            if (err) {
                this.errors = err.errors || {}
                return
            }

            this.$emit("success")
        }
    }
}
</script>
