<template>
    <info-layout>
        <h1>Politique de confidentialité de Kwordz</h1>
        <p>
            Nous vous encourageons à consulter notre politique de confidentialité car nous avons conscience que la
            protection de votre vie privée est essentielle pour nouer une relation durable avec vous. Nous nous
            engageons à traiter de manière adéquate les informations que vous nous confiez pour vous apporter la
            meilleure expérience utilisateur possible.
        </p>

        <h2>POLITIQUE CONCERNANT LES DONNEES CONFIDENTIELLES</h2>
        <p>
            Kwordz s’engage à protéger vos données confidentielles. Nous nous assurons que vous ayez une expérience
            optimale sur nos sites internet et dans l’utilisation de nos produits et services (collectivement, les «
            produits »). Cette politique couvre les sites web de Kwordz, les applications mobiles et les ordinateurs des
            clients. Elle est en vigueur dans le monde entier.
        </p>
        <p>
            Cette politique décrit nos pratiques de gestion, la façon dont nous collectons et utilisons les
            renseignements personnels que vous fournissez en ligne ou hors ligne. Tel qu'utilisé dans la politique de
            confidentialité, « Renseignements personnels » désignent tous les renseignements qui peuvent être utilisés
            pour identifier une personne.
        </p>
        <p>
            Ils peuvent inclure, mais sans s’y limiter, le nom, l'adresse de courrier électronique, le numéro de carte
            bancaire ou de crédit, le titre et tous renseignements personnels.
        </p>
        <p>
            Kwordz sera le responsable du traitement des données personnelles vous concernant qui sont fournies,
            collectées et/ou traitées conformément à la présente politique de confidentialité, sauf mention expresse
            contraire ci-dessous. Si vous avez des questions sur l'application de ce qui précède, veuillez nous
            contacter en utilisant les renseignements présentés dans la section « Contact » sur nos sites.
        </p>
        <p>
            Cette politique peut être mise à jour de temps à autre pour différentes raisons, telles que les pratiques
            opérationnelles ou les changements règlementaires. Vous êtes invité à consulter notre politique de
            confidentialité à chaque fois que vous revenez sur notre site Internet.
        </p>

        <h2>Collecte des renseignements personnels</h2>
        <p>
            Nous collectons les catégories de Données Personnelles suivantes à votre sujet lorsque vous utilisez ou
            interagissez avec nos Produits :
        </p>
        <ul class="space-y-3 list-disc ml-3">
            <li>Nom</li>
            <li>Prénom</li>
            <li>Sexe</li>
            <li>Langue maternelle</li>
            <li>Langue d'apprentissage</li>
            <li>Année scolaire</li>
            <li>Adresse courriel</li>
            <li>Information de carte de crédit/débit (Adresse postale, Code postale, Province, Pays)</li>
            <li>
                Les adresses IP et autres informations collectées passivement, comme détaillé dans la section "Collecte
                passive" ci-dessous
            </li>
        </ul>

        <p>
            Nous recueillons et/ou traitons vos données personnelles en relation avec les activités ci-dessous liées à
            nos produits:
        </p>
        <ul class="space-y-3 list-disc ml-3">
            <li>L'enregistrement de compte</li>
            <li>L'utilisation de certaines fonctionnalités du produit</li>
            <li>
                La génération de rapports basés sur des informations recueillies quant à l’utilisation de nos produits
            </li>
            <li>Les demandes de service d'assistance pour nos produits et les prestations en réponse à ces demandes</li>
            <li>Les transactions et/ou commandes passées</li>
            <li>Les inscriptions à des abonnements à des lettres d'information</li>
        </ul>

        <h2>Utilisation des renseignements personnels</h2>
        <p>
            Nous utiliserons vos données personnelles exclusivement dans le respect de notre politique de
            confidentialité. Si vous ne souhaitez pas que nous continuions à utiliser vos données personnelles, vous
            pouvez demander à ce que votre compte soit désactivé, en nous contactant comme indiqué dans la section «
            Contact ».
        </p>
        <p>
            Nous ne traiterons vos données personnelles que si nous avons une base légale pour le faire. Les bases
            légales pour le traitement comprennent le consentement, la nécessité contractuelle et nos « intérêts
            légitimes », tels que:
        </p>
        <ul class="space-y-3">
            <li>Personnaliser, améliorer ou exploiter nos produits et nos activités</li>
            <li>Mieux comprendre vos besoins et vos intérêts</li>
            <li>Répondre aux demandes que vous faites concernant les produits</li>
            <li>Vous fournir des informations et des offres de notre part ou de tiers</li>
            <li>
                Se conformer à nos obligations légales, résoudre les litiges avec les utilisateurs, faire respecter nos
                accords
            </li>
            <li>Protéger, enquêter et décourager les activités frauduleuses, nuisibles, non autorisées ou illégales</li>
            <li>Nous traitons les données personnelles à des fins telles que:</li>
            <li>
                Fournir des rapports basés sur des informations recueillies au sujet de l'utilisation de nos produits.
            </li>
            <li>
                Vous tenir au courant des dernières annonces de produits, les mises à jour, les améliorations du
                système, les offres spéciales et d'autres informations
            </li>
            <li>Fournir les prestations d’assistance et de soutien pour nos produits.</li>
            <li>Offrir la possibilité de créer des zones de profil personnel et consulter du contenu protégé</li>
            <li>
                Fournir la possibilité de vous contacter et de vous communiquer les informations d'expédition et de
                facturation
            </li>
            <li>Offrir la possibilité de commentaires à la clientèle et d'assistance</li>
            <li>
                Organiser et administrer des concours ou d'autres formes d'activités de marketing ou promotionnelles en
                option sur Kwordz.com ou les sites web affiliés
            </li>
            <li>
                Dans la mesure ou vous choisissez de participer, mener des questionnaires et des enquêtes afin de
                fournir de meilleurs produits et services à nos clients et utilisateurs
            </li>
            <li>
                Pour personnaliser les communications marketing et le contenu du site Web en fonction de vos
                préférences, par exemple en réponse à votre demande d'informations spécifiques sur les produits et
                services susceptibles de vous intéresser
            </li>
            <li>
                Pour contacter les personnes que vous nous référez et vous identifier comme source de référence,
                conformément à la section « Référence » ci-dessous
            </li>
            <li>Satisfaire aux obligations contractuelles ou légales</li>
        </ul>

        <h2>Références</h2>
        <p>
            Si vous choisissez d'utiliser notre service de références pour informer un ami de nos produits, vous
            déclarez que vous avez son autorisation de nous donner son nom et son adresse e-mail. Nous enverrons
            automatiquement un courrier électronique à votre ami en l'invitant à se rendre sur notre site. À moins que
            nous n'y soyons autorisés par votre ami, nous n’utiliserons que son nom et son adresse e-mail afin d’envoyer
            ce seul e-mail et de tenir un journal d'activité de notre programme de référence.
        </p>

        <h2>Choix</h2>
        <p>
            Vous pouvez choisir de fournir ou non les données personnelles à Kwordz, mais notez que vous ne pourrez
            peut-être pas accéder à certaines options de nos services s’ils nécessitent des données personnelles que
            vous n'avez pas fournies. Vous pouvez vous inscrire, et donc consentir, à recevoir des courriels ou des
            bulletins d’information de notre part. Si vous souhaitez interrompre la réception de ces communications,
            vous pouvez mettre à jour vos préférences en nous contactant par la section « Contact » de nos sites.
        </p>

        <h2>Droits de la personne concernée</h2>
        <p>
            Vous avez certains droits en ce qui concerne vos données personnelles, comme indiqué ci-dessous. Veuillez
            noter que dans certaines circonstances, il est possible que nous ne soyons pas en mesure de répondre
            entièrement à vos demandes, ou que nous vous demandions de nous fournir des informations supplémentaires
            relatives à votre demande, qui pourraient être des données personnelles, comme par exemple dans
            l'éventualité où nous dévons vérifier votre identité ou la nature de votre demande. Toutefois, dans de
            telles situations, nous vous répondrons pour vous informer de notre décision.
        </p>
        <p>
            Pour faire l'une des demandes suivantes, contactez-nous en utilisant les coordonnées mentionnées dans la
            section « Contact » de nos sites.
        </p>
        <ul class="space-y-3">
            <li>
                Accès : Vous pouvez demander plus d'informations sur les données personnelles que nous détenons à votre
                sujet. Vous pouvez également demander une copie des données personnelles.
            </li>
            <li>
                Rectification : Si vous estimez que les données personnelles que nous détenons à votre sujet sont
                incorrectes ou incomplètes, vous pouvez nous demander de les corriger ou de les compléter. Vous pouvez
                également corriger certaines de ces informations directement en vous connectant à votre compte
                personnel. Veuillez nous contacter dès que possible si vous constatez que ces informations sont
                incomplètes ou inexactes.
            </li>
            <li>
                Opposition : Vous pouvez nous contacter pour nous faire savoir que vous vous opposez à la collecte ou à
                l'utilisation de vos données personnelles à certaines fins.
            </li>

            <li>
                Effacement : Vous pouvez nous demander d'effacer tout ou partie de vos données personnelles de nos
                systèmes.
            </li>
            <li>
                Restriction de traitement : Vous pouvez nous demander de limiter le traitement de vos renseignements
                personnels.
            </li>
            <li>
                Portabilité : Vous avez le droit de demander une copie de vos données personnelles. Vous pouvez
                également demander que nous transmettions les données à une autre entité lorsque cela est techniquement
                possible.
            </li>
            <li>
                Retrait de consentement : Si nous traitons vos données personnelles en fonction de votre consentement
                (tel qu'indiquer au moment de la collecte de ces données), vous avez le droit de retirer votre
                consentement à tout moment. Veuillez noter, cependant, que si vous exercez ce droit, vous devrez
                peut-être fournir un consentement exprès au cas par cas pour l'utilisation ou la divulgation de
                certaines de vos données personnelles, si une telle utilisation ou divulgation est nécessaire pour vous
                permettre d'utiliser certains de nos produits.
            </li>
            <li>
                Droit de déposer une plainte : Vous avez le droit de déposer une plainte concernant les pratiques de
                Kwordz concernant vos données personnelles auprès de l’autorité de surveillance de votre pays ou de
                votre État membre.
            </li>
        </ul>

        <h2>Conservation des données</h2>
        <p>
            La durée de conservation de vos données personnelles dépend du type de données et du but pour lequel nous
            traitons les données.
        </p>

        <h2>Collecte passive</h2>
        <p>
            Kwordz et nos fournisseurs de services tiers collectant automatiquement certaines informations vous
            concernant lorsque vous utilisez nos produits, en utilisant des méthodes telles que les cookies et les
            technologies de suivi (décrites ci-dessous). Les informations collectées automatiquement pouvant inclure les
            adresses de protocole internet (IP), le genre de navigateur, le prestataire de service Internet, les pages
            d'entrée et de sortie, les fichiers consultés sur notre site (par exemple les pages HTML, les illustrations,
            etc.), le système d’exploitation, le cachet de la date et de l'heure, les données de flux de saisie pour
            analyser les tendances et administrer le site et/ou les produits.
        </p>
        <p>
            En outre, quand vous utilisez certains de nos produits, certaines informations en réseau nous sont
            transmises telles que les informations d'utilisation du produit. Ces informations nous sont transmises afin
            que nous puissions déterminer comment les utilisateurs interagissent avec nos produits, pour nous aider à
            améliorer nos produits et corriger les problèmes qui peuvent survenir.
        </p>
        <p id="logs">
            Pour assurer une qualité de nos sites web, des événements ("logs") sont enregistrés pour différente raison
            sur le site web. Ces événements sont entièrement privés et sont utilisés à des fins statistiques et
            d'assurance qualité des produits.
        </p>

        <h2>Cookies et technologies de suivi</h2>
        <p>
            Kwordz et ses partenaires utilisent des cookies ou des technologies similaires pour analyser les tendances,
            administrer le site, suivre les mouvements des utilisateurs sur le site web et recueillir des informations
            sur l'ensemble de nos utilisateurs, comme les informations relatives à la localisation et les adresses IP.
            Les utilisateurs peuvent contrôler l'utilisation des cookies sur leur navigateur individuel.
        </p>
        <p>
            Votre navigateur peut vous proposer une option « Ne pas suivre », qui vous permet de signaler aux opérateurs
            de sites Web et d'applications et de services Web (y compris les services de publicité comportementale) que
            vous ne souhaitez pas que ces opérateurs suivent certaines de vos activités en ligne dans le temps et sur
            différents sites Web. Nos produits ne prennent pas en charge les demandes « Ne pas suivre » pour le moment,
            ce qui signifie que nous recueillons des informations sur votre activité en ligne à la fois lorsque vous
            utilisez les produits et après avoir quitté nos propriétés.
        </p>

        <h2>Publicités ciblées par centres d'intérêt</h2>
        <p>
            Nous collaborons avec des tiers pour afficher de la publicité sur notre site web ou pour gérer nos
            publicités sur d'autres sites. Certains de nos partenaires externes peuvent utiliser des technologies telles
            que des cookies ou autres afin de recueillir des informations relatives à vos activités sur ce site Web, sur
            le site de nos partenaires et d’autres sites non affiliés, afin de vous fournir de la publicité selon vos
            activités de navigation et intérêts.
        </p>
        <p>
            Si vous souhaitez que vos informations concernant vos activités en ligne dans le temps et sur les différents
            sites Web ne soient pas utilisées dans le but de vous envoyer des annonces basées sur vos intérêts, vous
            pouvez demander de les désactiver en nous écrivant via la section « Contact ». Veuillez noter que cela ne
            vous empêche pas de recevoir des publicités, et même si vous désactivez les publicités ciblées par centres
            d’intérêt, vous continuerez à recevoir des annonces génériques, et nous conservons votre souhait de
            désabonnement des publicités ciblées par centres d’intérêt dans un cookie sur votre appareil, et vous devrez
            peut-être les désactiver à nouveau si vous supprimez vos cookies.
        </p>

        <h2>Application mobile</h2>
        <p>
            Lorsque vous téléchargez et utilisez nos produits, nous recueillons automatiquement les informations sur le
            genre d'appareil que vous utilisez, la version du système d’exploitation et l’identifiant de l’appareil (ou
            « UDID »). Nous vous envoyons des alertes instantanées de temps en temps dans le but de vous mettre à jour
            sur les évènements ou promotions que nous pouvons avoir.
        </p>

        <p>
            Si vous ne souhaitez plus recevoir ces genres de communication, vous pouvez les désactiver depuis votre
            appareil. Pour veiller à ce que vous receviez des alertes adéquates, nous aurons besoin de recueillir
            certaines informations sur votre appareil, y compris le système d'exploitation et les informations
            d'identification de l'utilisateur.
        </p>

        <h2>Géolocalisation</h2>
        <p>
            Nous recueillons des informations sur l'endroit où vous vous trouvez et les moments où vous utilisez nos
            produits. Nous utilisons ces informations afin, entre autres, d'optimiser votre connexion à notre centre de
            données et de proposer une personnalisation de votre expérience avec nos produits (comme votre préférence
            linguistique par exemple). Nous partageons ces informations avec des parties telles que notre fournisseur de
            centre de données et nos partenaires publicitaires. Si vous ne souhaitez pas que nous partageons vos
            informations, veuillez-vous désabonner en nous contactant par courrier électronique à
            <a
                href="mailto:info@kwordz.com"
                class="underline"
                >info@kwordz.com</a
            >.
        </p>

        <h2>Partage de vos données personnelles</h2>
        <p>
            Nous ne vendons ni ne louons vos données personnelles à des tiers à des fins de marketing à moins que vous
            ne nous ayez accordé la permission de le faire.
        </p>
        <p>
            Nous partageons vos données personnelles au sein de Kwordz, de ses sociétés affiliées et de ses fournisseurs
            de services tiers, à des fins de traitement ou de stockage des données.
        </p>
        <p>
            Nous partageons également vos données personnelles avec des partenaires commerciaux, des fournisseurs de
            services, et/ou des agents tiers autorisés ou des entrepreneurs afin de fournir des produits ou des
            transactions demandées, y compris pour le traitement des commandes, le traitement des transactions par carte
            de crédit et l'hébergement des sites web. Nous fournissons à ces tiers des renseignements personnels pour
            réaliser/utiliser le produit ou la transaction demandée.
        </p>
        <p>
            Dans certains cas, nous pouvons choisir d’acheter ou de vendre des actifs. Dans ces types de transactions,
            les informations sur les utilisateurs sont généralement l'un des actifs commerciaux transférés. De plus, si
            nous, ou la quasi-totalité de nos actifs, avons été acquis, ou si nous fermons boutique ou faisons faillite,
            les renseignements sur les utilisateurs seraient l'un des actifs transférés ou acquis par un tiers. Vous
            reconnaissez que de tels transferts peuvent se produire, et que tout acquéreur de notre société ou de nos
            actifs peut continuer à utiliser vos données personnelles comme indiqué dans cette politique. Tel qu'exigé
            par la loi, nous pouvons répondre à des assignations, des ordonnances judiciaires ou des procédures
            judiciaires semblables en communiquant vos données personnelles et d’autres renseignements connexes, le cas
            échéant. Nous pouvons également utiliser vos données personnelles et d'autres informations connexes pour
            établir ou exercer nos droits légaux ou nous défendre contre des revendications légales.
        </p>
        <p>
            Nous collectons et éventuellement partageons des données personnelles et toute autre information
            supplémentaire dont nous disposons afin d’enquêter, de prévenir ou de prendre des mesures concernant des
            activités illégales, des suspicions de fraude, des situations impliquant des menaces potentielles à la
            sécurité physique de toute personne, des infractions aux termes de service de Kwordz et toute autre requête
            légale.
        </p>
        <p>
            Lors de la navigation sur le site ou les produits Web de Kwordz, vous pouvez être dirigé vers un contenu ou
            une fonctionnalité hébergés par un tiers. Lorsque les informations sont collectées exclusivement au nom de
            Kwordz, la politique de confidentialité de Kwordz sera mentionnée et régira la manière dont vos
            renseignements seront utilisés. Dans le cas d'autres sites ou services qui ne sont pas ceux de Kwordz, la
            politique de confidentialité du propriétaire du site ou service sera mentionnée. Vous devriez passer en
            revue les politiques de confidentialité et de sécurité du tiers avant d'utiliser son site ou services.
        </p>

        <h2>Sécurité de vos renseignements personnels</h2>
        <p>
            Kwordz s'engage à protéger les renseignements personnels que vous partagez avec nous. Nous utilisons une
            combinaison de technologies, de procédures et de mesures organisationnelles standards de sécurité pour
            protéger vos renseignements personnels contre tout accès, utilisation ou divulgation non-autorisée. Lorsque
            nous transférons des informations de carte de crédit sur Internet, nous les protégeons en utilisant la
            technologie de chiffrement Secure Sockets Layer (SSL). Pour une sécurité accrue, vos informations de carte
            de crédit sont toujours traitées à l'externe par notre partenaire, Braintree Payments, une compagnie
            affiliés avec PayPal:
            <a
                href="https://www.braintreepayments.com/ca/features/data-security"
                class="underline"
                >https://www.braintreepayments.com/ca/features/data-security</a
            >
        </p>
        <p>
            Nous vous recommandons de prendre toutes les précautions nécessaires pour protéger vos renseignements
            personnels lorsque vous êtes sur Internet. Par exemple, modifiez souvent votre mot de passe. Utilisez une
            combinaison de lettres et de chiffres lors de la création de mots de passe et assurez-vous que vous utilisez
            un navigateur éprouvé. Si vous avez des questions concernant la sécurité de vos renseignements personnels,
            vous pouvez nous contacter à
            <a
                href="mailto:info@kwordz.com"
                class="underline"
                >info@kwordz.com</a
            >.
        </p>

        <h2>Les sites Web lies et les services de tiers</h2>
        <p>
            Nos sites et services peuvent fournir des liens vers d’autres sites et services tiers qui sont hors de notre
            contrôle et ne sont pas couverts par cette politique. Nous vous encourageons à consulter les politiques de
            confidentialité affichées sur ces sites (et d'ailleurs sur tous les sites) que vous visitez et les services
            que vous utilisez.
        </p>

        <h2 id="storage">Transfert et stockage des données personnelles</h2>
        <!--todo: Quand digitalocean est fermé, enlever l'Ontario -->
        <p>
            Nos produits sont hébergés et exploités au Québec et en Ontario par DigitalOcean et Amazon Web Services et
            ses fournisseurs de services. Nous pouvons changer d’hébergeur et être amené à transférer vos données
            personnelles dans une autre société agissant en notre nom aux fins de traitement ou de stockage des données.
            En utilisant l’un de nos Produits ou en fournissant des Données Personnelles pour l'une des finalités
            mentionnées ci-dessus, vous consentez au transfert et au stockage de vos Données Personnelles, qu'elles
            soient fournies par vous ou obtenues par un tiers, comme indiqué ici.
        </p>
        <p>Kwordz est responsable du traitement des données personnelles qu'elle reçoit.</p>

        <h2 id="security">Politique en matière de sécurité</h2>
        <p>
            Kwordz utilise des méthodes conformes aux normes de l’industrie afin de protéger la confidentialité, la
            sécurité et l’intégrité des données de ses clients (dont les renseignements personnels au sujet des enfants)
            contre toute forme non autorisée d’utilisation, de consultation, de communication, de modification ou de
            perte, ainsi que de destruction illégale ou accidentelle. Nous faisons en sorte que ces données protégées
            sont chiffrées partout qu'elle sont utilisés, et qu’elles sont conservées ou supprimées uniquement de façon
            conforme à la présente Politique.
        </p>
        <p>
            Kwordz effectue périodiquement des évaluations des risques et des vulnérabilités, ainsi que des
            vérifications de conformité relatives à la confidentialité et à la sécurité des données. Kwordz rectifie
            rapidement toute vulnérabilité de sécurité qui est relevée par nos développeurs ou par toute autre personne
            externes.
        </p>
        <p>
            Bien que Kwordz à mettre en œuvre des pratiques exemplaires relatives à la sécurité de l’information et des
            données, il nous est impossible de garantir de façon totale la sécurité sur ce plan, puisque les
            technologies nausifs et intrusif comme les virus évoluent constamment et qu’il existe d’autres facteurs de
            risque, comme les défaillances imprévisibles touchant le matériel ou les logiciels. Par conséquent, Kwordz
            ne peut être tenue responsable des pertes ou des altérations de données. Kwordz notifiera par courriel tout
            utilisateur d’une brèche de sécurité le touchant dans les sept jours suivant la découverte de la situation.
        </p>

        <h2>Nous contacter</h2>
        <p>
            Si vous avez des questions relatives à vos droits individuels, à la protection des renseignements
            personnels, ou si vous voulez faire part de commentaires relatifs à cette politique de confidentialité,
            veuillez envoyer un courriel à
            <a
                href="mailto:info@kwordz.com"
                class="underline"
                >info@kwordz.com</a
            >. Vous pouvez nous contactez par le formulaire de contact
            <a
                href="https://www.kwordz.com/#contact"
                class="inline-block underline"
                >ici</a
            >. Vous pouvez également nous contacter par courrier à cette adresse :
        </p>
        <p>
            Dialecte Numérique inc<br />
            8032 avenue des Églises,<br />
            Bureau #2026<br />
            Lévis, QC,<br />
            G6X 1X7 CANADA<br />
            info@kwordz.com<br />
        </p>
    </info-layout>
</template>

<script>
import InfoLayout from "views/layouts/InfoLayout"

export default {
    name: "Privacy",
    components: { InfoLayout }
}
</script>
