<template>
    <div
        v-if="isLoading"
        class="py-6">
        <Loader />
    </div>

    <simplebar
        v-else
        class="scroll-container -no-scroll">
        <div class="flex flex-col justify-start items-start w-full h-full max-h-full">
            <div class="mb-6 w-full">
                <ListGameHeader
                    @end="leaveGame"
                    @restart="restart"
                    @goBack="onGoBack"
                    :game-type="gameType"
                    :word-group="wordGroup"
                    :game-data="gameData" />
            </div>
            <div class="w-full flex-1">
                <StudentGameResult
                    v-if="gameData.isFinish"
                    :student-game-data="studentGameData"
                    :game-data="gameData"
                    :game="game"
                    @end="leaveGame"
                    @restart="restart" />

                <GameCards
                    v-else-if="gameTypeSlug === GAME_TYPES.CARDS"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />
                <GameHear
                    v-else-if="gameTypeSlug === GAME_TYPES.HEAR"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />
                <GameMatchDnD
                    v-else-if="gameTypeSlug === GAME_TYPES.MATCH"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />
                <GameTranslate
                    v-else-if="gameTypeSlug === GAME_TYPES.TRANSLATE"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />
            </div>
            <div
                class="mt-6 w-full"
                v-if="!gameData.isFinish">
                <GameFooter
                    :student-game-data="studentGameData"
                    :timer="timer" />
            </div>
        </div>
    </simplebar>
    <ConfirmQuittingGameModal
        v-if="showQuittingModal"
        @exit="leaveGame"
        @stay="showQuittingModal = false"
        @close="showQuittingModal = false" />
</template>

<script>
import * as types from "store/types"
import GameCards from "components/game/student/types/GameCards"
import GameHear from "components/game/student/types/GameHear"
import GameTranslate from "components/game/student/types/GameTranslate"
import Loader from "components/Loader"
import ajax from "config/ajax"
import EventBus, { EVENTS } from "@/eventbus"
import ListGameHeader from "components/game/student/list/ListGameHeader"
import GameFooter from "components/game/GameFooter"
import { anonymousGameMixin } from "mixins/game"
import ConfirmQuittingGameModal from "components/modals/student/ConfirmQuittingGameModal"
import Simplebar from "components/globals/Simplebar"
import StudentGameResult from "components/game/student/global/StudentGameResult"
import i18n from "config/i18n"
import GameMatchDnD from "components/game/student/types/GameMatchDnD"

export default {
    name: "AnonymousListGame",
    components: {
        GameMatchDnD,
        StudentGameResult,
        Simplebar,
        ConfirmQuittingGameModal,
        GameFooter,
        ListGameHeader,
        GameTranslate,
        Loader,
        GameHear,
        GameCards
    },
    mixins: [anonymousGameMixin],
    async created() {
        this.$store.dispatch(types.ANONYMOUS_DATA.GET_GLOBALS)

        //dont start game if no game left
        if (this.hasNoGameLeft()) {
            return
        }

        await this.init() //from mixin
        await this.initGame() //init game
    },
    methods: {
        async initGame() {
            //check if game already exist
            //last app didn't do that so dont do it right now
            this.wordGroup =
                this.$store.state.anonymousData.wordGroups.find((wG) => wG.uuid === this.$route.params.uuid) || null
            if (!this.wordGroup) {
                this.wordGroup = await this.$store.dispatch(
                    types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_UUID,
                    this.$route.params.uuid
                )
            }

            await this.startGame() //auto start

            this.isLoading = false
        },
        async startGame() {
            let res = await ajax.post(`/anonymous/list-games`, {
                wordGroupUuid: this.wordGroup.uuid,
                gameTypeSlug: this.gameTypeSlug
            })

            const { success, game, gameData } = res

            if (!success) {
                this.$router.push(`/anonymous/list/${this.wordGroup.uuid}`)
                EventBus.emit(EVENTS.SHOW_FLASH, {
                    title: i18n.global.t("flash.error"),
                    message: i18n.global.t("flash.errorOccurred"),
                    type: "error"
                })
                return
            }

            this.game = game
            this.gameData = gameData

            //wait for computed
            await this.$nextTick()

            let studentGameData =
                gameData.studentGames.find(
                    (sG) => sG.student && sG.student.id === this.$store.state.auth.anonymous.id
                ) || null

            if (!studentGameData) {
                this.$router.push(`/anonymous/list/${this.wordGroup.uuid}`)
                EventBus.emit(EVENTS.SHOW_FLASH, {
                    title: i18n.global.t("flash.error"),
                    message: i18n.global.t("flash.errorOccurred"),
                    type: "error"
                })
                return
            }

            let currentSection = studentGameData.sections[0]

            //init
            this.currentSection = {
                ...currentSection,
                isReal: true,
                wasGoodAnswer: false,
                wasWrongAnswer: false
            }

            this.startTimer()
        }
    }
}
</script>
