import * as types from "store/types"
import ajax from "config/ajax"
import cloneDeep from "lodash-es/cloneDeep"
import { addOrReplaceArr, deleteArr } from "helpers/helpers"
import { GAME_TYPES } from "@globals/constants"

const state = {
    students: [],
    classrooms: [],
    wordGroups: [],
    playableWordGroupIds: {
        [GAME_TYPES.CONTEST]: []
    },
    listGroups: [],
    words: [],
    products: [],
    payments: [],
    games: [], //games for classrooms state with student games data
    paymentPlan: null,

    preferredClassroomUuid: null,
    currentClassroomUuid: null,

    studentIdsByClassroomUuid: {},

    global: {
        productCategories: [],
        gameTypes: []
    },

    isFetching: {
        productsById: {}
    },

    hasFetch: {
        paymentPlan: false,
        payments: false,
        classrooms: false,
        wordGroups: false,
        listGroups: false,
        students: false
    }
}

const originalState = cloneDeep(state)

let preferredClassroomUuid = window.localStorage.getItem("kwordz:preferred-classroom-uuid") || ""
if (preferredClassroomUuid) {
    state.preferredClassroomUuid = preferredClassroomUuid
    state.currentClassroomUuid = preferredClassroomUuid
}

// getters, make function easy to access by vue
const getters = {
    getUserTraduction: (state, getters, rootState) => (item) => {
        return item.traductions.find((t) => t.lang === rootState.auth.user.lang) || null
    },
    getUserTeachingTraduction: (state, getters, rootState) => (item) => {
        if (rootState.auth.user.teachingLangs.length === 0) return null
        return item.traductions.find((t) => t.lang === rootState.auth.user.teachingLangs[0]) || null
    }
}

// actions
const actions = {
    [types.USER_DATA.GET_GAMES_BY_CLASSROOM_UUID](store, uuid) {
        return ajax.get(`/user/classrooms/uuid/${uuid}/games`).then((res) => {
            store.commit(types.USER_DATA.GET_GAMES_BY_CLASSROOM_UUID, res.games)
            return Promise.resolve(res.games)
        })
    },
    [types.USER_DATA.GET_CURRENT_PAYMENT_PLAN](store, payload = {}) {
        const { force = false } = payload
        if (store.state.hasFetch.paymentPlan && !force) {
            return Promise.resolve(store.state.paymentPlan)
        }
        return ajax.get(`/user/payments/plan`).then((res) => {
            store.commit(types.USER_DATA.GET_CURRENT_PAYMENT_PLAN, res.plan)
            return Promise.resolve(res.plan)
        })
    },
    [types.USER_DATA.GET_PAYMENTS](store, payload) {
        if (store.state.hasFetch.payments) {
            return Promise.resolve(store.state.payments)
        }
        return ajax.get(`/user/payments`).then((res) => {
            store.commit(types.USER_DATA.GET_PAYMENTS, res.payments)
            return Promise.resolve(res.payments)
        })
    },
    [types.USER_DATA.GET_PRODUCT_BY_ID](store, id) {
        if (store.state.products.length > 0) {
            let exist = store.state.products.find((p) => p.id === id)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        //skip, we are already loading it
        if (typeof store.state.isFetching.productsById[id] !== "undefined") {
            return Promise.resolve(null)
        }
        store.commit(types.USER_DATA.IS_FETCHING_PRODUCT_BY_ID, { id, isFetching: true })
        return ajax.get(`/user/products/id/${id}`).then((res) => {
            store.commit(types.USER_DATA.IS_FETCHING_PRODUCT_BY_ID, { id, isFetching: false })
            store.commit(types.USER_DATA.GET_PRODUCT_BY_ID, res.product)
            return Promise.resolve(res.product)
        })
    },
    [types.USER_DATA.GET_GLOBALS](store, payload) {
        return ajax.get(`/user/globals`).then((res) => {
            store.commit(types.USER_DATA.GET_GLOBALS, res.global)
            return Promise.resolve(res.global)
        })
    },
    [types.USER_DATA.GET_WORDS](store, payload) {
        return ajax.get(`/user/words/search`).then((res) => {
            store.commit(types.USER_DATA.GET_WORDS, res.words)
            return Promise.resolve(res.words)
        })
    },
    [types.USER_DATA.GET_WORDS_BY_WORD_GROUP_UUID](store, wordGroupUuid) {
        //todo cache
        return ajax.get(`/user/word-groups/uuid/${wordGroupUuid}/words`).then((res) => {
            res.words.forEach((word) => {
                store.commit(types.USER_DATA.GET_WORD_BY_ID, word)
            })

            return Promise.resolve(res.words)
        })
    },
    [types.USER_DATA.DUPLICATE_LIST_IN_LIST_GROUP](store, { listGroupUuid, wordGroupIds }) {
        return ajax
            .post(`/user/list-groups/${listGroupUuid}/duplicate-list`, {
                wordGroupIds
            })
            .then((res) => {
                res.wordGroups.forEach((wordGroup) => {
                    store.commit(types.USER_DATA.GET_WORD_GROUP_BY_ID, wordGroup)
                })

                store.commit(types.USER_DATA.GET_LIST_GROUP_BY_ID, res.listGroup)

                return Promise.resolve(res)
            })
    },
    [types.USER_DATA.GET_WORD_GROUPS_BY_LIST_GROUP_UUID](store, listGroupUuid) {
        //todo cache
        return ajax.get(`/user/list-groups/uuid/${listGroupUuid}/word-groups`).then((res) => {
            res.wordGroups.forEach((wordGroup) => {
                store.commit(types.USER_DATA.GET_WORD_GROUP_BY_ID, wordGroup)
            })

            return Promise.resolve(res.wordGroups)
        })
    },
    [types.USER_DATA.GET_LIST_GROUPS](store, payload) {
        if (store.state.hasFetch.listGroups) {
            return Promise.resolve(store.state.listGroups)
        }
        return ajax.get("/user/list-groups").then((res) => {
            store.commit(types.USER_DATA.GET_LIST_GROUPS, res.listGroups)
            return Promise.resolve(res.listGroups)
        })
    },
    [types.USER_DATA.DELETE_LIST_GROUP](store, payload) {
        return ajax.delete(`/user/list-groups/${payload.uuid}`).then((res) => {
            store.commit(types.USER_DATA.DELETE_LIST_GROUP, payload)
            return Promise.resolve(res)
        })
    },
    [types.USER_DATA.CREATE_LIST_GROUP](store, payload) {
        return ajax.post("/user/list-groups", payload).then((res) => {
            store.commit(types.USER_DATA.CREATE_LIST_GROUP, res.listGroup)
            return Promise.resolve(res.listGroup)
        })
    },
    [types.USER_DATA.EDIT_LIST_GROUP](store, payload) {
        return ajax.put(`/user/list-groups/${payload.uuid}`, payload).then((res) => {
            store.commit(types.USER_DATA.GET_LIST_GROUP_BY_ID, res.listGroup)
            return Promise.resolve(res.listGroup)
        })
    },
    [types.USER_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS](store, payload) {
        if (store.state.hasFetch.wordGroups) {
            return Promise.resolve(store.state.playableWordGroupIds[GAME_TYPES.CONTEST])
        }
        return ajax.get(`/user/word-groups/playable/${GAME_TYPES.CONTEST}`).then((res) => {
            store.commit(types.USER_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS, res.wordGroupIds)
            return Promise.resolve(res.wordGroupIds)
        })
    },
    [types.USER_DATA.DUPLICATE_WORD_GROUP_BY_UUID](store, { listGroupId, wordGroupUuid }) {
        return ajax
            .post(`/user/word-groups/uuid/${wordGroupUuid}/duplicate`, {
                listGroupId: listGroupId
            })
            .then((res) => {
                store.commit(types.USER_DATA.GET_WORD_GROUP_BY_ID, res.wordGroup)
                return Promise.resolve(res)
            })
    },
    [types.USER_DATA.GET_WORD_GROUP_BY_UUID](store, uuid) {
        if (store.state.wordGroups.length > 0) {
            let exist = store.state.wordGroups.find((w) => w.uuid === uuid)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        return ajax.get(`/user/word-groups/uuid/${uuid}`).then((res) => {
            store.commit(types.USER_DATA.GET_WORD_GROUP_BY_ID, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.USER_DATA.GET_WORD_GROUP_BY_ID](store, id) {
        if (store.state.wordGroups.length > 0) {
            let exist = store.state.wordGroups.find((w) => w.id === id)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        return ajax.get(`/user/word-groups/id/${id}`).then((res) => {
            store.commit(types.USER_DATA.GET_WORD_GROUP_BY_ID, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.USER_DATA.GET_WORD_GROUPS](store, payload) {
        if (store.state.hasFetch.wordGroups) {
            return Promise.resolve(store.state.wordGroups)
        }
        //fetch playable
        store.dispatch(types.USER_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS)
        return ajax.get("/user/word-groups").then((res) => {
            store.commit(types.USER_DATA.GET_WORD_GROUPS, res.wordGroups)
            return Promise.resolve(res.wordGroups)
        })
    },
    [types.USER_DATA.CREATE_WORD_GROUP](store, payload) {
        return ajax.post("/user/word-groups", payload).then((res) => {
            store.commit(types.USER_DATA.CREATE_WORD_GROUP, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.USER_DATA.DELETE_WORD_GROUP](store, payload) {
        return ajax.delete(`/user/word-groups/${payload.uuid}`).then((res) => {
            store.commit(types.USER_DATA.DELETE_WORD_GROUP, payload)
            return Promise.resolve(res)
        })
    },
    [types.USER_DATA.EDIT_WORD_GROUP](store, payload) {
        return ajax.put(`/user/word-groups/${payload.uuid}`, payload).then((res) => {
            store.commit(types.USER_DATA.EDIT_WORD_GROUP, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.USER_DATA.DELETE_CLASSROOM](store, payload) {
        return ajax.delete(`/user/classrooms/${payload.uuid}`).then((res) => {
            store.commit(types.USER_DATA.DELETE_CLASSROOM, payload)
            return Promise.resolve(res)
        })
    },
    [types.USER_DATA.EDIT_CLASSROOM](store, payload) {
        return ajax.put(`/user/classrooms/${payload.uuid}`, payload).then((res) => {
            store.commit(types.USER_DATA.EDIT_CLASSROOM, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.USER_DATA.CREATE_CLASSROOM](store, payload) {
        return ajax.post("/user/classrooms", payload).then((res) => {
            store.commit(types.USER_DATA.CREATE_CLASSROOM, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.USER_DATA.GET_CLASSROOMS](store, payload) {
        if (store.state.hasFetch.classrooms) {
            return Promise.resolve(store.state.classrooms)
        }
        return ajax.get("/user/classrooms").then((res) => {
            store.commit(types.USER_DATA.GET_CLASSROOMS, res.classrooms)
            return Promise.resolve(res.classrooms)
        })
    },
    [types.USER_DATA.GET_CLASSROOM_BY_UUID](store, uuid) {
        return ajax.get(`/user/classrooms/uuid/${uuid}`).then((res) => {
            store.commit(types.USER_DATA.GET_CLASSROOM_BY_UUID, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.USER_DATA.GET_CLASSROOM_BY_ID](store, id) {
        return ajax.get(`/user/classrooms/id/${id}`).then((res) => {
            store.commit(types.USER_DATA.GET_CLASSROOM_BY_ID, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID](store, classroomUuid) {
        return ajax.get(`/user/classrooms/${classroomUuid}/students`).then((res) => {
            store.commit(types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID, {
                students: res.students,
                classroomUuid: classroomUuid
            })
            //update students
            res.students.forEach((student) => {
                store.commit(types.USER_DATA.GET_STUDENT_BY_ID, student)
            })
            return Promise.resolve(res.students)
        })
    },
    [types.USER_DATA.GET_STUDENT_BY_UUID](store, uuid) {
        return ajax.get(`/user/students/uuid/${uuid}`).then((res) => {
            store.commit(types.USER_DATA.GET_STUDENT_BY_ID, res.student)
            return Promise.resolve(res.student)
        })
    },
    [types.USER_DATA.DELETE_STUDENT](store, payload) {
        return ajax.delete(`/user/students/${payload.uuid}`).then((res) => {
            store.commit(types.USER_DATA.DELETE_STUDENT, payload)
            return Promise.resolve(res)
        })
    },
    [types.USER_DATA.CREATE_STUDENT](store, payload) {
        return ajax.post("/user/students", payload).then((res) => {
            store.commit(types.USER_DATA.CREATE_STUDENT, res.student)
            return Promise.resolve(res.student)
        })
    },
    [types.USER_DATA.EDIT_STUDENT](store, payload) {
        return ajax.put(`/user/students/${payload.uuid}`, payload).then((res) => {
            store.commit(types.USER_DATA.EDIT_STUDENT, res.student)
            return Promise.resolve(res.student)
        })
    },
    [types.USER_DATA.GET_STUDENTS](store, payload) {
        if (store.state.hasFetch.students) {
            return Promise.resolve(store.state.students)
        }
        return ajax.get("/user/students").then((res) => {
            store.commit(types.USER_DATA.GET_STUDENTS, res.students)
            return Promise.resolve(res.students)
        })
    }
}

// mutations
const mutations = {
    [types.USER_DATA.GET_GAMES_BY_CLASSROOM_UUID](state, games) {
        games.forEach((game) => {
            addOrReplaceArr(state.games, game)
        })
    },
    [types.USER_DATA.GET_CURRENT_PAYMENT_PLAN](state, plan) {
        state.paymentPlan = plan
        state.hasFetch.paymentPlan = plan
    },
    [types.USER_DATA.GET_PAYMENTS](state, payments) {
        state.payments = payments
        state.hasFetch.payments = false //when edit set to false
    },
    [types.USER_DATA.EDIT_CLASSROOM](state, classroom) {
        addOrReplaceArr(state.classrooms, classroom)
        state.hasFetch.classrooms = false //when edit set to false
    },
    [types.USER_DATA.CREATE_CLASSROOM](state, classroom) {
        state.classrooms.push(classroom)
        state.hasFetch.classrooms = false //when edit set to false
    },
    [types.USER_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS](state, wordGroupIds) {
        state.playableWordGroupIds[GAME_TYPES.CONTEST] = wordGroupIds
    },
    [types.USER_DATA.CREATE_WORD_GROUP](state, wordGroup) {
        state.wordGroups.push(wordGroup)
        state.hasFetch.wordGroups = false //when edit set to false
    },
    [types.USER_DATA.DELETE_WORD_GROUP](state, wordGroup) {
        deleteArr(state.wordGroups, wordGroup)
        state.hasFetch.wordGroups = false //when edit set to false
    },
    [types.USER_DATA.EDIT_WORD_GROUP](state, wordGroup) {
        addOrReplaceArr(state.wordGroups, wordGroup)
        state.hasFetch.wordGroups = false //when edit set to false
    },
    [types.USER_DATA.DELETE_CLASSROOM](state, classroom) {
        deleteArr(state.classrooms, classroom)
        delete state.studentIdsByClassroomUuid[classroom.uuid]
        state.hasFetch.students = false
        state.hasFetch.classrooms = false //so we refetch classrooms
    },
    [types.USER_DATA.GET_CLASSROOMS](state, classrooms) {
        state.classrooms = classrooms
        state.hasFetch.classrooms = true
    },
    [types.USER_DATA.GET_CLASSROOM_BY_UUID](state, classroom) {
        addOrReplaceArr(state.classrooms, classroom)
    },
    [types.USER_DATA.GET_CLASSROOM_BY_ID](state, classroom) {
        addOrReplaceArr(state.classrooms, classroom)
    },
    [types.USER_DATA.GET_WORDS](state, words) {
        state.words = words
    },
    [types.USER_DATA.GET_WORD_GROUP_BY_ID](state, wordGroup) {
        addOrReplaceArr(state.wordGroups, wordGroup)
    },
    [types.USER_DATA.GET_WORD_BY_ID](state, word) {
        addOrReplaceArr(state.words, word)
        state.hasFetch.words = false
    },
    [types.USER_DATA.DELETE_LIST_GROUP](state, listGroup) {
        deleteArr(state.listGroups, listGroup)
        state.hasFetch.wordGroups = false //make sure we refetch them all
        state.hasFetch.listGroups = false //make sure we refetch them all
        state.hasFetch.classrooms = false //make sure listGroupId in class will be reset
    },
    [types.USER_DATA.CREATE_LIST_GROUP](state, listGroup) {
        state.listGroups.push(listGroup)
        state.hasFetch.listGroups = false //when edit set to false
    },
    [types.USER_DATA.GET_LIST_GROUPS](state, listGroups) {
        state.listGroups = listGroups
        state.hasFetch.listGroups = true
    },
    [types.USER_DATA.GET_LIST_GROUP_BY_ID](state, listGroup) {
        addOrReplaceArr(state.listGroups, listGroup)
    },
    [types.USER_DATA.GET_WORD_GROUPS](state, wordGroups) {
        state.wordGroups = wordGroups
        state.hasFetch.wordGroups = true
    },
    [types.USER_DATA.DELETE_STUDENT](state, student) {
        deleteArr(state.students, student)

        //loop each classroom and delete student if in there
        Object.keys(state.studentIdsByClassroomUuid).forEach((key) => {
            deleteArr(state.studentIdsByClassroomUuid[key], student)
        })
        state.hasFetch.students = false
        state.hasFetch.classrooms = false //so we refetch classrooms
        state.hasFetch.paymentPlan = false //refetch plan
    },
    [types.USER_DATA.GET_STUDENTS](state, students) {
        state.students = students
        state.hasFetch.students = true
    },
    [types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID](state, { students, classroomUuid }) {
        state.studentIdsByClassroomUuid[classroomUuid] = students.map((s) => s.id)
    },
    [types.USER_DATA.EDIT_STUDENT](state, student) {
        addOrReplaceArr(state.students, student)
        state.hasFetch.students = false
        state.hasFetch.paymentPlan = false //when need student
    },
    [types.USER_DATA.CREATE_STUDENT](state, student) {
        addOrReplaceArr(state.students, student)
        state.hasFetch.students = false
        state.hasFetch.paymentPlan = false //when need student
    },
    [types.USER_DATA.GET_STUDENT_BY_ID](state, student) {
        addOrReplaceArr(state.students, student)
    },
    [types.USER_DATA.GET_GLOBALS](state, globals) {
        state.global.productCategories = globals.productCategories
        state.global.gameTypes = globals.gameTypes
    },
    [types.USER_DATA.GET_PRODUCT_BY_ID](state, product) {
        addOrReplaceArr(state.products, product)
    },
    [types.USER_DATA.IS_FETCHING_PRODUCT_BY_ID](state, { id, isFetching }) {
        if (isFetching) {
            state.isFetching.productsById[id] = isFetching
        } else if (typeof state.isFetching.productsById[id] !== "undefined") {
            delete state.isFetching.productsById[id]
        }
    },
    [types.USER_DATA.SET_CURRENT_CLASSROOM_UUID](state, classroomUuid) {
        state.currentClassroomUuid = classroomUuid
        state.preferredClassroomUuid = classroomUuid
        window.localStorage.setItem("kwordz:preferred-classroom-uuid", classroomUuid)
    },
    [types.USER_DATA.SET_FETCH](state, { key, value = true }) {
        if (typeof state.hasFetch[key] === "undefined") {
            console.error("No key SET_FETCH", key)
            return
        }
        state.hasFetch[key] = value
    },
    [types.LOGOUT](state) {
        //reset all data
        let clone = cloneDeep(originalState)
        Object.keys(state).forEach((key) => {
            state[key] = clone[key]
        })
        Object.assign(state, clone)

        window.localStorage.removeItem("kwordz:preferred-classroom-uuid")
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
