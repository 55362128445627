<template>
    <div class="relative">
        <div
            class="p-1.5 shadow-xl bg-white rounded-full cursor-pointer relative"
            @click.prevent="toggleDropdown">
            <div
                class="flex flex-row justify-between items-center"
                v-if="currentLang">
                <div
                    class="bg-gray-100 rounded-full h-10 min-w-10 w-10 p-1.5 flex flex-row justify-center items-center">
                    <img
                        :src="FLAGS[currentLang]"
                        class="block w-full h-full object-contain object-center" />
                </div>
                <p class="text-purple font-medium px-2 whitespace-nowrap">
                    {{ $t(`global.langs.${currentLang}`) }}
                </p>
                <div
                    class="mx-0.5"
                    v-if="availableLangs.length > 0">
                    <Icons
                        icon="caret"
                        class="text-purple w-6 h-6 block" />
                </div>
            </div>
        </div>
        <div
            v-if="showDropdown"
            class="absolute right-0 z-2">
            <div
                v-for="lang in availableLangs"
                :key="lang.id"
                class="my-3">
                <div
                    type="button"
                    @click.prevent="setCurrentLang(lang)"
                    class="p-1.5 shadow-xl bg-white rounded-full cursor-pointer relative">
                    <div class="flex flex-row justify-start items-center">
                        <div
                            class="bg-gray-100 rounded-full h-10 min-w-10 w-10 p-1.5 flex flex-row justify-center items-center">
                            <img
                                :src="FLAGS[lang]"
                                class="block w-full h-full object-contain object-center" />
                        </div>
                        <p class="text-purple font-medium px-2 whitespace-nowrap">
                            {{ $t(`global.langs.${lang}`) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icons from "components/globals/Icons"
import { AVAILABLE_LANGS } from "@globals/constants"
import { getCurrentLang, selectLang } from "config/i18n"
import { FLAGS } from "services/countryFlags"

export default {
    name: "LangDropdown",
    components: { Icons },
    data() {
        return {
            AVAILABLE_LANGS,
            FLAGS,
            showDropdown: false
        }
    },
    computed: {
        currentLang() {
            return getCurrentLang()
        },
        availableLangs() {
            return Object.values(AVAILABLE_LANGS).filter((l) => l !== this.currentLang)
        }
    },
    methods: {
        setCurrentLang(lang) {
            selectLang(lang)
            this.showDropdown = false
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        }
    }
}
</script>
